import axios from 'axios'
import { identity, pickBy } from 'lodash'

import store from '../store'
import { NotificationPayload } from '../store/modules/notification'

function getToken () {
  const storage = localStorage
  let obj: any = null
  Object.entries(storage).forEach(([key, value], index) => {
    if (key.search(`oidc.user:${process.env.VUE_APP_OAUTH_AUTHORITY}`) > -1) {
      obj = JSON.parse(value)
    }
  })
  return { token: obj.access_token, tokenType: obj.tokenType }
}

interface Pagination {
  pagesize: number;
  pagenumber: number;
  orderby?: string;
}
export default class BaseApi {
  className = ''
  uci = ''
  type: number|null = null
  token: string|null = null
  tokenType: string|null = 'Bearer'

  constructor (className: string, type = -1, token: string|null = null, uci = 'BCO', overrideType = -1) {
    this.className = className
    this.uci = uci
    if (overrideType !== -1) {
      this.type = overrideType
    } else {
      // console.log(store)
      this.type = type
    }

    const oauthToken = getToken()
    if (token) {
      this.token = token
    } else if (oauthToken) {
      this.token = oauthToken.token
      this.tokenType = oauthToken.tokenType
    } else {
      this.token = null
    }

    axios.interceptors.response.use((response) => response, (error) => {
      // console.log('INTERCEPTORS')
      // Add interceptor logic for undefined responses / preflights / ...
      // console.log('INTERCEPT')
      // console.log(error)
      if (typeof error.response === 'undefined') {
        /* console.log('A network error occurred. '
            + 'This could be a CORS issue or a dropped internet connection. '
            + 'It is not possible for us to know.') */
      }
      let err  = {
        "data": {
          "result":{
            "status": "ERROR",
            "errorcode": "FAILEDTOLOAD",
            "message": "This could be a CORS issue or a dropped internet connection.",
            "errorid": ""
          }
        }
      }
      if (error.response.status === 404) {
        err = {
          "data": {
            "result":{
              "status": "ERROR",
              "errorcode": "FAILEDTOFIND",
              "message": "404 Not found",
              "errorid": ""
            }
          }
        }
      }
      return Promise.resolve(err)
      // return Promise.reject(error)
    })
  }

  async searchOn (key: string, value: any, amount = 25) {
    return new Promise(async (resolve, reject) => {
      if (value) {
        if (value.match(/\?/)) {
          value = { op: 'LI', value: value }
        }
      }
      const filter: any = {}
      filter[key] = value
      try {
        const dt = await this.filter(filter, { pagesize: amount, pagenumber: 1 })
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async create (data: any, debug = false) {
    return new Promise(async (resolve, reject) => {
      const opts = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/${this.className}/${this.uci}/createupdate`,
        data: {
          ...data
        }
      }
      if (debug) {
        opts.url = opts.url + '?debug=1'
      }
      try {
        const dt = await this.postApi(opts) as any
        // console.log(dt)
        if (dt.result.status === 'success') {
          resolve(dt.Data)
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  async update (key: string, value: string | number, data: any) {
    return new Promise(async (resolve, reject) => {
      const opts: any = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/${this.className}/${this.uci}/createupdate`,
        data: {
          Keys: {},
          ...data
        }
      }
      opts.data.Keys[key] = value
      try {
        const dt = await this.postApi(opts) as any
        if (dt.result.status === 'success') {
          resolve(dt.Data)
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  // filter
  // @args {Object} filter - filter containing the query
  // @args {Object} pagination - pagination parameters
  // @args {Number} type - where in the request is sendn(0 = admin, 1 = cpo, 3 = msp, 5 = landowner)
  // @return Promise
  async filter (filter: any, pagination: Pagination, select: any = { DataCategories: ['BASIC', 'STATE', 'TECH'] }, caching = 1, otherClass: string | null = null) {
    return new Promise(async (resolve, reject) => {
      const options: any = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/${this.className}/${this.uci}/filtered`,
        params: {
          ...pagination,
          type: this.type,
          filter: filter,
          select: select,
          caching: caching
        }
      }
      // console.log(options)
      if (select.orderby) {
        options.params.orderby = JSON.stringify(select.orderby)
        delete options.params.select.orderby
      }
      if (select.orderBy) {
        options.params.orderby = JSON.stringify(select.orderBy)
        delete options.params.select.orderBy
      }

      if (otherClass) {
        options.url = `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/${otherClass}/${this.uci}/filtered`
      }
      // console.log(filter)
      if (!filter) {
        delete (options.params.filter)
      } else if (filter.length === 0) {
        delete (options.params.filter)
      }
      try {
        const res: any = await this.getApi(options)
        if (res.Result.Status.toLowerCase() === 'success') {
          resolve(res)
        } else {
          // console.log('DEBUG ELSE')
          if (res.Result.ErrorCode === 'WrongPassword') {
            reject(new Error('wrongpw'))
          } else {
            reject(new Error('unknown'))
          }
        }
      } catch (err: any) {
        console.error(err)
        this.errorHandler(err, options, this.className)
        reject(new Error(err))
      }
    })
  }

  // filter
  // @args {String} url - url where the query is executed
  // @args {Object} filter - filter containing the query
  // @args {Object} pagination - pagination parameters
  // @args {Number} type - where in the request is sendn(0 = admin, 1 = cpo, 3 = msp, 5 = landowner)
  // @return Promise
  async customFilter (url: string, filter: any, pagination: Pagination, select: any = { DataCategories: ['BASIC', 'STATE', 'TECH'] }, caching = 1) {
    return new Promise(async (resolve, reject) => {
      const options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}${url}`,
        params: {
          ...pagination,
          type: this.type,
          filter: filter,
          select: select,
          caching: caching
        }
      }
      // console.log(filter)
      if (!filter) {
        delete (options.params.filter)
      } else if (filter.length === 0) {
        delete (options.params.filter)
      }
      try {
        const res: any = await this.getApi(options)
        if (res.Result.Status.toLowerCase() === 'success') {
          resolve(res)
        } else {
          if (res.Result.ErrorCode === 'WrongPassword') {
            reject(new Error('wrongpw'))
          } else {
            reject(new Error('unknown'))
          }
        }
      } catch (err: any) {
        console.error(err)
        this.errorHandler(err, options, this.className)
        reject(new Error(err))
      }
    })
  }

  // TODO
  async one (id: any, select = { DataCategories: ['BASIC', 'STATE', 'TECH'] }) {
    return new Promise(async (resolve, reject) => {
      const opt = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/${this.className}/${this.uci}/one/${id}`,
        params: {
          select: select
        }
      }
      try {
        const res: any = await this.getApi(opt)
        if (res.Result.Status === 'success') {
          resolve(res)
        } else {
          if (res.Result.ErrorCode === 'WrongPassword') {
            reject(new Error('wrongpw'))
          } else {
            reject(new Error('unknown'))
          }
        }
      } catch (err: any) {
        console.error(err)
        this.errorHandler(err, opt, this.className)
        reject(new Error(err))
      }
    })
  }

  static generateFilter (data: any, operator = 'and') {
    const rtn = []
    data = pickBy(data, identity)
    let k: any = null
    let v: any = null
    for ([k, v] of Object.entries(data)) {
      if (typeof v === 'object') {
        if (Object.keys(v).length > 0) {
          const t: any = {}
          t[k] = v
          rtn.push(t)
        }
      } else if (v.match(/\?/)) {
        const t: any = {}
        t[k] = { op: 'LI', value: v }
        rtn.push(t)
      } else {
        const t: any = {}
        t[k] = v
        rtn.push(t)
      }
    }
    let t: any = {}
    if (rtn.length > 1) {
      t[operator] = rtn
    } else {
      t = rtn[0]
    }
    return t
  }

  static checkQuestion (data: any) {
    const o = data.match(/\?/g)
    // console.log('next is data.match')
    // console.log(o, data)
    if (o !== -1) {
      return { op: 'LI', value: data }
    } else {
      return data
    }
  }

  async getMetaData () {
    return new Promise(async (resolve, reject) => {
      const opt = `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/${this.className}/${this.uci}/meta`
      try {
        const res: any = await this.getApi(opt)
        if (res.Result.Status.toLowerCase() === 'success') {
          resolve(res)
        } else {
          if (res.Result.ErrorCode === 'WrongPassword') {
            reject(new Error('wrongpw'))
          } else {
            reject(new Error('unknown'))
          }
        }
      } catch (err: any) {
        // console.error(err)
        // this.errorHandler(err, opt, this.className)
        reject(new Error(err))
      }
    })
  }

  // getApi
  // @args {String | Object} options - options for the get Api
  // @args {Boolean} useAuth - allows you to disable the authentication (Default=true)
  // @return Promise
  async getApi (options: any, useAuth = true, isNotified = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'GET', useAuth)
      this.rawHttp(options).then((res: any) => {
        if(this.isError(res, options, isNotified)) {
          reject(res.data.result)
        }
        resolve(res.data)
      }).catch(err => {
        if (err.response) {
          this.errorHandler(err.response.data, options, this.className)
          reject(new Error(err.response))
        } else {
          reject(err)
        }
      })
    })
  }

  async getStreamApi (options: any, useAuth = true, isNotified = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'GET', useAuth, 'blob')
      this.rawHttp(options).then((res: any) => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  async getApiNoCheck (options: any, useAuth = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'GET', useAuth)
      this.rawHttp(options).then((res: any) => {
        resolve(res.data)
      }).catch(err => {
        // console.log('BASEAPI')
        // console.log(err.response.status)
        // console.log(err.status)
        // console.error(err)
        const errOut: any = new Error(err)
        if (err.response) {
          errOut.status = err.response.status
          // console.log('ERREUR OUT')
          // console.log(errOut)
        }
        reject(errOut)
      })
    })
  }

  async getExterneApi (options: any, useAuth = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'GET', useAuth)
      this.rawHttp(options).then((res: any) => {
        // console.log('LOG CALL')
        // console.log(res)
        if (res.status === 200) {
          resolve(res.data)
        } else {
          // console.log('IN BASEAPI')
          // console.error(res)
          this.errorHandler(res.data, options, this.className)
          reject(new Error(res))
        }
      }).catch(err => {
        console.error(err)
        reject(new Error(err))
      })
    })
  }

  // postApi
  // @args {String | Object} options - options for the get Api
  // @args {Boolean} useAuth - allows you to disable the authentication (Default=true)
  // @return Promise
  async postApi (options: any, useAuth = true, isNotified = true) {
    // console.log('IN POST API')
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'post', useAuth)
      // console.log('[deubg post call]')
      this.rawHttp(options).then((res: any) => {
        // console.log('IN API CALL')
        // console.log(res)
        // console.log(this.isError(res, options, isNotified))
        if(this.isError(res, options, isNotified)) {
          reject(res.data.result)
        }
        resolve(res.data)
      }).catch(err => {
        reject(new Error(err))
      })
    })
  }

  // postApi
  // @args {String | Object} options - options for the get Api
  // @args {Boolean} useAuth - allows you to disable the authentication (Default=true)
  // @return Promise
  async putApi (options: any, useAuth = true, isNotified = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'put', useAuth)
      this.rawHttp(options).then((res: any) => {
        if(this.isError(res, options, isNotified)) {
          reject(new Error(res.data.result))
        }
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        reject(new Error(err))
      })
    })
  }

  // deleteApi
  // @args {String | Object} options - options for the get Api
  // @args {Boolean} useAuth - allows you to disable the authentication (Default=true)
  // @return Promise
  async deleteApi (options: any, useAuth = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, 'delete', useAuth)
      this.rawHttp(options).then((res: any) => {
        if(this.isError(res, options)) {
          reject(new Error(res))
        }
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        reject(new Error(err))
      })
    })
  }

  // checkHeaders
  // @args {Object} options - options for the get Api
  // @args {Number} mode - mode where in the request is sendn(0 = admin, 1 = cpo, 3 = msp, 5 = landowner)
  // @args {Boolean} useAuth - allows you to disable the authentication (Default=true)
  // @return Object
  checkHeaders (options: any, mode: any, useAuth: any, responseType = '') {
    let opt: any = {}
    if (typeof options === 'string') {
      opt.url = this.checkUrl(options)
    } else {
      options.url = this.checkUrl(options.url)
      opt = options
    }
    opt.method = mode
    opt.responseType = responseType
    if (useAuth) {
      if (this.token !== '') {
        if (opt.headers) {
          opt.headers.Authorization = `Bearer ${this.token}`
        } else {
          opt.headers = {
            Authorization: `Bearer ${this.token}`
          }
        }
      } else {
        return new Error('No token was set')
      }
    }
    return opt
  }

  // rawHttp
  // @args {Object} options - options for the get Api
  // @return Promise
  async rawHttp (options: any) {
    return new Promise((resolve, reject) => {
      axios(options).then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err)
      })
    })
  }

  // Check if endpoint result resulted in an error. If so execute error handler.
  // @args {Object} res - http result
  // @args {Object} options - http options
  // @args {Object} isNotified - isNotified boolean?
  isError(res: any, options: any, isNotified?: any){
    if(res == null) {
      res = {}
      return true
    }
    // console.log(res)
    // Check status
    if(res?.data?.result?.status?.toLowerCase() === 'error'
    || res?.data?.result?.status?.toLowerCase() === 'warning'
    || res?.data?.result?.status?.toLowerCase() === 'critical') {
      // console.log("status check failed")
      if (isNotified) {
        this.errorHandler(res.data, options, this.className)
      }
      return true
    } else if(res?.data?.Status || res?.data?.result?.status.toLowerCase() === "success" || res?.data?.result?.status.toLowerCase() === "info") {
      // console.log("success")
      return false
    }
    // console.log("aiai")
    if (isNotified) {
      this.errorHandler(res.data, options, this.className)
    }
    return true
  }

  errorHandler (data: any, options: any, dataclass: any) {
    let msg = ''
    // console.log('BEFORE IF')
    if (data.result) {
      msg = data.result.errorcode // 'An error has occurred (' + data.result.message + '), please contact support.'
    }
    // console.log('AFTER IF')

    let content = 'An error has occures, please contact support.'
    if (msg === 'Invalid token.') {
      content = 'Card not valid for registration. Please check or contact services!'
    }
    content = msg
    const payload: NotificationPayload = {
      color: 'error',
      content: content,
      timeout: -1
    }
    // console.log('PAYLOAD')
    // console.log(payload)
    store.commit('notification/showMessage', payload)
    // if (data.result.status === 'error') {
    //   store.dispatch('oidcStore/authenticateOidc')
    // }
    const event = new CustomEvent('api-error', { detail: { data, options, dataclass } })
    document.dispatchEvent(event)
  }

  static paginationGen (page: any) {
    const rtn: any = {}

    if (page.PageNumber === 1) {
      rtn.prevDisabled = true
    } else {
      rtn.prevDisabled = false
    }

    if (page.PageNumber < page.PageCount) {
      rtn.nextDisabled = false
    } else {
      if (page.resultfull === 1) {
        rtn.nextDisabled = true
      } else {
        rtn.nextDisabled = false
      }
    }
    rtn.start = ((page.PageNumber - 1) * page.RowCount) + 1
    rtn.stop = (page.PageNumber * page.RowCount)
    if (page.PageNumber === page.PageCount) {
      rtn.start = page.Results - page.RowCount
      rtn.stop = page.Results
    }
    rtn.max = page.Results
    rtn.currentPage = page.PageNumber
    // console.log(rtn)
    return rtn
  }

  checkUrl (url: any) {
    // Fix url encoding issues - RFC 3986 says \ should %5C sadly most browsers don't implement this so for now just remove it.
    url = url.replace("\\", "")
    if (url.substring(0, 4) === 'http') {
      return url
    } else {
      return `${process.env.VUE_APP_API_ENDPOINT}/${url}`
    }
  }
}
