











import Component from 'vue-class-component'
import BannerApi from '@/services/BannerApi'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import Vue from 'vue'

interface MutationType {
  type: string
}

@Component
class BannerNotification extends Vue {
  bannerMessage = ''
  loading = false

  mounted() {
    this.$store.subscribe(this.subscribeHandler)
  }

  subscribeHandler = (mutation: MutationType) => {
    if (mutation.type === 'oidcStore/setOidcAuthIsChecked' && !this.loading) {
      this.fetchBanner()
      this.loading = true
    }
  }

  async fetchBanner() {
    try {
      const bannerData =
        (await new BannerApi().getBanner()) as DefaultResponseInterface
      if (bannerData?.data?.message) {
        this.bannerMessage = bannerData.data.message
      }
    } catch (error) {
      console.error('Error fetching banner:', error)
    }
  }
}

export default BannerNotification
