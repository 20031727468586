






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class BcStepBtnChoice extends Vue {
  @Prop({ type: Boolean, default: false }) readonly outlined!: boolean
  @Prop({ type: String, default: 'primary' }) readonly color!: string
  @Prop(String) readonly icon: any
  @Prop(String) readonly label: any
  @Prop(Boolean) readonly disabled: any
}
