






import './bc-simple-tableUx.scss'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class BcSimpleTableUx extends Vue {
}
