import { FileUploadInterface } from '@/typings/RequestInterface'

export function isRequired(errorMessage: string) {
  return (val: any) => {
    if (val) {
      return true
    }
    return errorMessage
  }
}

export function isEmail(errorMessage: string) {
  return (val: any) => {
    if (/.+@.+\..+/.test(val)) {
      return true
    }
    return errorMessage
  }
}

export function isPhone(errorMessage: string) {
  return (val: any) => {
    if (/^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(val)) {
      return true
    }
    return errorMessage
  }
}

/* export function isHouseNumber (errorMessage: string) {
  return (val: any) => {
    if (val !== undefined) {
      if (!val.trim()) return true
      if (!isNaN(parseFloat(val)) && val >= 0 && val <= 9999) return true
      return errorMessage
    }
    return ''
  }
} */

export function isZipCode(errorMessage: string) {
  return (val: any) => {
    if (val !== undefined) {
      if (!val.trim()) return true
      if (!isNaN(parseFloat(val)) && val >= 0 && val <= 9999) return true
      return errorMessage
    }
    return ''
  }
}

export function isVAT(errorMessage: string) {
  return (val: any) => {
    if (!val) return true
    if (/^(BE)?0[0-9]{9}$/.test(val)) return true
    return errorMessage
  }
}

export function isTexte(errorMessage: string) {
  return (val: any) => {
    if (/^([a-zàâçéèêëîïôûùüÿñæœ']+(-*\s*)*){2,}$/gi.test(val)) return true
    return errorMessage
  }
}

export function isWhiteSpaces(errorMessage: string) {
  return (val: any) => {
    if (!/^\s*$/gi.test(val)) return true
    return errorMessage
  }
}

export function isAddress(errorMessage: string) {
  return (val: any) => {
    if (!val) return true
    if (/^([a-zàâçéèêëîïôûùüÿñæœ'0-9]+(-*\s*)*){2,}$/gi.test(val)) return true
    return errorMessage
  }
}

export function isHouseNumber(errorMessage: string) {
  return (val: any) => {
    if (!val) return true
    if (/^([a-zàâçéèêëîïôûùüÿñæœ/a'0-9]+(-*\s*)*){1,}$/gi.test(val)) return true
    return errorMessage
  }
}

export function isLengthCheck(errorMessage: string) {
  return (val: any) => {
    const lengthCheck = 10

    if (val.length >= lengthCheck) {
      return true
    }

    return errorMessage
  }
}

export function isMaxLengthCheck(errorMessage: string, max: number) {
  return (val: any) => {
    const lengthCheck = max

    if (val.length <= lengthCheck) {
      return true
    }

    return errorMessage
  }
}

export function hasSpecialChars(errorMessage: any) {
  return (val: any) => {
    // eslint-disable-next-line
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

    if (specialChars.test(val)) {
      return true
    }

    return errorMessage
  }
}

export function hasIbanSpecialChars(val: any) {
  // eslint-disable-next-line
  const specialChars = /[`!@#$%^&*()\s_+\-=\[\]{};':"\\|,.<>\/?~]/

  if (specialChars.test(val)) {
    return true
  }

  return false
}

export function hasNumber(errorMessage: any) {
  return (val: any) => {
    if (/\d/.test(val)) {
      return true
    }

    return errorMessage
  }
}

export function IsUpper(errorMessage: string) {
  return (val: any) => {
    if (/[A-Z]/.test(val)) {
      return true
    }

    return errorMessage
  }
}

export function IsLower(errorMessage: string) {
  return (val: any) => {
    if (/[a-z]/.test(val)) {
      return true
    }

    return errorMessage
  }
}

export function IsFileName (errorMessage: string) {
  return (files: FileUploadInterface[]) => {
    if (files.length > 0) {
      let isAllowed = true
      const regex = /^(.*<.*>).*$/;
      for (let indexFile = 0; indexFile < files.length && isAllowed; indexFile++) {
        const fileName = files[indexFile].name.split('\\').pop();
        if (fileName) {
          isAllowed = !regex.test(fileName) 
        }
      }
      if (isAllowed) return true
      return errorMessage
    }
    return true
  }
}

export function IsAttachmentType(errorMessage: string) {
  const allowedTabType = [
    'application/pdf',
    'application/msword',
    'application/docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/gif',
    'image/jpeg',
    'image/jif',
    'image/jfif',
    'image/jfi',
    'image/jpe',
    'image/jpeg',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ]
  return (files: FileUploadInterface[]) => {
    if (files.length > 0) {
      let isAllowed = true
      for (let indexFile = 0; indexFile < files.length && isAllowed; indexFile++) {
        let found = false
        for (
          let indexAllow = 0;
          indexAllow < allowedTabType.length && !found;
          indexAllow++
        ) {
          found = allowedTabType[indexAllow] === files[indexFile].type
        }
        isAllowed = found
      }
      if (isAllowed) return true
      return errorMessage
    }
    return true
  }
}

export function IsSizeAttachment(errorMessage: string) {
  return (files: FileUploadInterface[]) => {
    if (files.length > 0) {
      let totalFileSize = 0
      for (const elm of files) {
        totalFileSize += elm.size
      }
      return totalFileSize <= 5000000 ? true: errorMessage
    }
    return true
  }
}
