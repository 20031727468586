


































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import usernav from './navigation'

@Component({
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' })
  }
})
export default class FixedNav extends Vue {
  nav = usernav
  model= 0

  get _items () {
    if (this.$store.getters['sideMenu/userMenu']) {
      if (this.usrDTA.data.Relation.IsCompany === 1 && this.usrDTA.data.role !== 'User') {
       for (let index = 0; index < usernav.length; index++) {
         const element = usernav[index];
         if (element.title === 'newnav.myUsers') {
           usernav.splice(index,1)
         }
       }
      } else {
        for (let index = 0; index < usernav.length; index++) {
          const element = usernav[index];
          if (element.title === 'newnav.myOrganisation' ) {
            usernav.splice(index,1)
          }
        }
      }
      return usernav
    }
  }

  get usrDTA (): any {
    // @ts-ignore
    return this.userDTA
  }

  get isVisible () {
    // @ts-ignore
    return this.$store.getters['sideMenu/visible']
  }

  set isVisible (value: boolean) {
    this.$store.commit('sideMenu/setVisible', value)
  }

  get visible () {
    return this.$store.getters['sideMenu/userMenu'] || this.$store.getters['sideMenu/adminMenu']
  }

}
