<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g xmlns="http://www.w3.org/2000/svg" id="Charging_Station" data-name="Charging Station"><rect x="5" y="54" width="38" height="6" rx="1" fill="#3c9265" data-original="#000000" style="" class=""/><path d="M47,52a6.0066,6.0066,0,0,1-6-6V35a1.0009,1.0009,0,0,0-1-1H38a1,1,0,0,1,0-2h2a3.0033,3.0033,0,0,1,3,3V46a4,4,0,0,0,8,0V21a1,1,0,0,1,2,0V46A6.0066,6.0066,0,0,1,47,52Z" fill="#3c9265" data-original="#000000" style="" class=""/><path d="M24.627,38.6338a1.0016,1.0016,0,0,1-.2071-.83l.794-3.97L21.0811,39H22.6a1,1,0,0,1,.9805,1.1963l-.794,3.97L26.9189,39H25.4A1,1,0,0,1,24.627,38.6338Z" fill="#3c9265" data-original="#000000" style="" class=""/><path d="M38,4H10A1,1,0,0,0,9,5V52H39V5A1,1,0,0,0,38,4ZM13,25a1,1,0,0,1,1-1h8a1,1,0,0,1,0,2H14A1,1,0,0,1,13,25ZM29.7813,38.6245l-8,10a1,1,0,0,1-1.7618-.8208L21.38,41H19a1,1,0,0,1-.7812-1.6245l8-10a1,1,0,0,1,1.7617.8208L26.62,37H29a1,1,0,0,1,.7813,1.6245ZM34,26H26a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Zm1-5a1,1,0,0,1-1,1H14a1,1,0,0,1-1-1V9a1,1,0,0,1,1-1H34a1,1,0,0,1,1,1Z" fill="#3c9265" data-original="#000000" style="" class=""/><rect x="15" y="10" width="18" height="10" fill="#3c9265" data-original="#000000" style="" class=""/><path d="M49,18v3a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V18Z" fill="#3c9265" data-original="#000000" style="" class=""/><path d="M58,8H56a7.0081,7.0081,0,0,0-7,7v1h6V15a1.0009,1.0009,0,0,1,1-1h2a1,1,0,0,0,1-1V9A1,1,0,0,0,58,8Z" fill="#3c9265" data-original="#000000" style="" class=""/></g></g></svg>
</template>

<script>
export default {
  name: 'CardQuestionMark',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
