import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref } from 'vue-property-decorator'
import { VDialog, VCard, VCardTitle, VCardText, VCardActions, VDivider, VSelect, VBtn, VIcon, VSpacer, VTextField } from 'vuetify/lib'
import './ExportWindow.scss'

@Component
export default class ExportWindow extends Vue {
  @Prop({ type: String }) readonly title?: string
  @Prop({ type: Boolean }) readonly loading?: boolean

  selectedDelimiter = ';'
  show = false
  delimiters: Array<any> = [
    { id: 1, name: 'Comma (,) CSV', delimiter: ',' },
    { id: 2, name: 'Semicolon (;) CSV', delimiter: ';' },
    { id: 3, name: 'Pipe (|) CSV', delimiter: '|' },
    { id: 4, name: 'JSON format', delimiter: 'json' }
  ]

  amount = '100'

  render (h: any) {
    const tmp = this.genDialog()
    // console.log(tmp)
    return h('div', [tmp])
  }

  genActivator (val: any) {
    return this.$createElement(VBtn, {
      props: {
        text: true,
        icon: true
      },
      on: {
        click: () => { this.show = !this.show }
      }
    }, [this.$createElement(VIcon, 'mdi-cloud-download')])
  }

  genDialog () {
    return this.$createElement(VDialog, {
      props: {
        value: this.show,
        width: 800
      },
      on: {
        input: (val: boolean) => { this.show = val }
      },
      scopedSlots: {
        activator: (val) => this.genActivator(val) as any,
        default: () => this.genCard() as any
      }
    })
  }

  doExport () {
    this.$emit('do:export', {
      amount: this.amount,
      delimiter: this.selectedDelimiter
    })
  }

  genCard () {
    return this.$createElement(VCard, [
      this.$createElement(VCardTitle, `Export ${this.title} Data`),
      this.$createElement(VDivider),
      this.$createElement(VCardActions, [
        this.$createElement(VSelect, {
          class: 'fix-select-input-whitespace',
          props: {
            value: this.selectedDelimiter,
            items: this.delimiters,
            itemValue: 'delimiter',
            itemText: 'name',
            label: 'Delimiter'
          },
          on: {
            change: (val: any) => { this.selectedDelimiter = val }
          }
        }),
        this.$createElement(VTextField, {
          props: {
            label: 'Amount',
            'presistent-hint': true,
            hint: 'Max amount of records',
            type: 'number',
            value: this.amount,
            max: 1000,
            width: 400,
            rules: [
              (val: string) => {
                if (parseInt(val) < 10) {
                  return 'value must be equal or greater than 10'
                } else if (parseInt(val) > 1000) {
                  return 'value must be equal or smaller than 1000'
                } else {
                  return true
                }
              }
            ]
          },
          class: 'ml-4',
          on: {
            input: (val: string) => {
              if (parseInt(val) < 10) {
                this.amount = '10'
              } else if (parseInt(val) > 1000) {
                this.amount = '1000'
              } else {
                this.amount = val
              }
            }
          }
        }),
        this.$createElement(VSpacer),
        this.$createElement(VBtn, {
          props: {
            text: true,
            loading: this.loading
          },
          on: {
            click: () => this.doExport()
          }
        }, 'Export')
      ])
    ])
  }
}
