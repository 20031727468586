import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { VBtn, VIcon, VMenu, VList, VListItem, VListItemTitle, VDivider, VCardText } from 'vuetify/lib'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({ userDT: 'user/userInfo' })
  }
})
export default class AccountMenu extends Vue {
  visible = false

  get items () {
    return [
      { key: 1, type: 'txt', align: 'left' },
      { key: 2, type: 'hr' },
      { key: 3, type: 'link', to: { name: 'ChangePassword' }, label: this.t('$vuetify.profile.changePassword'), align: 'left' },
      { key: 4, type: 'link', to: { name: 'myprofile' }, label: this.t('$vuetify.profile.myProfile'), align: 'left' },
      { key: 5, type: 'hr' },
      { key: 6, type: 'button', action: this.logout, label: this.t('$vuetify.profile.logout'), align: 'center' }
    ]
  }

  logout () {
    this.$store.dispatch('oidcStore/signOutOidc')
  }

  genActivator (on: any) {
    return this.$createElement(VBtn, {
      props: {
        text: true
      },
      on
    }, [
      this.$createElement(VIcon,{
        props: {
          color: 'white',
        },
      }, 'uil uil-user-circle'),
      this.$createElement(VIcon,{
        props: {
          color: 'white',
        },
      }, 'uil uil-angle-down')
    ])
  }

  genStructure () {
    const tmp: Array<any> = []
    this.items.forEach(el => {
      if (el.type === 'link') {
        tmp.push(this.$createElement(VListItem, { props: { to: el.to } }, [
          this.$createElement(VListItemTitle, {
            class: [`text-${el.align}, ${el.label}-btn`],
            domProps: { innerHTML: el.label }
          })
        ]))
      } else if (el.type === 'hr') {
        tmp.push(this.$createElement(VDivider))
      } else if (el.type === 'txt') {
        let elm = 'User'
        // @ts-ignore
        if (this.userDT.data !== undefined) {
          // @ts-ignore
          if (this.userDT.data.role !== 'User') {
            elm = this.t('$vuetify.profile.fleetManager')
          }
          tmp.push(this.$createElement(VCardText, {
            // @ts-ignore
            domProps: { innerHTML: this.userDT.data.RelationContact.Name + '<br>' + this.userDT.data.RelationContact.Email + '<br>' + elm } // this.userDT.data.role }
          }))
        }
      } else if (el.type === 'button') {
        // console.log('adding button')
        tmp.push(this.$createElement(VListItem, {
          on: {
            click: () => {
              if (el.action) {
                el.action()
              }
            }
          }
        },
        [
          this.$createElement(VListItemTitle, {
            class: [`text-${el.align}`],
            domProps: { innerHTML: el.label }
          })
        ]
        ))
      }
    })

    return tmp
  }

  render (h: any) {
    return h(VMenu, {
      props: {
        value: this.visible,
        'offset-y': true
      },
      on: {
        input: (val: boolean) => (this.visible = val)
      },
      scopedSlots: {
        activator: (props: any) => this.genActivator(props.on),
        default: () => this.$createElement(VList, { props: { dense: true } }, this.genStructure())
      }
    })
  }
}
