
























import Vue from 'vue'
import Component from 'vue-class-component'
import {Watch, Prop, Emit, PropSync} from 'vue-property-decorator'
import PaginationInterface from '../../../typings/PaginationInterface'
import {getMaxPageNumber, getNextPageNumber, shouldDisableNextButton} from "@/components/_base/BcDataTable/utils";

@Component
export default class GenericTablePagination extends Vue {
  @PropSync('pagination', {type: Object}) pag!: PaginationInterface

  rowsPerPageOptions: Array<number> = [10, 25, 50]
  rowsPerPage = 10

  get begin() {
    // console.log('BEGIN :')
    // console.log(this.pag)
    /* if (this.pag.pagenumber) {
      if (this.pag.pagecount === this.pag.pagenumber) {
        if (this.pag.resultfull && this.pag.results) {
          return this.pag.results - this.pag.pagenumber + 1
        } else {
          if (this.pag.pagenumber) {
            return 1 + (this.pag.pagenumber * (this.pag.pagenumber - 1))
          }
        }
      } else {
        if (this.pag.pagenumber) {
          return 1 + (this.pag.pagenumber * (this.pag.pagenumber - 1))
        }
      }
    } else {
      return 0
    } */
    return this.pag.pagenumber
  }

  get end() {
    // console.log('END _')
    // console.log(this.pag)
    if (this.pag.pagenumber) {
      // console.log('first if')
      if (this.pag.pagecount === this.pag.pagenumber) {
        if (this.pag.resultfull && this.pag.results) {
          return this.pag.results
        } else {
          if (this.pag.pagenumber) {
            return this.pag.pagenumber * (this.pag.pagenumber)
          }
        }
      } else {
        if (this.pag.pagenumber) {
          return this.pag.pagenumber * (this.pag.pagenumber)
        }
      }
    } else {
      return 0
    }
  }

  get max() {
    return getMaxPageNumber(this.pag.pagecount, this.pag.resultfull);
  }

  get disablePrevious() {
    if (this.pag.pagenumber) {
      if (this.pag.pagenumber > 1) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  @Watch('disablePrevious', {immediate: true})
  @Emit('updated:disablePrevious')
  disablePreviousEmit() {
    return this.disablePrevious
  }

  @Watch('disableNext', {immediate: true})
  @Emit('updated:disableNext')
  disableNextEmit() {
    return this.disableNext
  }

  get disableNext() {
    return shouldDisableNextButton(this.pag.pagenumber, this.pag.pagecount, this.pag.resultfull)
  }

  nextPage() {
    const page = getNextPageNumber(this.pag.pagecount, this.pag.pagenumber)
    this.pag = {
      ...this.pag,
      pagenumber: page
    }
  }

  previousPage() {
    let page = 1
    if (this.pag.pagecount && this.pag.pagenumber) {
      if (this.pag.pagecount < this.pag.pagenumber) {
        page = this.pag.pagecount
      } else {
        if (this.pag.pagenumber) {
          page = this.pag.pagenumber - 1
        }
      }
    } else {
      if (this.pag.pagenumber) {
        page = this.pag.pagenumber - 1
      }
    }
    this.pag = {
      ...this.pag,
      pagenumber: page
    }
  }

  updateRowsPerPage(val: any) {
    this.pag = {
      ...this.pag,
      pagesize: val
    }
  }
}
