

















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref } from 'vue-property-decorator'
import FilterContent from './FilterContent.vue'
import { createFiltersObject } from '../../../functions/filterFunctions'
import FilterOptions from '../../../typings/FilterOptions'

@Component({
  components: {
    FilterContent
  }
})
export default class FilterContainer extends Vue {
  @Prop(Array) readonly filterOptions!: Array<FilterOptions>
  @Ref() readonly filterComponentBig!: FilterContent
  @Ref() readonly filterComponentSmall!: FilterContent
  activator = false
  activatorX = 0
  activatorY = 0
  filters: Array<any> = []
  tags: Array<string> = []

  @Emit('search')
  onSearch () {
    return 'search'
  }

  clickFilterDropdown (e: MouseEvent) {
    e.preventDefault()
    this.activator = false
    this.activatorX = e.clientX
    this.activatorY = e.clientY

    this.$nextTick(() => {
      this.activator = true
    })
  }

  updateFilters (f: any) {
    this.filters = f
    const t: Array<string> = []
    f.forEach((e: any) => {
      let tm = ''
      if (e.operator === 'EX') {
        tm = 'is'
      } else if (e.operator === 'CO') {
        tm = 'contains'
      } else if (e.operator === 'EW') {
        tm = 'ends with'
      } else if (e.operator === 'SW') {
        tm = 'starts with'
      }
      if (e.field && e.value) {
        t.push(`${e.field} ${tm} ${e.value}`)
      }
    })
    this.tags = t
  }

  get generateFilter () {
    return createFiltersObject(this.filters)
  }

  @Watch('generateFilter', { deep: true })
  @Emit('updated:qs')
  emitUpdateQS () {
    return this.generateFilter
  }

  get isSmall () {
    return this.$vuetify.breakpoint.mdAndDown
  }

  deleteTag (tag: any) {
    if (this.isSmall) {
      this.filterComponentSmall.deleteFilter(tag.id)
    } else {
      this.filterComponentBig.deleteFilter(tag.id)
    }
  }
}
