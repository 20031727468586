










import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'

@Component
export default class FilterRowContent extends Vue {
  @Prop({ type: Array }) readonly headers!: Array<any>
  @Prop({ type: Object }) readonly filter!: any
  @Prop({ type: Number }) readonly index!: number

  value = ''
  field: any = null
  operator: any = 'EX'
  operators: Array<any> = [{ value: 'SW', text: 'Starts with...' }, { value: 'EW', text: 'Ends with...' }, { value: 'CO', text: 'Contains...' }, { value: 'EX', text: 'Is Exact...' }]

  get output () {
    if (this.field && this.value.trim()) {
      const f: any = {
        field: this.field,
        operator: this.operator,
        value: this.value.trim(),
        id: this.index
      }
      return f
    } else {
      return null
    }
  }

  @Watch('output')
  @Emit('update:filter')
  doUpdate () {
    return this.output
  }

  @Emit('delete')
  doDelete () {
    return this.filter.id
  }
}
