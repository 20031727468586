<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
<g>
<path d="M467,16H45C20.187,16,0,36.187,0,61v300c0,24.813,20.187,45,45,45h46v75c0,6.067,3.655,11.537,9.26,13.858
c5.606,2.321,12.057,1.038,16.347-3.252L202.213,406H467c24.813,0,45-20.187,45-45V61C512,36.187,491.813,16,467,16z M482,361
c0,8.271-6.729,15-15,15H196c-3.978,0-7.793,1.581-10.606,4.394L121,444.786V391c0-8.284-6.716-15-15-15H45
c-8.271,0-15-6.729-15-15V61c0-8.271,6.729-15,15-15h422c8.271,0,15,6.729,15,15V361z"/>
</g>
</g>
<g>
<g>
<path d="M256,76c-74.439,0-135,60.561-135,135s60.561,135,135,135s135-60.561,135-135S330.439,76,256,76z M256,316
c-57.897,0-105-47.103-105-105c0-57.897,47.103-105,105-105c57.897,0,105,47.103,105,105C361,268.897,313.897,316,256,316z"/>
</g>
</g>
<g>
<g>
<path d="M309.031,245.369c-14.148-14.15-32.982-21.943-53.031-21.943s-38.883,7.793-53.033,21.943
c-5.857,5.858-5.857,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0c8.483-8.483,19.784-13.156,31.819-13.156
c12.035,0,23.336,4.672,31.819,13.156c5.86,5.859,15.356,5.857,21.213,0C314.889,260.724,314.889,251.227,309.031,245.369z"/>
</g>
</g>
<g>
<g>
<circle cx="211" cy="181" r="15"/>
</g>
</g>
<g>
<g>
<circle cx="301" cy="181" r="15"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</template>

<script>
export default {
  name: 'CardQuestionMark',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
