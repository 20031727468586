export default {
  AF: 'Afghanistan',
  AX: 'Åland',
  AL: 'Albanië',
  DZ: 'Algerije',
  AS: 'Amerikaans-Samoa',
  VI: 'Amerikaanse Maagdeneilanden',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua en Barbuda',
  AR: 'Argentinië',
  AM: 'Armenië',
  AW: 'Aruba',
  AU: 'Australië',
  AZ: 'Azerbeidzjan',
  BS: 'Bahama’s',
  BH: 'Bahrein',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'België',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnië en Herzegovina',
  BW: 'Botswana',
  BV: 'Bouveteiland',
  BR: 'Brazilië',
  IO: 'Brits Indische Oceaanterritorium',
  VG: 'Britse Maagdeneilanden',
  BN: 'Brunei',
  BG: 'Bulgarije',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodja',
  CA: 'Canada',
  BQ: 'Caribisch Nederland',
  CF: 'Centraal-Afrikaanse Republiek',
  CL: 'Chili',
  CN: 'China',
  CX: 'Christmaseiland',
  CC: 'Cocoseilanden',
  CO: 'Colombia',
  KM: 'Comoren',
  CG: 'Congo-Brazzaville',
  CD: 'Congo-Kinshasa',
  CK: 'Cookeilanden',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  DK: 'Denemarken',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominicaanse Republiek',
  DE: 'Duitsland',
  EC: 'Ecuador',
  EG: 'Egypte',
  SV: 'El Salvador',
  GQ: 'Equatoriaal-Guinea',
  ER: 'Eritrea',
  EE: 'Estland',
  SZ: 'eSwatini',
  ET: 'Ethiopië',
  FO: 'Faeröer',
  FK: 'Falklandeilanden',
  FJ: 'Fiji',
  PH: 'Filipijnen',
  FI: 'Finland',
  FR: 'Frankrijk',
  GF: 'Frans-Guyana',
  PF: 'Frans-Polynesië',
  TF: 'Franse Gebieden in de zuidelijke Indische Oceaan',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgië',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenada',
  GR: 'Griekenland',
  GL: 'Groenland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinee',
  GW: 'Guinee-Bissau',
  GY: 'Guyana',
  HT: 'Haïti',
  HM: 'Heard en McDonaldeilanden',
  HN: 'Honduras',
  HU: 'Hongarije',
  HK: 'Hongkong SAR van China',
  IE: 'Ierland',
  IS: 'IJsland',
  IN: 'India',
  ID: 'Indonesië',
  IQ: 'Irak',
  IR: 'Iran',
  IM: 'Isle of Man',
  IL: 'Israël',
  IT: 'Italië',
  CI: 'Ivoorkust',
  JM: 'Jamaica',
  JP: 'Japan',
  YE: 'Jemen',
  JE: 'Jersey',
  JO: 'Jordanië',
  KY: 'Kaaimaneilanden',
  CV: 'Kaapverdië',
  CM: 'Kameroen',
  KZ: 'Kazachstan',
  KE: 'Kenia',
  KG: 'Kirgizië',
  KI: 'Kiribati',
  UM: 'Kleine afgelegen eilanden van de Verenigde Staten',
  KW: 'Koeweit',
  HR: 'Kroatië',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Letland',
  LB: 'Libanon',
  LR: 'Liberia',
  LY: 'Libië',
  LI: 'Liechtenstein',
  LT: 'Litouwen',
  LU: 'Luxemburg',
  MO: 'Macau SAR van China',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MV: 'Maldiven',
  MY: 'Maleisië',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marokko',
  MH: 'Marshalleilanden',
  MQ: 'Martinique',
  MR: 'Mauritanië',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldavië',
  MC: 'Monaco',
  MN: 'Mongolië',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  MM: 'Myanmar (Birma)',
  NA: 'Namibië',
  NR: 'Nauru',
  NL: 'Nederland',
  NP: 'Nepal',
  NI: 'Nicaragua',
  NC: 'Nieuw-Caledonië',
  NZ: 'Nieuw-Zeeland',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  KP: 'Noord-Korea',
  MK: 'Noord-Macedonië',
  MP: 'Noordelijke Marianen',
  NO: 'Noorwegen',
  NF: 'Norfolk',
  UG: 'Oeganda',
  UA: 'Oekraïne',
  UZ: 'Oezbekistan',
  OM: 'Oman',
  TL: 'Oost-Timor',
  AT: 'Oostenrijk',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestijnse gebieden',
  PA: 'Panama',
  PG: 'Papoea-Nieuw-Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PN: 'Pitcairneilanden',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Roemenië',
  RU: 'Rusland',
  RW: 'Rwanda',
  KN: 'Saint Kitts en Nevis',
  LC: 'Saint Lucia',
  VC: 'Saint Vincent en de Grenadines',
  BL: 'Saint-Barthélemy',
  MF: 'Saint-Martin',
  PM: 'Saint-Pierre en Miquelon',
  SB: 'Salomonseilanden',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tomé en Principe',
  SA: 'Saoedi-Arabië',
  SN: 'Senegal',
  RS: 'Servië',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SH: 'Sint-Helena',
  SX: 'Sint-Maarten',
  SI: 'Slovenië',
  SK: 'Slowakije',
  SD: 'Soedan',
  SO: 'Somalië',
  ES: 'Spanje',
  SJ: 'Spitsbergen en Jan Mayen',
  LK: 'Sri Lanka',
  SR: 'Suriname',
  SY: 'Syrië',
  TJ: 'Tadzjikistan',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad en Tobago',
  TD: 'Tsjaad',
  CZ: 'Tsjechië',
  TN: 'Tunesië',
  TR: 'Turkije',
  TM: 'Turkmenistan',
  TC: 'Turks- en Caicoseilanden',
  TV: 'Tuvalu',
  UY: 'Uruguay',
  VU: 'Vanuatu',
  VA: 'Vaticaanstad',
  VE: 'Venezuela',
  GB: 'Verenigd Koninkrijk',
  AE: 'Verenigde Arabische Emiraten',
  US: 'Verenigde Staten',
  VN: 'Vietnam',
  WF: 'Wallis en Futuna',
  EH: 'Westelijke Sahara',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  ZA: 'Zuid-Afrika',
  GS: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
  KR: 'Zuid-Korea',
  SS: 'Zuid-Soedan',
  SE: 'Zweden',
  CH: 'Zwitserland'
}
