














































import { Component, Vue, Watch } from 'vue-property-decorator'
import RelationApi from '@/services/RelationApi'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { RelationFilterInterface } from '@/typings/RelationFilterInterface'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import {
  GENERIC_FILTER_CHARGEPASS_REQUEST,
  GENERIC_FILTER_CHARGING_HISTORY,
  GENERIC_FILTER_CARDS,
  GENERIC_FILTER_CHARGERS
} from '@/mocks/featureFlags'

@Component({
  filters: {
    toMaxLength(elm: string) {
      if (elm.length > 30) {
        return elm.substring(0, 30) + '...'
      }
      return elm
    }
  },
  computed: {
    getColor() {
      if (process.env.VUE_APP_BAR_BG) {
        return process.env.VUE_APP_BAR_BG
      } else {
        return 'black lighten-1'
      }
    }
  }
})
class GenericRelationFilter extends Vue {
  relations: RelationFilterInterface[] = []
  selectedRelation = null
  isLoading = true

  get genericRelationFilterFlag() {
    return this.isAllowed()
  }

  relationIcon(isCompany: boolean) {
    if (isCompany) return 'mdi-domain'
    return 'mdi-account'
  }

  relationIconAltText(isCompany: boolean) {
    if (isCompany) return 'company icon'
    return 'person icon'
  }

  handleDefaultSelectedRelation() {
    const relation = this.$store?.state?.user?.usrInfo?.data?.Relation
    relation.Type = 'relation'
    if (relation) {
      relation.Value = relation.Id + relation.Description
      this.selectedRelation = relation
    }
  }

  isAllowed() {
    return (
      isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/requests',
        flag: GENERIC_FILTER_CHARGEPASS_REQUEST
      }) ||
      isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/chargesession',
        flag: GENERIC_FILTER_CHARGING_HISTORY
      }) ||
      isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/tokens',
        flag: GENERIC_FILTER_CARDS
      }) ||
      isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/chargepoints',
        flag: GENERIC_FILTER_CHARGERS
      })
    )
  }

  async updated() {
    const userAllowed = this.isAllowed()
    // add param for filtering for only relations
    const isRelationsOnly = this.$route.path === '/my/chargepoints'

    if (userAllowed && this.relations.length === 0) {
      const relations = (await new RelationApi().getRelationsForCurrentUser(
        isRelationsOnly
      )) as DefaultResponseInterface
      this.isLoading = false
      this.relations = relations.data
      this.relations = this.relations.map((obj: RelationFilterInterface) => {
        const trimmedDescription = obj.Description.replace(/ +(?= )/g, '')
        return {
          ...obj,
          Description: trimmedDescription,
          Value: `${obj.Id}${trimmedDescription}`
        }
      })

      this.handleDefaultSelectedRelation()
    }
  }

  @Watch('selectedRelation')
  setRelation(relationId: string) {
    this.$store.commit('genericRelationFilter/setSelectedRelation', relationId)
  }
}

export default GenericRelationFilter
