export default {
  profile: {
    fleetManager: 'Fleet Manager',
    changePassword: 'change password',
    myProfile: 'My profile',
    logout: 'Log Out',
    currentPswd: 'current password',
    newPswd: 'new password',
    repeatPswd: 'repeat password',
    successUpdate: 'Account succesfully updated',
    local: 'nl-NL',
    locale: 'nl'
  },
  requests: {
    title: 'Requests'
  },
  common: {
    close: 'Sluiten',
    noDataText: 'Geen data beschikbaar'
  },
  dataIterator: {
    pageText: '{0}-{1} of {2}',
    noResultsText: 'Geen resultaten gevonden',
    loadingText: 'Gegevens laden...'
  },
  dataTable: {
    itemsPerPageText: 'Rije per pagina:',
    rowsPerPageText: 'Rije per pagina:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.'
    }
  },
  dataFooter: {
    itemsPerPageText: 'Items per pagina:',
    itemsPerPageAll: 'Alles',
    nextPage: 'Volgende pagina',
    prevPage: 'Vorige pagina',
    firstPage: 'Eerste pagina',
    lastPage: 'Laatste pagina',
    pageText: ''
  },
  datePicker: {
    itemsSelected: '{0} geselecteerd'
  },
  noDataText: 'Geen data beschikbaar',
  carousel: {
    prev: 'Vorige afbeelding',
    next: 'Volgende afbeelding'
  },
  calendar: {
    moreEvents: '{0} meer'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  },
  cdr: {
    sessionId: 'Sessie id',
    startStopSession: 'Start/Stop Sessie',
    startSession: 'Start sessie',
    sessionDuration: 'Sessieduur',
    token: 'Laadpas',
    consumption: 'Verbruik',
    status: 'Status',
    price: 'Prijs',
    actions: 'Acties',
    connectorNumber: 'Connector nr',
    cpMonitor: 'Chargepoint Monitor',
    myLocations: 'Mijn Locaties'
  },
  chargelocation: {
    street: 'straat',
    city: 'Stad',
    county: 'land',
    landOwner: 'landeigenaar',
    description: 'beschrijving',
    houseNr: 'huisnummer',
    postalCode: 'postcode',
    owner: 'eigenaar',
    mergeMode: 'Samenvoeg modus',
    newLocation: 'Nieuwe Locatie',
    mergeSelected: 'geslecteerde samenvoegen',
    searchLocation: 'Zoek locatie',
    Id: 'Id',
    label: 'Label',
    Description: 'Omschrijving',
    Street: 'Straat',
    City: 'Stad',
    LandOwner: 'Land Eigenaar',
    Owner: 'Eigenaar',
    Status: 'Status',
    Enable: 'Inschakelen',
    Disable: 'Uitschakelen',
    validate3char: 'Typ minimaal 3 karakters'
  },
  chargepoint: {
    activeSessions: 'Actieve sessies',
    pastSessions: 'Laatste Sessies',
    tools: 'Tools',
    Authorizes: 'Authorisaties',
    ChargeBoxIdentifier: 'ChargeBox Identifier',
    Owner: 'Eigenaar',
    ChargeBoxSN: 'ChargeBox Serienummer',
    Model: 'Model',
    OnlineStateDateTime: 'Laatst teken van leven',
    id: 'Id',
    filters: 'Filters',
    AVAILABLE: 'BESCHIKBAAR',
    lastBootNotification: 'Laatste bootnotification',
    description: 'Beschrijving',
    viewLocation: 'Bekijk locatie',
    moreInfo: 'Meer informatie',
    currentError: 'Huidige foutmelding',
    adminstate: 'Admin status',
    EndpointUrl: 'Endpoint',
    EndpointIp: 'Ip address',
    IccId: 'Icc',
    Imsi: 'Imsi',
    FirmwareVersion: 'Firmware versie',
    MeterType: 'Meter Type',
    MeterSN: 'Meter Serienummer',
    Vendor: 'Fabrikant',
    Longitude: 'Longitude',
    Latitude: 'Latitude',
    chargepoints: 'laadpunten'
  },
  navbar: {
    home: 'Home',
    locations: 'Locaties',
    chargeLocationsConfig: 'Laad lokaties config',
    cdrs: 'Laadsessie details',
    chargepoints: 'Laadpunten',
    findToken: 'Vind laadpas',
    tokenRequests: 'Laadpas aanvragen',
    bulkUpdateSubs: 'Bulk Update Abonnementen',
    offlineCPs: 'Offline Laadpunten',
    whiteList: 'Whitelist',
    customers: 'Relations',
    CPOchargepoints: 'CPO Laadpunten',
    Reports: 'Raporten',
    CPOContract: 'CPO Contracten',
    superAdminTools: 'Super Admin Tools',
    alarms: 'alarms',
    scheduler: 'Scheduler',
    transTool: 'Translation Tool',
    cpMonitor: 'Chargepoint Monitor',
    myLocations: 'Mijn locaties',
    subscriptionType: 'Subscription Type',
    logsTools: 'Logs tools',
    chargepointMonitor: 'Chargepoint Monitor',
    genclassexp: 'Generic Class explorer',
    genericTables: 'Generic Tables',
    testTom: 'Toms Test Page',
    managementTitle: 'Management',
    subType: 'Subscription Type',
    emails: 'Emails',
    logTools: 'Log Tools',
    Alarms: 'Alarms',
    Backlogs: 'Backlogs',
    Errors: 'Errors',
    Scheduler: 'Scheduler',
    TranslationTool: 'TranslationTool',
    GenericClassesexplorer: 'GenericClassesexplorer',
    GenericTables: 'GenericTables',
    ProtectedUserInfo: 'ProtectedUserInfo',
    TestPageTom: 'TestPageTom',
    relSubs: 'Relations/Subscriptions',
    Connections: 'Connections',
    SessionInError: 'Sessions In Error',
    BulkSendCommand: 'Bulk Send Command'
  },
  tariffs: {
    tariffType: 'tariffType',
    AC: 'AC',
    DC: 'DC',
    Roaming: 'Roaming'
  },
  ChargeLocationDetail: {
    Offline: 'Offline',
    Online: 'Online',
    Available: 'Beschikbaar',
    Charging: 'Laden',
    Parking: 'Geparkeerd',
    Error: 'Error',
    SaveChanges: 'Wijzigingen opslaan',
    Edit: 'Bewerk',
    Published: 'Gepubliseerd',
    NotPublished: 'Niet gepubliceerd',
    ViewChargepoint: 'Bekijk Chargepoint',
    EvseId: 'Evse Id',
    SyncUpdateDateTime: 'Last Sync',
    LocationInfo: 'locatie informatie',
    Description: 'Beschrijving',
    DescriptionLocationToChargePoints: 'Wegbeschrijving naar oplaadpunt',
    DescriptionRouteToLocation: 'Route naar locatie',
    ContactInfo: 'Contactgegevens',
    CreateLocation: 'Locatie aanmaken',
    CoordinateQuickFill: 'Voer het adres in om snel de locatiegegevens in te vullen',
    NoGPS: 'Geen gps-coördinaten gevonden',
    Search: 'Zoeken',
    DragIconOnMap: 'Sleep het pictogram op de kaart',
    Save: 'Opslaan',
    EditCoordinates: 'Coördinaten bewerken',
    Label: 'Label',
    Floor: 'Verdieping',
    Street: 'Straat',
    HouseNumber: 'Huisnummer',
    ZipCode: 'Post code',
    City: 'Stad',
    Country: 'Land',
    Language: 'Taal',
    LocationMobile: 'Locatie Mobiel',
    LocationPhone: 'Locatie Telefoon nummer',
    Phone: 'Telefoon nummer',
    LocationEmail: 'Locatie email'
  },
  newnav: {
    chargepoints: 'Laadpunten',
    subscriptions: 'Abonnementen',
    tokens: 'Laadpassen',
    myOrganisation: 'Mijn organisatie',
    chargesession: 'Laadsessies'
  }
}
