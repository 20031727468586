/* eslint-disable */

import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

/*
  include cable mis
*/

@Module({ namespaced: true })
export default class ChargerInstallationState extends VuexModule {
  current = 'StepChargerType'
  previous: Array<any> = []

  elm : any = {
    isCompany: false,
    serialNumber: '',
    chargepointID: '',
    relationId: '',
    chargertype: 'PRIVATE',
    relationContactId: '',
    isPlugCharge: false,
    isGuestCharge: false,
    isRFID: false,
    plugcharge: {
      isReimbursement: false,
      customfee: '',
      isCustomfee: false,
      isCreg: false,
      reimburser: '',
      tariffid: {
        Code: ''
      }
    },
    isPublishMap: false,
    guestcharge: {
      isFree: false,
      isBlueCornerFee: false,
      isCustomfee: false,
      isMapFree: false,
      isMapPaid: false,
      typeCharge: 0,
      customfee: 0,
      isReimburse: false,
      tariffid: {
        Code: ''
      }
    },
    location: {
      relationId: '',
      street: '',
      city: '',
      name: '',
      description: '',
      housenumber: '',
      postalcode: '',
      latitude: 0,
      longitude: 0,
      country: ''
    },
    accessCard: {
      whitelistGroup: '',
      whitelistTokenGroup: '',
      subscriptiontypeid: '',
      tokens: [],
      rfidTokens: [],
      rfidTokensReimb: [],
      tokensReimb: [],
      isReimburse: false,
      reimburserId: ''
    }
  }

  @Mutation
  setReset () {
    this.current = 'StepChargerType'
    this.previous = []
    this.elm.isPlugCharge= false,
    this.elm.isGuestCharge= false,
    this.elm.isRFID=false,
    this.elm.plugcharge= {
      isReimbursement: false,
      customfee: '',
      isCustomfee: false,
      isCreg: false,
      reimburser: '',
      tariffid: {
        Code: ''
      }
    }
    this.elm.isPublishMap=false,
    this.elm.guestcharge= {
      isFree: false,
      isBlueCornerFee: false,
      isCustomfee: false,
      isMapFree: false,
      isMapPaid: false,
      typeCharge: 0,
      customfee: 0,
      isReimburse: false,
      tariffid: {
        Code: ''
      }
    }
    this.elm.accessCard= {
      whitelistGroup: '',
      whitelistTokenGroup: '',
      subscriptiontypeid: '',
      tokens: [],
      rfidTokens: [],
      isReimburse: false,
      reimburserId: '',
      rfidTokensReimb: [],
      tokensReimb: [],
      reimbsubscriptiontypeid: ''
    }
  }

  @Mutation
  setAccessCard (val: any) {
    this.elm.accessCard = val
  }

  @Mutation
  setAccessCardSubscriptionId (val: any) {
    this.elm.accessCard.subscriptiontypeid = val
  }

  @Mutation
  setWhiteList (val: any) {
    this.elm.accessCard.subscriptiontypeid = val
  }

  @Mutation
  setReimbWhiteList (val: any) {
    this.elm.accessCard.reimbsubscriptiontypeid = val
  }

  @Mutation
  setStep (name: any) {
    this.previous.push(this.current)
    this.current = name
  }

  @Mutation
  setResetToken () {
    this.elm.accessCard.tokens = []
    this.elm.accessCard.rfidTokens = []
    this.elm.accessCard.subscriptiontypeid = ''
    this.elm.accessCard.reimburserId = ''
    this.elm.accessCard.rfidTokensReimb = []
    this.elm.accessCard.tokensReimb = []
    this.elm.accessCard.reimbsubscriptiontypeid = ''
  }

  @Mutation
  setPreviousStepName () {
    if (this.previous.length > 0) {
      this.current = this.previous.pop()
    }
  }

  // TODO REMOVE NOT USED CODE
  @Mutation
  setIsCompany (val: any) {
    this.elm.isCompany = val
  }

  @Mutation
  setSerialNumber (val: any) {
    this.elm.serialNumber = val
  }

  @Mutation
  setChargepointID (val: any) {
    this.elm.chargepointID = val
  }

  @Mutation
  setRelationId (val: any) {
    this.elm.relationId = val
  }

  @Mutation
  setRelationContactId (val: any) {
    this.elm.relationContactId = val
  }

  @Mutation
  setIsPlugCharge (val: any) {
    this.elm.isPlugCharge = val
  }

  @Mutation
  setPlugChargeIsReimbursement (val: any) {
    this.elm.plugcharge.isReimbursement = val
  }

  @Mutation
  setAccessCardIsReimbursement (val: any) {
    this.elm.accessCard.isReimburse = val
  }

  @Mutation
  setGuestChargeIsReimbursement (val: any) {
    this.elm.guestcharge.isReimburse = val
  }

  @Mutation
  setGuestchargeIsBlueCornerFee (val: any) {
    this.elm.guestcharge.isBlueCornerFee = val
  }

  @Mutation
  setGuestchargeIsFree (val: any) {
    this.elm.guestcharge.isFree = val
  }

  @Mutation
  setIsPublishMap (val: any) {
    this.elm.isPublishMap = val
  }

  @Mutation
  setLocation (val: any) {
    this.elm.location.street = val.Street
    this.elm.location.city = val.City
    this.elm.location.country = val.Country
    this.elm.location.description = val.Description
    this.elm.location.housenumber = val.HouseNumber
    this.elm.location.name = val.Label
    this.elm.location.latitude = val.Latitude
    this.elm.location.longitude = val.Longitude
    this.elm.location.postalcode = val.ZipCode
  }

  @Mutation
  setIsRFID (val: any) {
    this.elm.isRFID = val
  }

  @Mutation
  setIsGuestCharge (val: any) {
    this.elm.isGuestCharge = val
  }

  @Mutation
  setPlugChargeReimburser (val: any) {
    this.elm.plugcharge.reimburser = val
  }

  @Mutation
  setWLReimburser (val: any) {
    this.elm.accessCard.reimburserId = val
  }

  @Mutation
  setPlugChargeCustomFee (val: any) {
    this.elm.plugcharge.isCustomfee = val.isCustomfee
    this.elm.plugcharge.customfee = val.val
  }

  @Mutation
  setPlugChargeCreg (val: any) {
    this.elm.plugcharge.isCreg = val.isCreg
    this.elm.plugcharge.tariffid.Code = val.code
  }

  @Mutation
  setGuestChargeCustomFee (val: any) {
    this.elm.guestcharge.isCustomfee = val.isCustomfee
    this.elm.guestcharge.customfee = val.val
    this.elm.guestcharge.tariffid.Code = val.val
  }

  @Mutation
  setIsCustomFee (val: any) {
    this.elm.guestcharge.isCustomfee = val
  }

  @Mutation
  addToken (val: string) {
    const payload = {
      Id: val,
      Description: ''
    }
    this.elm.accessCard.tokens.push(payload)
    // this.elm.guestcharge.isCustomfee
    // Object.assign(this.token[this.editedIndex], this.token)
  }

  @Mutation
  addReimbToken (val: string) {
    const payload = {
      Id: val,
      Description: ''
    }
    this.elm.accessCard.tokensReimb.push(payload)
    // this.elm.guestcharge.isCustomfee
    // Object.assign(this.token[this.editedIndex], this.token)
  }


  @Mutation
  addRFIDToken (val: string) {
    this.elm.accessCard.rfidTokens.push(val)
    // this.elm.guestcharge.isCustomfee
    // Object.assign(this.token[this.editedIndex], this.token)
  }

  @Mutation
  deleteRFIDToken (index: number) {
    this.elm.accessCard.rfidTokens.splice(index, 1)
    // this.elm.guestcharge.isCustomfee
    // Object.assign(this.token[this.editedIndex], this.token)
  }
  @Mutation
  deleteToken (index: number) {
    this.elm.accessCard.tokens.splice(index, 1)
    // this.elm.guestcharge.isCustomfee
    // Object.assign(this.token[this.editedIndex], this.token)
  }

  @Mutation
  deleteReimbToken (index: number) {
    this.elm.accessCard.tokensReimb.splice(index, 1)
  }
  @Mutation
  deleteRFIDReimbToken (index: number) {
    this.elm.accessCard.rfidTokensReimb.splice(index, 1)
  }

  @Mutation
  addRFIDReimbToken (val: string) {
    this.elm.accessCard.rfidTokensReimb.push(val)
  }

  @Mutation
  setAddress (val: any) {
    this.elm.location = {
      relationId: val.Id,
      street: val.Street,
      city: val.Street,
      name: '',
      description: '',
      housenumber: val.HouseNumber,
      postalcode: val.PostalCode,
      latitude: 0,
      longitude: 0,
      country: val.Country
    }
    // this.elm.accessCard.tokens.push(val)
    // this.elm.guestcharge.isCustomfee
    // Object.assign(this.token[this.editedIndex], this.token)
  }



  get stepName () {
    return this.current
  }

  get elmState () {
    return this.elm
  }

  get previousStepName () {
    return this.previous[(this.previous.length - 1)]
  }
}
