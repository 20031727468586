






import './bc-simple-table.scss'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class BcSimpleTable extends Vue {
}
