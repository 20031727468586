<template>
<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m391 512c57.897 0 105-47.552 105-106 0-55.405-43.135-100.918-97.586-104.735-18.785-53.686-69.63-90.265-127.414-90.265h-120c-74.439 0-135 60.561-135 135v45h271.076c-.704 4.901-1.076 9.907-1.076 15 0 58.448 47.103 106 105 106zm0-181c41.355 0 75 33.645 75 75 0 41.906-33.645 76-75 76s-75-34.094-75-76c0-41.355 33.645-75 75-75zm-345 30v-15c0-57.897 47.103-105 105-105h120c42.215 0 79.656 25.096 96.156 62.733-31.406 7.321-57.44 28.776-71.011 57.267z"/><path d="m365.394 359.18-21.213 21.213 25.606 25.607-25.606 25.607 21.213 21.213 25.606-25.607 25.606 25.607 21.213-21.213-25.606-25.607 25.606-25.607-21.213-21.213-25.606 25.607z"/><path d="m211 211c57.897 0 105-47.103 105-105 0-58.448-47.103-106-105-106s-105 47.552-105 106c0 57.897 47.103 105 105 105zm0-30c-41.355 0-75-33.645-75-75 0-41.906 33.645-76 75-76s75 34.094 75 76c0 41.355-33.645 75-75 75z"/></g></svg></template>

<script>
export default {
  name: 'UserNotAllowedAccount',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
