

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'

@Component
export default class BcNotification extends Vue {
  show = false
  message = ''
  color = 'info'
  timeout = 6000
  top = false
  left = false
  right = false
  bottom = false
  multiline = false

  created () {
    this.$store.subscribe((mutation: any, state: any) => {
      if (mutation.type === 'notification/showMessage') {
        this.message = state.notification.content
        this.color = state.notification.color
        this.timeout = state.notification.timeout
        this.position(state)
        this.multiline = state.notification.multiline
        this.show = true
      }
    })
  }

  position (state: any) {
    if (state.notification.position.length > 0) {
      // console.log(state.notification.position)
      if (/top/.test(state.notification.position)) {
        this.top = true
      } else {
        this.top = false
      }
      if (/bottom/.test(state.notification.position)) {
        this.bottom = true
      } else {
        this.bottom = false
      }
      if (/left/.test(state.notification.position)) {
        this.left = true
      } else {
        this.left = false
      }
      if (/right/.test(state.notification.position)) {
        this.right = true
      } else {
        this.right = false
      }
    } else {
      this.top = true
      this.right = true
    }
  }
}
