<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    viewBox="0 0 210 190"
    :role="role"
  >
    <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-107)">
    <path
       id="path815"
       d="M 190.40336,239.79614 H 19.519008 V 125.87309 H 190.40336 m 0,-18.98729 H 19.519008 c -10.5378518,0 -18.98712725,8.44932 -18.98712725,18.98729 v 113.92305 a 18.98715,18.98715 0 0 0 18.98712725,18.9869 h 66.455047 v 18.98727 H 66.986894 v 18.98727 h 75.948616 v -18.98727 h -18.98715 v -18.98727 h 66.455 a 18.98715,18.98715 0 0 0 18.98718,-18.9869 V 125.87309 c 0,-10.53797 -8.54423,-18.98729 -18.98718,-18.98729 z"
       inkscape:connector-curvature="0"
       style="stroke-width:9.49357414" />
    <path
       id="path826"
       d="m 141.62441,157.613 0.0459,-0.0459 -17.38849,-17.38836 -4.95476,4.95476 9.86277,9.8628 c -4.39384,1.68275 -7.52563,5.88963 -7.52563,10.89113 a 11.685764,11.685764 0 0 0 11.68578,11.68579 c 1.68275,0 3.22527,-0.37401 4.6743,-0.98157 v 33.70187 a 4.6743055,4.6743055 0 0 1 -4.6743,4.67429 4.6743055,4.6743055 0 0 1 -4.67431,-4.67429 v -21.03448 a 9.3486112,9.3486112 0 0 0 -9.34861,-9.34863 h -4.67426 v -32.72018 a 9.3486112,9.3486112 0 0 0 -9.34859,-9.34862 H 77.258334 a 9.3486112,9.3486112 0 0 0 -9.348618,9.34862 v 74.78901 h 46.743044 v -35.0574 h 7.01147 v 23.37164 a 11.685764,11.685764 0 0 0 11.68578,11.68576 11.685764,11.685764 0 0 0 11.68576,-11.68576 v -44.40603 c 0,-3.22526 -1.30875,-6.17008 -3.41224,-8.27351 m -8.27352,12.94783 a 4.6743055,4.6743055 0 0 1 -4.67431,-4.67432 4.6743055,4.6743055 0 0 1 4.67431,-4.6743 4.6743055,4.6743055 0 0 1 4.67429,4.6743 4.6743055,4.6743055 0 0 1 -4.67429,4.67432 m -46.743078,37.39456 v -21.03449 h -9.348598 l 18.697223,-35.05731 v 23.37155 h 9.348613 z"
       inkscape:connector-curvature="0"
       style="stroke-width:4.67430496" />
  </g>
  </svg>

</template>

<script>
export default {
  name: 'MonitorEv',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width: 1.33333337;
}
</style>
