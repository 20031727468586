<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="Group_135" data-name="Group 135" transform="translate(2001 -2497)">
    <g id="credit-card" transform="translate(-2003 2494)">
      <path id="Path_1793" data-name="Path 1793" d="M6,13H8.4a.8.8,0,1,0,0-1.6H6A.8.8,0,1,0,6,13Zm9.6-8H4.4A2.4,2.4,0,0,0,2,7.4v7.2A2.4,2.4,0,0,0,4.4,17H15.6A2.4,2.4,0,0,0,18,14.6V7.4A2.4,2.4,0,0,0,15.6,5Zm.8,9.6a.8.8,0,0,1-.8.8H4.4a.8.8,0,0,1-.8-.8V9.8H16.4Zm0-6.4H3.6V7.4a.8.8,0,0,1,.8-.8H15.6a.8.8,0,0,1,.8.8Z" fill="#333"/>
    </g>
    <rect id="Rectangle_605" data-name="Rectangle 605" width="16" height="16" transform="translate(-2001 2497)" fill="none"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'ChargeCard',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
