<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="900" height="600" viewBox="0 0 9 6">
      <rect width="9" height="6" fill="#ED2939"/>
      <rect width="6" height="6" fill="#fff"/>
      <rect width="3" height="6" fill="#002395"/>
  </svg>
</template>

<script>
export default {
  name: 'frenchFlag'
}
</script>
