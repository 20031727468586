<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_132" data-name="Group 132" transform="translate(-15 -10)">
        <rect id="Rectangle_602" data-name="Rectangle 602" width="16" height="16" transform="translate(15 10)" fill="none"/>
        <g id="users-alt" transform="translate(14 8.5)">
        <path id="Path_1792" data-name="Path 1792" d="M9.229,9.85a3.583,3.583,0,0,0,1.238-2.709,3.641,3.641,0,1,0-7.283,0A3.583,3.583,0,0,0,4.423,9.85,5.826,5.826,0,0,0,1,15.152a.728.728,0,1,0,1.457,0,4.37,4.37,0,1,1,8.739,0,.728.728,0,0,0,1.457,0,5.826,5.826,0,0,0-3.423-5.3Zm-2.4-.524A2.185,2.185,0,1,1,9.011,7.141,2.185,2.185,0,0,1,6.826,9.326Zm7.093.233A3.641,3.641,0,0,0,11.2,3.5a.728.728,0,0,0,0,1.457,2.181,2.181,0,0,1,1.092,4.071.727.727,0,0,0-.036,1.238l.284.189.095.051a5.1,5.1,0,0,1,2.913,4.646.728.728,0,0,0,1.457,0A6.554,6.554,0,0,0,13.919,9.559Z" transform="translate(0 0)" fill="#279dd9"/>
        </g>
    </g>
    </svg>
</template>

<script>
export default {
  name: 'ChargeCard',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337;
}
</style>
