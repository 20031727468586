import Vue from 'vue'

export default (store: any) => {
  return Vue.mixin({
    methods: {
      notification ({ content = '', color = '', timeout = 6000, position = 'top-right', multiline = false }) {
        store.commit('notification/showMessage', { content, color, timeout, position, multiline })
      }
    }
  })
}
