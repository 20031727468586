






































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class AutocompleteChargePointList extends Vue {
        icons= [
        'mdi-home',
        'mdi-email',
        'mdi-calendar',
        'mdi-delete',
      ]

      items= [
        'default',
        'absolute',
        'fixed',
      ]

      padless= false
      variant= 'default'
    cookiesDialog= false
    showMore= false
    isCookies = false
    cookiesObj= {
      necessary: true,
      analytical: false,
      functional: false,
      performance: false,
      other: false,
      ads: false,
      nonNecessary: false
    }

    mounted () {
      this.getCookiesDTA()
    }

    get localAttrs () {
        const attrs: any = {}
        attrs.fixed = true
        return attrs.fixed
      }

    saveCookies (isAll = false) {
      this.cookiesDialog = false
      this.isCookies = false
      this.$cookies.set('setup', false)
      this.$cookies.set('viewed_cookie_policy', 'yes', '', '', 'bluecorner.be')
      if (isAll) {
        this.cookiesObj= {
            necessary: true,
            analytical: true,
            functional: true,
            performance: true,
            other: true,
            ads: true,
            nonNecessary: true
        }
        this.$cookies.set('cookielawinfo-checkbox-others', 'yes', '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-necessary', 'yes', '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-performance', 'yes', '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-analytics', 'yes', '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-functional', 'yes', '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-advertisement', 'yes', '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-non-necessary', 'yes', '', '', 'bluecorner.be')
      } else {
        this.$cookies.set('cookielawinfo-checkbox-others', (this.cookiesObj.other ? 'yes' : 'no'), '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-performance', (this.cookiesObj.performance ? 'yes' : 'no'), '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-necessary', (this.cookiesObj.necessary ? 'yes' : 'no'), '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-analytics', (this.cookiesObj.analytical ? 'yes' : 'no'), '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-functional', (this.cookiesObj.functional ? 'yes' : 'no'), '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-advertisement', (this.cookiesObj.ads ? 'yes' : 'no'), '', '', 'bluecorner.be')
        this.$cookies.set('cookielawinfo-checkbox-non-necessary', (this.cookiesObj.nonNecessary ? 'yes' : 'no'), '', '', 'bluecorner.be')
      }
    }

    getCookiesDTA () {
      this.isCookies = !this.$cookies.isKey('viewed_cookie_policy')
      this.cookiesObj.other = this.$cookies.get('cookielawinfo-checkbox-others') === 'yes'
      this.cookiesObj.performance = this.$cookies.get('cookielawinfo-checkbox-performance') === 'yes'
      this.cookiesObj.necessary = this.$cookies.get('cookielawinfo-checkbox-necessary') === 'yes'
      this.cookiesObj.analytical = this.$cookies.get('cookielawinfo-checkbox-analytics') === 'yes'
      this.cookiesObj.functional = this.$cookies.get('cookielawinfo-checkbox-functional') === 'yes'
      this.cookiesObj.ads = this.$cookies.get('cookielawinfo-checkbox-advertisement') === 'yes'
      this.cookiesObj.nonNecessary = this.$cookies.get('cookielawinfo-checkbox-non-necessary') === 'yes'
    }
}
