









import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import FilterContentRow from './FilterContentRow.vue'
import { findIndex, remove } from 'lodash'
import FilterOptions from '../../../typings/FilterOptions'

@Component({
  components: {
    FilterContentRow
  }
})
export default class FilterContent extends Vue {
  @Prop(Array) readonly filterOptions!: Array<FilterOptions>

  filters: Array<any> = []

  // headers:Array<string> = ['ChargeBoxIdentifier', 'ChargePointSerialNumber', 'Description']
  operators: Array<string> = ['Starts with...', 'Ends with...', 'Contains...']
  id = 0

  get headers () {
    const tmp: Array<string> = []
    this.filterOptions.forEach(e => {
      tmp.push(e.fieldName)
    })
    return tmp
  }

  deleteFilter (id: number) {
    // let index = findIndex(this.filters, (o) => { return o.id === id})
    const test = remove(this.filters, (o) => {
      if (o.id === id) {
        return true
      } else {
        return false
      }
    })
    this.$forceUpdate()
    this.doUpdatedFilters()
    // this.filters.splice(index, 1)
  }

  @Watch('filters', { deep: true })
  wFilters (v: any) {
    this.doUpdatedFilters()
  }

  @Emit('updated:filters')
  doUpdatedFilters () {
    const tmp: Array<any> = []
    this.filters.forEach(e => {
      if (e.value && e.field) {
        let tm = ''
        if (e.operator === 'EX') {
          tm = 'is'
        } else if (e.operator === 'CO') {
          tm = 'contains'
        } else if (e.operator === 'EW') {
          tm = 'ends with'
        } else if (e.operator === 'SW') {
          tm = 'starts with'
        }
        const obj: any = Object.assign({ description: `${e.field} ${tm} ${e.value}` }, e)
        // console.log(obj)
        tmp.push(obj)
      }
    })
    return tmp
  }

  updateFilter (event: any) {
    const index = findIndex(this.filters, (o) => {
      return o.id === event.id
    })
    this.filters[index] = event
    this.$forceUpdate()
    this.doUpdatedFilters()
  }
}
