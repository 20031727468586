import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '../plugins/oidc.config'
import Chargepoints from './modules/Chargepoints'
import sideMenu from './modules/sideMenu'
import notification from './modules/notification'
import User from './modules/User'
import RequestsState from './modules/RequestsState'
import ChargerInstallationState from './modules/ChargerInstallationState'
import GenericRelationFilter from '@/store/modules/GenericRelationFilter'

Vue.use(Vuex)

const events = {}

export default new Vuex.Store({
  modules: {
    // @ts-ignore
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      { namespaced: true, dispatchEventsOnWindow: true },
      events
    ),
    chargepoints: Chargepoints,
    sideMenu: sideMenu,
    notification,
    user: User,
    requestsState: RequestsState,
    chargerInstallationState: ChargerInstallationState,
    genericRelationFilter: GenericRelationFilter
  }
})
