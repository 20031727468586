<template>
<svg id="Outline" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m344 16h-240a40.045 40.045 0 0 0 -40 40v400a40.045 40.045 0 0 0 40 40h304a40.045 40.045 0 0 0 40-40v-336a8 8 0 0 0 -2.343-5.657l-96-96a8 8 0 0 0 -5.657-2.343zm88 440a24.028 24.028 0 0 1 -24 24h-304a24.028 24.028 0 0 1 -24-24v-400a24.028 24.028 0 0 1 24-24h232v56a40.045 40.045 0 0 0 40 40h56zm-80-412.687 68.687 68.687h-44.687a24.028 24.028 0 0 1 -24-24z"/><path d="m192 232a24 24 0 1 0 24 24 24.028 24.028 0 0 0 -24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1 -8 8z"/><path d="m256 232a24 24 0 1 0 24 24 24.028 24.028 0 0 0 -24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1 -8 8z"/><path d="m320 232a24 24 0 1 0 24 24 24.028 24.028 0 0 0 -24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1 -8 8z"/></svg>
</template>

<script>
export default {
  name: 'CardQuestionMark',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
