import { Store } from 'vuex'
import { Route } from 'vue-router'

interface IsAllowedToUseGenericRelationFilter {
  // eslint-disable-next-line
  store: Store<any>
  route: Route
  path: string
  flag: string
}

/**
 * This function checks if the currently logged in user is allowed to have the generic relation filter displayed in the current route.
 */
export const isAllowedToUseGenericRelationFilter = ({
  store,
  route,
  path,
  flag
}: IsAllowedToUseGenericRelationFilter) => {
  const isChargePassRoute = route.path === path
  const role = store?.state?.user?.usrInfo?.data?.role.indexOf('EntityManager')
  const isAllowedToSeeFilter = role !== '' &&  role > -1
  const isPartOfACompany =
    store?.state?.user?.usrInfo?.data?.Relation?.IsCompany > 0

  return (
    store?.state?.flagsmith?.featureFlags[flag] &&
    isChargePassRoute &&
    isAllowedToSeeFilter &&
    isPartOfACompany
  )
}
