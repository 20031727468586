import UserApi from '@/services/UserApi'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class User extends VuexModule {
  usrInfo= {}

  @Mutation
  setUserInfo (payload: any) {
    this.usrInfo = payload
  }

  get userInfo () {
    return this.usrInfo
  }

  @Action({ commit: 'setUserInfo' })
  async fetchUserInfo () {
    try {
      const dt = await new UserApi().userInfo() as any
      if (dt.data) {
        if (dt.data.result.status === 'success') {
          return { data: dt.data.data, verified: true }
        } else if (dt.data.result.status === 'error') {
          if (dt.data.result.errorcode === 'SETUP') {
            return { data: {}, verified: false }
          } else {
            return { error: true }
          }
        }
      }
      return dt
    } catch (error) {
      return error
    }
  }
}
