// import colors from 'vuetify/es5/util/colors'

export default {
  OFFLINE: '#D3D3D3',
  default: '#9D9D9D',
  primary: '#65a844',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  ERROR: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  background: '#FFF',
  // charging statusses
  charging: '#1976D2',
  OCCUPIED: '#1976D2',
  AVAILABLE: '#4CAF50',
  UNAVAILABLE: '#FF5252', // connector state
  RESERVED: '#696969',
  parking: '#90CAF9',
  UNKNOWN: '#D3D3D3',
  ONLINE: '#4CAF50',
  // CDR STATUS
  CHARGING: '#1976D2',
  PARKING: '#90CAF9',
  FINISHED: '#4CAF50',
  COMPLETED: '#2196F3',
  OFFLINE_CHARGING: '#1976D2',
  OFFLINE_PARKING: '#90CAF9',
  OFFLINE_AVAILABLE: '#4CAF50',
  OFFLINE_ERROR: '#FF5252',
  OFFLINE_OCCUPIED: '#1972D2'
}
