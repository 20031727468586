import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/baseComponents'
import vuetify from './plugins/vuetify'
import getQueryStringValue from './functions/getQueryStringValue'
import notification from './plugins/notification'
// @ts-ignore
import * as GmapVue from 'gmap-vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueCookies from 'vue-cookies'
import { addAnalytics } from '@/functions/analytics'
// @ts-ignore because VueFlagSmith has no type definitions
import VueFlagsmith from 'flagsmith-vue-client'

Vue.use(VueCookies)
Vue.$cookies.config('7d', '', 'bluecorner.be')

Vue.config.productionTip = false

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: 'places'
  },
  installComponents: true
})

if (process.env.NODE_ENV === 'development') {
  require('./mocks/browser.js')
}

Vue.use(VueLodash, { name: 'custom', lodash: lodash })

// mixin that provieds some globaly used parameters
Vue.mixin({
  data() {
    return {
      $nodeEnv: process.env.NODE_ENV,
      $mapsApiKey: process.env.VUE_APP_MAPS_API_KEY
    }
  }
})

// Vue mixin that provides easier access to the translation function
Vue.mixin({
  methods: {
    t(key: string, values: any): string {
      if (Number(getQueryStringValue('showtranslations')) === 1) {
        return key
      } else {
        if (key.startsWith('$vuetify')) {
          // @ts-ignore
          return this.$vuetify.lang.t(key, values)
        } else {
          // @ts-ignore
          return this.$vuetify.lang.t(`$vuetify.${key}`, values)
        }
      }
    },
    showId() {
      if (Number(getQueryStringValue('debug')) === 1) {
        return true
      }
      return false
    }
  }
})

Vue.use(VueFlagsmith, {
  // Required, environment id
  environmentId: process.env.VUE_APP_FLAGSMITH_API_KEY,

  // Optional, Flagsmith API host (defaults to https://api.flagsmith.com)
  host: process.env.VUE_APP_ENABLE_MSW
    ? 'https://localhost:8080/feature-flags'
    : 'https://api.flagsmith.com',

  // Required
  store
})

notification(store)

new Vue({
  router,
  store,
  vuetify,
  render: (h: any) => h(App)
}).$mount('#app')

addAnalytics()
