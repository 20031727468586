import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { RelationFilterInterface } from '@/typings/RelationFilterInterface'

@Module({ namespaced: true })
export default class GenericRelationFilter extends VuexModule {
  selectedRelation: RelationFilterInterface | null = null

  get currentRelation() {
    return this.selectedRelation
  }

  @Mutation
  setSelectedRelation(val: RelationFilterInterface) {
    this.selectedRelation = val
  }
}
