import BaseApi from '@/services/BaseApi'
import ChargePointSendMessageInterface from '../typings/ChargePointSendMessageInterface'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'

export interface IRemoteStop {
  sessionid: string | number
  tokenuid: string | number
}

interface IReset {
  chargepointid: string | number
  mode: 'HARD' | 'SOFT'
  // placementid: string | number
}

interface RemoteStartProps {
  chargepointid?: string
  tokenid: string
  placementid?: string | number
  connectorid?: string | number
}

export default class ChargePointApi extends BaseApi {
  constructor(type = 0) {
    super('ChargePoint', type)
  }

  // async search (
  //   query: string,
  //   pagination: PaginationInterface = { pagenumber: 1, pagesize: 10 }
  // ) {}

  async details(id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/${id}`
    }
    return await this.getApi(options)
  }

  async getSettings(id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/${id}/Settings`
    }
    return await this.getApi(options)
  }

  async minimumDetails(id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/Minimal/${id}`
    }
    return await this.getApi(options)
  }

  async myChargers(id = '', relationContactId?: string, relationId?: string) {
    let filtered = ''
    if (id.length > 0) {
      filtered = `?directonly=true&relid=${id} `
    }

    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/list${filtered}`,
      params: {
        relationcontactid: relationContactId,
        relid: relationId
      }
    }
    return await this.getApi(options)
  }

  async getCharger(sn = '') {
    let filtered = ''
    if (sn.length > 0) {
      filtered = `?filter={'chargepointserialnumber'=${sn}}`
    }
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/list${filtered}`
    }
    return await this.getApi(options)
  }

  async sendMessage(options: ChargePointSendMessageInterface) {
    return new Promise(async (resolve, reject) => {
      const opts: any = {
        url: 'v2/chargepoint/BCO/SendMessage',
        data: {
          ...options
        }
      }
      try {
        const dt = (await this.postApi(opts)) as any
        resolve(dt.data.Data)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  async getIndicators(payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/Stats`,
      data: {
        start: payload.start,
        end: payload.end
      }
    }
    return await this.postApi(options)
  }

  async checkMessageData(messageId: string | number) {
    return new Promise(async (resolve, reject) => {
      try {
        const dt = (await this.getApi(
          `v2/chargepoint/BCO/MessageById/${messageId}`
        )) as any
        resolve(dt.data.Data)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    }).then(
      (data: any) => {
        // console.log(data)
      },
      (err: any) => {
        console.log(err)
      }
    )
  }

  async verifyChargePoint(serialnumber: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('verify chargepoint')
        const dt = (await this.getApi(
          `v3/chargepoint/BCO/Verify/Serial/${serialnumber}`
        )) as any
        resolve(dt.data)
      } catch (err) {
        // console.error(err)
        reject(err)
      }
    })
  }

  async contracts(chargepointId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const dt = (await this.getApi(
          `v3.1/chargeplacement/BCO/${chargepointId}/contractdetails`
        )) as DefaultResponseInterface
        resolve(dt.data)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }
  // <Route Url="/:Uci/Remote/Start" Method="POST" Call="RemoteStart" />
  // <Route Url="/:Uci/Remote/Stop" Method="POST" Call="RemoteStop" />
  // <Route Url="/:Uci/Remote/Unlock" Method="POST" Call="RemoteUnlock" />
  // <Route Url="/:Uci/Remote/Reset" Method="POST" Call="RemoteReset" />

  async remoteStart(data: RemoteStartProps) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts: any = {
          url: 'v3/chargepoint/BCO/Remote/Start'
        }
        if (data.chargepointid) {
          opts.data = {
            chargepointid: data.chargepointid,
            tokenid: data.tokenid
          }
        } else {
          if (data.placementid) {
            opts.data = {
              placementid: data.placementid,
              tokenid: data.tokenid
            }
          } else {
            opts.data = {
              connectorid: data.connectorid,
              tokenid: data.tokenid
            }
          }
        }
        const dt = await this.postApi(opts)
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async reset(data: IReset) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts = {
          url: 'v3/chargepoint/BCO/Remote/Reset',
          data
        }
        const dt = await this.postApi(opts)
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async guestCharging(payload: any, chargepointid: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts = {
          url: `v3/chargeplacement/BCO/${chargepointid}/update/guestcharging`,
          data: payload
        }
        const dt = await this.postApi(opts)
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async reimbWL(payload: any, chargepointid: any) {
    // console.log('CALL GUEST')
    return new Promise(async (resolve, reject) => {
      try {
        const opts = {
          url: `v3/chargeplacement/BCO/${chargepointid}/update/reimbwhitelist`,
          data: payload
        }
        const dt = await this.postApi(opts)
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async plugCharging(payload: any, chargepointid: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts = {
          url: `v3/chargeplacement/BCO/${chargepointid}/update/isplugandcharge`,
          data: payload
        }
        const dt = await this.postApi(opts)
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async remoteStop(data: IRemoteStop) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts = {
          url: 'v3/chargepoint/BCO/Remote/Stop',
          data
        }
        const dt = (await this.postApi(opts)) as any
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  async updateChargepoint(payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargepoint/BCO/Update`,
      data: payload,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async unlock(placementid: string | number) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts = {
          url: 'v3/chargepoint/BCO/Remote/Unlock',
          data: { placementid }
        }
        const dt = (await this.postApi(opts)) as any
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }
}

export { IReset, RemoteStartProps }
