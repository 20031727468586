<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    viewBox="0 0 24 24"
    :role="role"
    style="fill: #65a844!important"
  >

    <path
      d="M 13.5,18 H 4 v -6 h 10.3 c 1,-1.2 2.5,-2 4.2,-2 1.3,0 2.5,0.5 3.5,1.3 V 6 C 22,4.8954305 21.104569,4 20,4 H 4 C 2.8954305,4 2,4.8954305 2,6 v 12 c 0,1.104569 0.8954305,2 2,2 H 14.6 C 14.2,19.4 13.8,18.7 13.5,18 M 4,6 H 20 V 8 H 4 V 6"
  />
    <path
      d="m 20.909346,13.598978 q 0,0.548605 -0.195931,0.979652 -0.19593,0.425449 -0.515017,0.755732 -0.313489,0.319087 -0.722144,0.598987 -0.408655,0.279901 -0.867692,0.543008 v 1.259553 h -1.002044 v -1.707395 q 0.363871,-0.207126 0.783722,-0.453439 0.425449,-0.246312 0.694153,-0.498223 0.324685,-0.291096 0.503822,-0.598987 0.179136,-0.313489 0.179136,-0.794918 0,-0.632576 -0.431047,-0.940466 -0.425449,-0.313489 -1.102809,-0.313489 -0.604585,0 -1.147592,0.190332 -0.53741,0.190333 -0.850898,0.386263 h -0.05598 v -1.141994 q 0.391861,-0.151147 0.990849,-0.268705 0.604585,-0.123156 1.141995,-0.123156 1.203572,0 1.897726,0.587791 0.699752,0.582193 0.699751,1.539454 z m -2.211216,6.376137 h -1.141994 v -1.181181 h 1.141994 z" />
  </svg>

</template>

<script>
export default {
  name: 'CardQuestionMark',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
