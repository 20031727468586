import Vue from 'vue'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'

import store from '../store'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import user from './_user'

Vue.use(VueRouter)

let routes: Array<RouteConfig> = []
let router: any

if(process.env.VUE_APP_MODE === 'MAINTENANCE') {
  routes = [
    {
      path: '/utility',
      name: 'utility',
      component: () => import('@/views/_enduser/Maintenance.vue')
    },
    {
      path: '/',
      redirect: '/utility'
    }
  ]

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
} else {
  routes = [
    ...user,
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/ui-elements',
      name: 'uiElements',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/theme/Theme.vue')
    },
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: () => import('../views/OidcCallback.vue')
    }
  ]

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))
  router.beforeEach((to:any, from: any, next:any) => {
    if (to.path === '/my/presets') {
      store.commit('sideMenu/setNavBar', '')
    } else if (to.path.indexOf('/my/') !== -1) {
      store.commit('sideMenu/setNavBar', 'user')
    } else if (to.path === '/chargelocationsmap') {
      store.commit('sideMenu/setNavBar', '')
    } else if (to.path === '/dashboard') {
      store.commit('sideMenu/setNavBar', '')
    } else {
      store.commit('sideMenu/setNavBar', 'admin')
    }
    next()
  })
}

export default router
