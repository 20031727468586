enum Environments {
  Production = 'production',
  EvaIntern = 'evaintern',
  MyTest = 'mytest'
}

const hasGivenConsent = (): boolean => {
  return document.cookie.indexOf('cookielawinfo-checkbox-analytics=yes') > -1
}

// This code has been copy pasted from Analytics official docs.
const runAnalytics = () => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  function gtag() {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments)
  }
  // @ts-ignore
  gtag('js', new Date())

  // @ts-ignore
  gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS)
}

const createAnalyticsTag = (analyticsProperty?: string) => {
  if (!analyticsProperty || !hasGivenConsent()) {
    return ''
  }
  const scriptTag = document.createElement('script')
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsProperty}`
  scriptTag.async = true
  document.head.appendChild(scriptTag)
  runAnalytics()
  return scriptTag
}

export const addAnalytics = () => {
  const env = process.env.NODE_ENV
  const analyticsProperty = process.env.VUE_APP_GOOGLE_ANALYTICS
  if (env !== Environments.Production && env !== Environments.EvaIntern) {
    return ''
  }
  return createAnalyticsTag(analyticsProperty)
}
