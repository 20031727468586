












import Vue from 'vue'
import Component from 'vue-class-component'

@Component
/**
 * @group CoreComponents
 * Base button component with styles added specifically for the BlueCorner design
 * this component extends the default Vuetify Button so every VBtn property passed to this component will be applied to this component
 * https://vuetifyjs.com/en/components/buttons/
 */
export default class BcCardSettings extends Vue {
}
