import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import bluecornerTheme from '../themes/bluecorner'
import en from '../locale/ts/en'
import nl from '../locale/ts/nl'
import fr from '../locale/ts/fr'
import TypeTwoCcs from '../assets/svg/TypeTwoCcs.vue'
import CardQuestionMark from '../assets/svg/CardQuestionMark.vue'
import ChargepointCard from '../assets/svg/ChargepointCard.vue'
import Other from '../assets/svg/Other.vue'
import Complaint from '../assets/svg/Complaint.vue'
import Help from '../assets/svg/Help.vue'
import ServiceCenter from '../assets/svg/ServiceCenter.vue'
import Moving from '../assets/svg/Moving.vue'
import ChargeCard from '../assets/svg/ChargeCard.vue'
import MyOrganisation from '../assets/svg/MyOrganisation.vue'
import ChargingHistory from '../assets/svg/ChargingHistory.vue'
import chargePoint from '../assets/svg/Chargepoint.vue'
import ChargeCardAccess from '../assets/svg/ChargeCardAccess.vue'
import Type2 from '../assets/svg/Type2.vue'
import Voltage from '../assets/svg/Voltage.vue'
import Driving from '../assets/svg/Driving.vue'
import UserAllowedAccount from '../assets/svg/UserAllowedAccount.vue'
import UserNotAllowedAccount from '../assets/svg/UserNotAllowedAccount.vue'
import AddCompany from '../assets/svg/AddCompany.vue'
import NewInstallation from '../assets/svg/NewInstallation.vue'
import EnglishFlag from '../assets/svg/EnglishFlag.vue'
import DutchFlag from '../assets/svg/DutchFlag.vue'
import FrenchFlag from '../assets/svg/FrenchFlag.vue'
import MonitorEv from '../assets/svg/MonitorEv.vue'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: {
      en,
      nl,
      fr
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        ...bluecornerTheme
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      voltage: {
        component: Voltage,
        props: {
          role: 'icon'
        }
      },
      driving: {
        component: Driving,
        props: {
          role: 'icon'
        }
      },
      addCompany: {
        component: AddCompany,
        props: {
          role: 'icon'
        }
      },
      other: {
        component: Other,
        props: {
          role: 'icon'
        }
      },
      newInstallation: {
        component: NewInstallation,
        props: {
          role: 'icon'
        }
      },
      userNotAllowedAccount: {
        component: UserNotAllowedAccount,
        props: {
          role: 'icon'
        }
      },
      userAllowedAccount: {
        component: UserAllowedAccount,
        props: {
          role: 'icon'
        }
      },
      type2ccs: {
        component: TypeTwoCcs,
        props: {
          role: 'icon'
        }
      },
      type2: {
        component: Type2,
        props: {
          role: 'icon'
        }
      },
      cardQuestionMark: {
        component: CardQuestionMark,
        props: {
          role: 'icon'
        }
      },
      Card: {
        component: ChargepointCard,
        props: {
          role: 'icon'
        }
      },
      moving: {
        component: Moving,
        props: {
          role: 'icon'
        }
      },
      complaint: {
        component: Complaint,
        props: {
          role: 'icon'
        }
      },
      help: {
        component: Help,
        props: {
          role: 'icon'
        }
      },
      serviceCenter: {
        component: ServiceCenter,
        props: {
          role: 'icon'
        }
      },
      chargeCard: {
        component: ChargeCard,
        props: {
          role: 'icon'
        }
      },
      myOrganisation: {
        component: MyOrganisation,
        props: {
          role: 'icon'
        }
      },
      chargingHistory: {
        component: ChargingHistory,
        props: {
          role: 'icon'
        }
      },
      chargePoint: {
        component: chargePoint,
        props: {
          role: 'icon'
        }
      },
      ChargeCardAccess: {
        component: ChargeCardAccess,
        props: {
          role: 'icon'
        }
      },
      frenchFlag: {
        component: FrenchFlag
      },
      englishFlag: {
        component: EnglishFlag
      },
      dutchFlag: {
        component: DutchFlag
      },
      monitorEv: {
        component: MonitorEv
      }
    }
  }
})
