<template>
  <v-app v-if="!chargePassFlag">
    <v-app-bar elevate-on-scroll fixed app clipped-right height="68px" :color="getColor">
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 pa-5"
        contain
        min-width="100"
        :src="require('@/assets/blink-logo-wht.png')"
        width="140"
      />
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        style="justify-content: center; margin-right: 5px"
        @click="toggleHamburger"
        color="white"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <MobileNav v-if="!this.$vuetify.breakpoint.mdAndUp" />
    <!-- Left menu: Hidden on mobile -->
    <FixedNav v-if="this.$vuetify.breakpoint.mdAndUp" />
    <!-- End left menu -->
    <v-app-bar class="hidden-sm-and-down menuBC" app height="68px" clippedLeft :color="getColor">
      <div class="d-flex align-center">
        <v-img
          alt="logo"
          class="shrink mt-1 hidden-sm-and-down ml-5"
          contain
          min-width="100"
          :src="require('@/assets/blink-logo-wht.png')"
          width="140"
        />
        <span v-if="getColor != '#000000'" class="white--text ml-5">
          DEVELOPMENT ENVIRONMENT
        </span>
      </div>
      <app-bar-tabs/>
      <generic-relation-filter />
      <v-spacer></v-spacer>
      <account-menu />
    </v-app-bar>
    <v-main>
      <BannerNotification/>
      <router-view />
    </v-main>
    <CookiesGPDR />
    <Intake />
    <BcSnackbar />
  </v-app>
  <v-app v-else style="background: #f2f5f8">
    <v-main class="ml-5 mr-5 mt-5">
      <maintenance />
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import AppBarTabs from './components/_core/appbar/AppBarTabs'
import FixedNav from './components/_core/nav/FixedNav.vue'
import MobileNav from './components/_core/nav/MobileNav.vue'
import AccountMenu from './components/_core/AccountMenu'
import BcSnackbar from './components/_base/BcNotification/BcNotification.vue'
import Intake from './views/intake/Intake.vue'
import BannerNotification from './components/common/BannerNotification.vue'
import CookiesGPDR from './components/common/CookiesGPDR.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import GenericRelationFilter from './components/common/GenericRelationFilter.vue'
import Maintenance from './views/_enduser/Maintenance.vue'
export default Vue.extend({
  name: 'App',
  components: {
    AppBarTabs,
    FixedNav,
    MobileNav,
    AccountMenu,
    BcSnackbar,
    Intake,
    BannerNotification,
    CookiesGPDR,
    GenericRelationFilter,
    Maintenance
  },
  mounted() {
    this.setLocale(localStorage.getItem('language'))
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' }),
    hamburger() {
      return this.$store.getters['sideMenu/userMenu']
    },
    getColor() {
      if (process.env.VUE_APP_BAR_BG) {
        return process.env.VUE_APP_BAR_BG
      } else {
        return '#000000'
      }
    },
    menuVisible() {
      return this.$store.getters['sideMenu/visible']
    },
    activeLanguage: {
      get() {
        const currentLang = _.find(this.availableLocales, (o) => {
          if (o.code === this.$vuetify.lang.current) {
            return true
          }
          return false
        })
        if (currentLang) {
          return currentLang
        } else {
          return { name: 'English', code: 'en', flag: 'gb' }
        }
      }
    },
    chargePassFlag() {
      return this.$store?.state?.flagsmith?.featureFlags.maintenance
    }
  },
  data: () => ({
    showWelcome: true,
    group: null,
    browser: null,
    drawer: false,
    // TODO CHANGE IT (refac)
    showArrow: {
      type: Boolean,
      default: false
    },
    // Show the country label
    showLabel: {
      type: Boolean,
      default: true
    },
    menu: false,
    modeState: process.env.VUE_APP_MODE,
    currentLang: '',
    availableLocales: [
      { name: 'English', code: 'en', flag: 'gb' },
      { name: 'Français', code: 'fr', flag: 'fr' },
      { name: 'Nederlands', code: 'nl', flag: 'nl' }
    ],
    state: {
      outdatedBrowser: false
    }
    // build trigger test
  }),
  watch: {
    group() {
      // @ts-ignore
      this.drawer = false
    }
  },
  methods: {
    setLocale(locale) {
      this.$vuetify.lang.current = locale
      localStorage.setItem('language', locale)
    },
    toggleHamburger() {
      this.$store.commit('sideMenu/toggleVisibleMobile')
    }
  }
})
</script>
<style>
.v-application--wrap {
  top: 0px;
  left: 0px;
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
}
</style>
