// @ts-ignore
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { VBtn, VIcon, VAutocomplete } from 'vuetify/lib'
import countrylist from '@/locale/countrylist'

@Component
export default class BcCountrySelect extends Vue {
  @PropSync('value', { type: String }) valueSynced!: string
  @Prop(Boolean) readonly disabled!: boolean
  @Prop(Array) readonly rules!: []

  get countries () {
    const currentLang = this.$vuetify.lang.current
    // @ts-ignore
    if (countrylist[currentLang]) {
      // @ts-ignore
      return this.formatCountries(countrylist[currentLang])
    } else {
      return this.formatCountries(countrylist.en)
    }
  }

  formatCountries (countries: any) {
    const arr: Array<any> = []
    const keys: Array<string> = Object.keys(countries)
    keys.forEach(e => {
      arr.push({ value: e, text: countries[e] })
    })
    return arr
  }

  render (h: any) {
    return this.$createElement(VAutocomplete, {
      props: {
        items: this.countries,
        value: this.valueSynced,
        disabled: this.disabled,
        outlined: true,
        'autocomplete': 'hide_string',
        dense: true,
        rules:this.rules,
        'hide-details': 'auto',
        label: this.t('$vuetify.myOrganisation.country')
      },
      on: {
        ...this.$listeners
      }
    })
  }
}
