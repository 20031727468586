export default {
  errorTXT: {
    INVADDRESS: 'The address needs to be complete !',
    RelNameTooLong: "The name field can't be longer than 100 characters.",
    FAILEDTOFIND: '404 Not found',
    IBANINVALID: "The iban field doesn't contain a valid IBAN number.",
    USERNOTFOUND: 'User not found',
    EmailTooLong: "The email field can't be longer than 255 characters.",
    CANTCOMBINE: "Can't combine multiple guest charging modes.",
    Error: 'An error has occures, please contact support.',
    '': 'An error has occures, please contact support.',
    FAILEDTOLOAD:
      'This could be a CORS issue or a dropped internet connection.',
    Error149: "Property doesn't exist",
    'User already exist': 'User already exist',
    'Card added': 'Card added',
    Deactivated: 'Deactivated card.',
    ALUSEDCARD: 'You already choosed this card number',
    ATTACHEDTOUSER:
      'Charge card has already been attached to a user under your account.',
    TOKACTIVERR:
      "The card is already in use by another user, this card can't be added",
    UnknownToken: 'Charge card not found.', //
    UNKNOWNREQUEST:
      "We can't find this token request id. Please refresh the page.",
    UNKNOWNERROR:
      'This service is unavailable at the moment. Please retry later or contact support.',
    Unauthorized: 'You do not have access to this resource.',
    UNAUTHORIZED: 'You do not have access to this resource.',
    InvalidToken: "User isn't signed in.",
    UnknownChargePoint: "Chargepoint couldn't be found.",
    NOCONTACT: "Charge card doesn't have a user. Please Contact Support.",
    NOACTIVESESSION: 'No active session was found.',
    PLACEMENTNOTFOUND: 'Chargepoint connector not found.',
    CHARGEPOINTNOTFOUND: 'Chargepoint not found.',
    SETUP: "Account hasn't been setup yet.",
    SETUPERROR: 'Failed to create account data.',
    USEREXISTS: 'User already exists.',
    INVALIDEMTOREL: 'An administrator cannot be converted to a relation.',
    CONVERTONLYRC: 'Current relation only has this user.',
    RELCONVERTFAILURE: 'Failed to convert to new relation.',
    RCNOTFOUND: 'User not found.',
    NOPARENTRELATION: 'Info not found.',
    NOPORTAL: "User doesn't have portal access.",
    ERROROCCURED: 'Something went wrong!',
    ROLEASSIGNFAILED: "Couldn't assign role to user.",
    ROLEREMOVEFAILED: "Couldn't remove role from user.",
    RELNOTFOUND: "Relation couldn't be found.",
    SEARCHQUERYINVALID: 'Search field cannot be empty.',
    RELCREUPFAIL: 'Failed to create or update relation.',
    TOKGRNOTFOUND: 'Whitelist tokengroup not found.',
    TOKGRINUSE:
      "Unable to remove Whitelist TokengRoup as it's currently in use.",
    RCCREUPFAIL: 'Failed to update or create user.',
    WRONGFILTER: 'There is something wrong with your search filter.',
    STOPCONFAILED: 'Failed to stop the contract.',
    SUBNOTFOUND: "Subscription couldn't be found.",
    SUBTYPENOTFOUND: "Subscription type couldn't be found.",
    RELORRCNOTFOUND: 'No relation or user was selected.',
    SUPERROR: 'Profile error on SUP. Please contact support with this error.',
    NOCPO: 'Failed to find a cpo, Please contact support.',
    UnknownChargeLocation: "Chargelocation couldn't found.",
    PLACCONFLICT: "Couldn't find any placements.",
    CREPLACONFLICT: 'Please contact support with your serial number.',
    TOKGRUPFAIL: "Couldn't update the whitelist token group.",
    INVALIDVAL:
      'You have to select a subscriptiontype to activate reimbursement.',
    REIMBREQRC:
      'You can’t configure reimbursement without selecting a user, please select the user whom should be refunded for his/her charge sessions.',
    WHTLCREFAIL: 'Failed to create new whitelist.',
    EMAILUNAVAILABLE: 'Email is in use on a user.',
    Error192: 'An error has occures, please contact support.',
    NEWRELATION: 'New organisation has been created',
    REIMBURSERNA: 'Your not allowed to set this as reimburser.',
    undefined: 'An error has occures, please contact support.',
    INVCODE: 'No subscription selected',
    UNEXPECTED:
      'An unexpected error has occured, please try again later or contact our servicedesk'
  },
  gdpr: {
    introduction:
      'We use cookies to ensure our website runs as smoothly as possible. This is how we make your browsing experience even easier.',
    config: 'Cookie settings',
    readMore: 'Learn more',
    accept: 'Accept',
    privacePolicy: 'Privacy Policy',
    shortExplain:
      'This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities...',
    fullExplain:
      'This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential  for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.',
    showMore: 'Show more',
    showLess: 'Show less',
    allWays: 'Always Enabled',
    necessary: 'Required',
    necessaryExplain:
      'They are necessary for the proper functioning of the website and cannot be disabled in our systems. They are usually placed only in response to certain actions taken by you, for example a request for a service, such as setting your privacy preferences, logging in or filling out a form. Without these cookies, we cannot provide access to some sections of our website or supply the requested service.',
    functional: 'Functional',
    functionalExplain:
      'They enable the website to offer improved functionality and personalization, such as storing the user’s choices, preferred language or location. They are placed by us or on our behalf by processors. If you don’t allow these cookies, this may hamper the performance of some or all of the services.',
    performance: 'Performance',
    performanceExplain:
      'They are necessary for the proper functioning of the website and cannot be disabled in our systems. They are usually placed only in response to certain actions taken by you, for example a request for a service, such as setting your privacy preferences, logging in or filling out a form. Without these cookies, we cannot provide access to some sections of our website or supply the requested service.',
    analytical: 'Analytical',
    analyticalExplain:
      'They make it possible to count the number of visitors and traffic sources which in turn allows us to measure and improve our website performance. With these cookies, we know which pages are the most and least popular and we can see how visitors navigate through the website. All the data collected by these cookies is aggregated and is therefore anonymous. If you don’t allow these cookies, we won’t know when you have visited our website and won’t be able to measure the performance.',
    advertising: 'Advertising',
    advertisingExplain:
      'They may be placed by us or by our advertising partners.They are used by those companies to build a profile of your interests and display relevant advertisements relating to the services of Blue Corner on other websites. They do not directly store personal data.  Rather, they use the unique identification data of your browser and device. If you disable these cookies, you will see less targeted advertisements on third channels.',
    other: 'Other',
    otherExplain:
      'They may be placed by us or by our advertising partners. They are used by those companies to build a profile of your interests and display relevant advertisements relating to the services of Blue Corner on other websites. They do not directly store personal data. Rather, they use the unique identification data of your browser and device. If you disable these cookies, you will see less targeted advertisements on third channels.',
    nonNecessary: 'Non Necessary',
    nonNecessaryExplain:
      'Any cookies that may not be particularly necessary for the website to function and is used specifically to collect user personal data via analytics, ads, other embedded contents are termed as non-necessary cookies. It is mandatory to procure user consent prior to running these cookies on your website.'
  },
  subscriptions: {
    SUB_COSTLESS: 'Private',
    SUB_STARTER: 'Starter',
    SUB_FAN: 'Fan',
    SUB_ADDICT: 'Addict',
    SUB_KISS: 'Kiss',
    SUB_PARTNER_UPPER_NOUPLIFT: 'Partner subscription type following NO UP LIFT'
  },
  map: {
    Unknown: 'Error',
    zoomLocation: 'Search for location',
    filters: 'filters',
    legend: 'legend',
    occupiedParking: 'occupied/parking',
    occupied: 'occupied/parking',
    Available: 'Available',
    myChargers: 'my chargers',
    otherNetworks: 'other networks',
    kw: 'kw',
    kwh: 'kWh',
    ac: 'ac',
    min: 'min',
    Error: 'Error',
    priceInfo: 'price information',
    priceIVAT: 'prices are inclusive of vat',
    evType: 'ev type',
    kwhFee: 'kwh fee',
    startFee: 'start fee',
    connectionFee: 'connection fee',
    parkingFee: 'parking fee',
    from: 'from',
    to: 'to',
    Occupied: 'Occupied',
    filterChargerType: 'Filter by Charge Type',
    bcNetwork: 'Blink Network',
    myChargersFilter: 'My chargers',
    otherNetworkFilter: 'Other Networks',
    otherFilters: 'other filters',
    showFacilities: 'Show Facilities',
    unkown: 'An error has occures, please contact support.',
    subscription: 'Subscription'
  },
  profile: {
    fleetManager: 'Administrator',
    changePassword: 'Change Password',
    myProfile: 'My profile',
    logout: 'Log Out',
    currentPswd: 'Current password',
    newPswd: 'New password',
    repeatPswd: 'Repeat password',
    successUpdate: 'Account succesfully updated',
    local: 'en-EN',
    locale: 'en'
  },
  dashboard: {
    of: 'of',
    loading: 'Loading Portal: Loading...',
    loadingTokens: 'Loading Portal: Load Tokens...',
    loadingChargers: 'Loading Portal: Load Chargers...',
    loadingCPIndicators: 'Loading Portal: Load Chargepoint Indicators...',
    loadingTokensIndicators: 'Loading Portal: Load Token Indicators...',
    loadingKWHIndicators: 'Loading Portal: Load KWH Relation Indicators...',
    loadingKWHRelationIndicators:
      'Loading Portal: Load KWH Relation Contact Indicators...',
    loadingCoIndicators: 'Loading Portal: Load Co2 Indicator...',
    title: 'Dashboard',
    chargerOverviewTitle: 'Chargers overview',
    chargeCardsOverviewTitle: 'Charge cards overview',
    totalEnergy: 'Total Energy',
    chargers: 'Chargers',
    averageEnergy: 'Average Energy Per Session',
    totalTime: 'Total Time',
    averageTime: 'Average Time Per Session',
    card: 'Cards',
    coSaved: 'Kg CO2 saved',
    allDriversConsumption: 'All users consumption',
    myConsumption: 'My Consumption',
    consumptions: 'Consumptions',
    topMonth: 'Top Month',
    totSessionsChargers: 'Total amount of sessions on chargers',
    totSessionsChargepass: 'Total amount of sessions on charge card'
  },
  myOrganisation: {
    info: 'Info', // NEW
    chargers: 'Chargers', // NEW
    contactInfo: 'Contact info', // NEW
    address: 'Address', // NEW
    extraInfo: 'Extra info', // NEW
    access: 'Access', // NEW
    billingInfo: 'Billing info', // New,
    editInfo: 'Edit info', // New
    title: 'My Organisation',
    fleetManager: 'Administrator',
    newOrganisation: 'Add an organisation',
    newOrganisationFrom: 'New organisation from',
    newDriver: 'New User',
    drivers: 'Users',
    editDriver: 'Edit User ',
    newDriverOrganisation: 'New user for',
    assignFleet: 'Assign as Administrator',
    portalAccess: 'Portal Access',
    assignFleetWarning: '“ATTENTION: THE Administrator CAN .....”',
    createPortal: 'Create portal account',
    createPortalWarning:
      '“ATTENTION: WHEN ADDING THIS USER,THE PORTAL ACCOUNT WILL BE CREATED AND THIS USER WILL RECEIVE A NOTIFICATION EMAIL TO ACTIVATE AND CONFIRM THIS REGISTRATION.”',
    chargepoints: 'Chargepoints',
    allDrivers: 'Show users of suborganisations',
    myDrivers: 'users',
    allChargepoints: 'All Chargepoints',
    addCharger: 'add charger',
    addDriver: 'Add a new user',
    driverTable: {
      level: 'Organisation',
      name: 'Name',
      firstName: 'first name',
      lastName: 'Last name',
      email: 'Email',
      address: 'Address',
      account: 'Account',
      access: 'Access',
      admin: 'Admin'
    },
    chargepointTable: {
      chargePointIdentifie: 'charge point identifie',
      serialNumber: 'serial number',
      model: 'model',
      owner: 'owner',
      connectors: 'connectors'
    },
    editOrganisation: 'Edit organisation',
    editUser: 'Edit User',
    search: 'Search',
    loadingWait: 'Loading... Please wait',
    driverHasAccess: 'User has a portal access',
    driverHasNotAccess: "User doesn't have a portal access",
    country: 'Country'
  },
  guestCharging: {
    guestCharging: 'guest charging',
    reimbursement: 'reimbursement',
    published: 'published',
    configure: 'configure',
    guestChargingConfigure: 'guest charging configuration',
    disabled: 'disabled',
    cpPrivate: 'this charge point is private',
    guestChargeExplanation: 'guests will note be allowed to charge',
    free: 'free',
    publishMap: 'publish on map',
    cpPublic: 'this charge point is public',
    cpPublicExplanation: 'guest will be allowed access free of charge.',
    paid: 'paid',
    disablePlugCharge: 'Please disable Plug & Charge',
    cpPaidExplanation: 'guest will be allowed access for a configurable fee.',
    setFee: 'set the fee',
    select: 'select'
  },
  plugCharge: {
    plugCharge: 'plug & charge',
    reimbursement: 'reimbursement',
    plugChargeConfig: 'plug & charge configuration',
    chargerPlugCharge: 'charger is plug & charge',
    setFee: 'set the fee',
    configure: 'configure',
    select: 'select',
    disableGuestCard: 'Please disable Charge Card Access and Guest Charging'
  },
  tokenWizard: {
    invalidChargeCardNumber: 'Invalid charge card number',
    stepsSerialNumber: 'Serial Number',
    stepsCheck: 'Check',
    stepsOwner: 'Owner',
    stepsChargepoint: 'Chargepoint',
    stepsLocation: 'Location',
    stepsPlugCharge: 'Plug & Charge',
    stepsChargeCardAccess: 'Charge Card Access',
    stepsGuestCharging: 'Guest Charging',
    validateCharger: 'Validate charger',
    chargepointSerialNumber: 'Serial number',
    search: 'Search',
    change: 'Change',
    chargerOverview: 'Charger Overview',
    connectors: 'Connectors',
    homeCharger: 'Home Charger ?',
    location: 'Address',
    locationDescription:
      'Where can we locate the new charger ? The location of this charger will only be published if you chose to.',
    form: {
      label: 'Label',
      description: 'Description',
      street: 'Street',
      housNr: 'House Nr.',
      zip: 'Zip',
      city: 'City',
      country: 'Country',
      enterLocation: 'Enter a location'
    },
    reimbursementConf: 'Reimbursement Configuration',
    plugChargeEnable: 'Laadpunt is plug & charge enabled',
    resimbursement: 'Reimbursement',
    chargeCardAccess: 'charge card access',
    grantAccess: 'grant access to charge cards',
    whitelistGroup:
      'select the charge card group to use, or create a new group',
    chargeCardAccessEnable: 'enable charge card access',
    chargeCardGroup: 'Charge card group',
    newGroup: 'new group',
    duplicateGroup: 'Duplicate Group',
    nexGroupWL: 'New charge card group',
    createGroup: 'Create a new charge card group',
    editCardGroup: 'Edit card group',
    nameGroup: 'Enter the name of the group',
    chargePassNumber: 'charge card number',
    confirmDeleteToken: 'Are your sure to delete token',
    groupName: 'group name',
    confirmDeleteGroup: 'Are your sure to delete Group',
    guestCharging: 'Guest Charging',
    disabled: 'Disabled',
    guestDisableExplanation:
      'This charge point is private Guest will note be allowed to charge.',
    free: 'Free',
    publish: 'publish on map',
    publishExplanation:
      'This charge point is public. Guest will be allowed access free of charge.',
    paid: 'Paid',
    paidExplanation:
      'This charge point is public. Guest will be allowed access for a configurable fee.',
    setFee: 'Set the fee:',
    configureChargeCard: 'Configure charge card access',
    chargepassList: 'Charge card list',
    addChargepass: 'Add charge card',
    modifyCardGroup: 'Modify Charge card group',
    noReimbursement: 'No reimbursement',
    chargeCardReimbursement: 'Charge card based reimbursement',
    modifyExplanation:
      'You can modify the charge cards in the group. These changes will affect all Charge Points that use this group.',
    reimbursementElec: 'Reimburse electricity costs'
  },
  myChargeCards: {
    addToken: 'Add a new card', // NEW
    addCard: 'Add a card', // NEW
    cardNumber: 'Card number', // New,
    insertCardNumber:
      'Please insert the number printed on the card. You can add multiple cards at once.', // NEW
    linked: 'Linked User/Entity', // NEW
    overview: 'Overview', // NEW
    driverCard: 'Driver', // New
    hint: 'Charge card number follows the following structure: BE-BCA-000000-0', // NEW
    duplicateCards:
      'There are multiple cards with the same number in your list', // NEW
    linkDriverOrganisation:
      'Please link every card to a driver or organisation.', // NEW
    linkDriver: 'Please link every card to a driver.', // NEW
    searchDriverOrganisation: 'Search a driver or organisation', // NEW
    searchDriver: 'Search a driver', // NEW
    pickSubscription: 'Please pick the subscription for each card.', // NEW
    overviewContent: 'Great! Are these the cards you want to add ?', // NEW
    title: 'My charge cards',
    defaultSettings: 'Default Settings',
    newChargeCard: 'New charge card',
    vatRule: '* All prices include 21% VAT.',
    chargePass: 'charge card',
    evco: 'evco',
    driver: 'Which user can use this chargecard ?',
    description: 'Description',
    subscription: 'Subscription',
    state: 'state',
    organisation: 'organisation',
    summary: 'summary',
    customer: 'Customer',
    activateChargeCard: 'activate a charge card',
    chargeCardNumber: 'Charge card number',
    subList: 'subscription list',
    enablePublicCharging: 'Public charging',
    relation: 'Which company can use this chargecard ?',
    subType: 'subscription type',
    driverSelect:
      'do you want to specify user information for this charge card ? (Optional)'
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  myTokens: {
    descriptionToken: 'Description',
    invoiceReference: 'Invoice Reference',
    nonBluecorner: 'Non-Blue Corner Pass',
    custom: 'Custom Tariff',
    editDescription: 'Edit description',
    editInvoice: 'Edit Invoice Reference',
    contractTerms: 'Contract terms',
    monthlyFee: 'Monthly Fee',
    price: 'Cost',
    priceExclVAT: 'Cost (excl VAT)',
    reimbursementExclVAT: 'Cost (excl VAT)',
    number: 'Number',
    evcoId: 'EVCO ID',
    evType: 'EV TYPE',
    kwhFee: 'kwh fee',
    startFee: 'startfee',
    roamingFee: 'Roaming Fee',
    parkingFee: 'Parking Fee',
    rotationFee: 'Connection Rate (Per Min)',
    daymax: 'daymax',
    publicAC: 'Public - AC',
    publicDC: 'Public - DC',
    publicBike: 'Public - Bike',
    interop: 'Interop',
    private: 'Private',
    from: 'From',
    to: 'To',
    myChargeCard: 'My charge card',
    customer: 'Customer',
    driver: 'User',
    status: 'Status',
    deactivateCard: 'Deactivate card',
    activateCard: 'Activate card',
    lastSessions: 'last 10 sessions',
    location: 'location',
    chargepoint: 'chargepoint',
    chargepass: 'chargepass',
    startStopSession: 'start/stop sesson',
    sessionDuration: 'session duration',
    reimbursement: 'refund',
    loadMore: 'load more',
    loading: 'loading...',
    chargeCardNumber: 'Charge card number',
    enablePublicCharging: 'Enable for public charging',
    choose: 'Choose',
    connectionFeeExplanation:
      '* The connection fee is charged when your battery is charged for as long as your car is still connected (8 to 20h). With other providers, the rate of the provider itself is charged, including a standard handling fee of € 0.5 per charging session. All prices include 21% VAT.',
    relation: 'Relation',
    subList: 'Susbscription list',
    subType: 'Subscription type',
    changeSub: 'change subscription',
    changeDriver: 'Change user',
    pastSessions: 'Last 10 sessions',
    chooseDriver: 'Choose a user',
    profile: 'Profile',
    specifyDriver:
      'Do you want to specify user information for this charge card? (optional)',
    annualFee: 'Annual subscription fee',
    normalChargers: 'Normal chargers (AC)',
    fastCharger: 'Fast chargers (DC)',
    connectionRate: 'connection rate (per min)',
    subTypeKwh: 'subscription type (per kwh)',
    startKwh: 'starter (per kwh)',
    fanKwh: 'fan (per kwh)',
    addict: 'addict (per kwh)'
  },
  myChargeSessions: {
    title: 'Charging <br> history',
    mobileTitle: 'Charging history',
    filterToken: 'filter by Charge Card',
    filterChargepoint: 'filter by chargepoint',
    location: 'location',
    chargepoint: 'chargepoint',
    chargePass: 'charge card',
    customerReference: 'Customer Reference',
    startStopSession: 'start/stop session',
    sessionDuration: 'session duration',
    price: 'Cost',
    reimbursement: 'refund',
    sessionsDetail: 'sessions detail',
    totPrice: 'total price',
    exclBtw: 'excl. Btw',
    sessionDate: 'Session Date',
    kwhCharged: 'kWh Charged',
    chargeCard: 'Charge Card',
    filterByToken: 'Filter by Charge Card',
    filterByStatus: 'Filter by status',
    filterByRequest: 'Filter by request',
    filterByChargepoint: 'Filter by Chargepoint',
    session: 'session',
    excel: {
      street: 'STREET',
      housenumber: 'HOUSE NUMBER',
      zip: 'POSTAL CODE',
      city: 'CITY',
      country: 'COUNTRY',
      parkingTime: 'PARKING TIME (minutes)',
      session: 'session',
      consumption: 'CONSUMPTION (kWh)',
      chargingTime: 'CHARGING TIME (minutes)',
      startStopSession: 'start/stop session',
      endDate: 'CHARGE SESSION END',
      startDate: 'CHARGE SESSION START',
      chargePass: 'CHARGE CARD',
      chargepoint: 'CHARGEPOINT',
      customerReference: 'CUSTOMER REFERENCE',
      location: 'LOCATION'
    }
  },
  serviceCenter: {
    chargepassCompany: 'Company',
    chargepassRequester: 'Select who will receive the charge card',
    ticketReporter: "You can only reply on the tickets that you've reported.",
    created: 'Date',
    closedComments: 'Comments are closed, please open a new ticket.',
    title: 'Requests',
    type: 'type',
    createdByMe: 'Created by me',
    createdByAnyone: 'Created by anyone',
    anyStatus: 'Any Status',
    openRequest: 'Open Requests',
    inProgress: 'In Progress',
    closedRequest: 'Closed Requests',
    description: 'Describe your request',
    contactExplication:
      'We will contact you personnaly to discuss the possibilities. In order to facilitate the proces, please describe the situation and desired solution in the field below.',
    reference: 'reference',
    summary: 'summary',
    serviceDesk: 'service desk',
    houseNumber: 'Nr',
    status: 'status',
    requester: 'requester',
    newRequest: 'New request',
    haveQuestion: 'I have a question.',
    newChargerInstal: 'I want to request a new charger installation',
    requestCharger: 'Request a charger',
    totPrice: 'total price',
    typeCharger: 'what kind of charger do you want to order ?',
    smartCable: 'smart cable',
    chargingExplication: 'Select your desired charging speed.',
    fixedCharger: 'Home Charger',
    normalCharger: 'Monofase 230 V - MAX. 3.7kW',
    maxNormalCharger: 'Monofase 230 V',
    fastCharger: 'Driefase 230V/400V - MAX. 22kW',
    maxFastCharger: 'Driefase 230V/400V',
    requiredSub: 'for smart cable you are required to have a subscription',
    subHome: 'subscription @home',
    noSub: 'no subscription',
    invAddress: 'invoice address',
    mrMrsms: 'Mr/Mrs/ms',
    dynamicLoadingTitle: 'Do you want to enable dynamic loading ?',
    dynamicLoadingRadio: 'Dynamic Loading',
    noDynamicLoadingRadio: 'No Dynamic Loading',
    dynamicLoadBalancing: 'Dynamic Load Balancing',
    name: 'name',
    address: 'address',
    zipCode: 'zip code',
    city: 'city',
    deliveryAddressInv:
      'delivery address is different from the invoice address',
    contactDetails: 'contact details',
    requestChargeCard: 'Request a charge card',
    email: 'email',
    phone: 'phone',
    gdprTitle: 'gdpr notice',
    gdprExplain:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis tempore nihil illum dicta? Nisi nostrum delectus optio cupiditate ipsa? Sed labore eaque sequi dolore autem laboriosam placeat quae recusandae expedita!',
    gdprAccept:
      'I accept to my personal data being stored and used to handle request',
    reviewOrder: 'please review your order',
    orderDetails: 'order details',
    chargerType: 'charger type',
    mountingType: 'mounting type',
    sub: 'subscription',
    phaseConnection: 'Do you have a 3-phase connection available ?',
    subReviewOrder: 'Please review your order',
    phaseConnectionExplication:
      'In most instances, the main switch will either be what is referred to as 1-pole wide or 3-poles wide. If your MAIN SWITCH is 1-pole wide, then you have a single phase power supply.<br>  Alternatively, if your MAIN SWITCH is 3-poles wide, then you have a 3-phase power supply.',
    dynamicLoad: 'dynamic loading',
    delAddress: 'Delivery Address',
    extraInfoField:
      'use the field below to provide some extra information/questions',
    requestChargecard: 'Request a charge card',
    orderQuantity: 'Order quantity',
    streetAdr: 'street address (delivery)',
    postalCode: 'postal code (delivery)',
    cityDel: 'city (delivery)',
    extraInfo: 'Extra info.  e.g. PO number',
    otherReq: 'Support request',
    question: 'I have a question',
    questionWhat: 'what is your question about ?',
    questionHow: 'How can we help you ?',
    atCompany: 'at a company site',
    atHome: 'at a home address',
    company: 'company',
    vatNumber: 'Vat Number',
    standard: 'Standard',
    mountingTesting: 'Mounting & Testing',
    custom: 'Custom',
    wallMounted: 'Wall Mounted',
    poleMounted: 'Pole Mounted',
    installationType: 'Installation type',
    mountingTypeQuestion: 'What mounting type do you wish to use ?',
    remark: 'We will contact you for a detailed price calculation',
    questionChargerType: 'What kind of charging solution are you looking for ?',
    thanksChargePassRequest: 'Thank you for your request. We will contact you soon.',
    addressDelivery: {
      street: 'Street',
      houseNumber: 'House number',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country'
    }
  },
  requests: {
    title: 'Requests'
  },
  common: {
    close: 'Close',
    noDataText: 'No data available',
    export: 'Export to Excel'
  },
  tabsNav: {
    dashboard: 'Dashboard',
    locations: 'Locations',
    administration: 'Administration'
  },
  cdr: {
    sessionId: 'Session id',
    startStopSession: 'Start/Stop session',
    startSession: 'Start session',
    sessionDuration: 'Session duration',
    token: 'charge pass',
    consumption: 'Consumption',
    status: 'Status',
    price: 'Price',
    actions: 'Actions',
    connectorNumber: 'Connector Nr',
    cpMonitor: 'Chargepoint Monitor',
    myLocations: 'My Locations'
  },
  chargelocation: {
    street: 'street',
    city: 'city',
    county: 'country',
    landOwner: 'land owner',
    description: 'description',
    houseNr: 'house number',
    postalCode: 'postal code',
    owner: 'owner',
    mergeMode: 'Merge Mode',
    newLocation: 'New Location',
    mergeSelected: 'Merge selected',
    searchLocation: 'Search Location',
    Id: 'Id',
    label: 'Label',
    Description: 'Description',
    Street: 'Street',
    City: 'City',
    LandOwner: 'Land Owner',
    Owner: 'Owner',
    Status: 'Status',
    Enable: 'Enable',
    Disable: 'Disable',
    validate3char: 'Please type at least 3 characters'
  },
  tarrifSetup: {
    alreadyExist: 'Tariff already exist with this',
    newTariff: 'new tariff',
    subType: 'subscription type',
    startDate: 'start date *',
    code: 'code',
    description: 'description',
    oneTimeFee: 'one time fee',
    monthlyFee: 'monthly fee',
    quarterlyFee: 'quarterly fee',
    yearlyFee: 'yearly fee',
    evseGroups: 'evse groups',
    calculateType: 'calculate type',
    startFee: 'start fee',
    kwhFee: 'kwh fee',
    rotationFee: 'Connection Rate (Per Min)',
    minNoFee: 'min. no fee',
    noFeeFrom: 'no fee from',
    noFeeTo: 'no fee to',
    parkingFee: 'parking fee',
    to: 'to',
    dayMax: 'day max',
    relationType: 'Relation Type',
    calcType: 'Calculate Type'
  },
  chargepoint: {
    newCharger: 'New charger', // NEW
    newAddCharger: 'Add a new charger', // NEW
    defaultChargerSettings: 'Default charger settings', // NEW
    activeGroup: 'Active group',
    tokens: 'Tokens',
    tarriffCode: 'Tariff Code',
    configure: 'Configure',
    sessionId: 'Session id',
    chargepoint: 'Chargepoint',
    startStopSession: 'start/stop session',
    sessionDuration: 'Session duration',
    chargePass: 'Charge card',
    chargePoint: 'Chargepoint',
    mychargers: 'My chargers',
    location: 'Location',
    description: 'Description',
    publicCharging: 'Public charging',
    published: 'Published',
    price: 'Cost',
    from: 'From',
    to: 'To',
    reimbursement: 'Refund',
    connectors: 'Connectors',
    activeSessions: 'Active sessions',
    pastSessions: 'Last 10 sessions',
    pastAuthorize: 'Last 10 authorizes',
    tools: 'Tools',
    technicalData: 'Technical data',
    noContract: 'No active contract',
    payBack: 'Terugbetaling actief',
    loadMore: 'Load more',
    retrieveInformation: 'Retrieving information',
    Authorizes: 'Authorizes',
    ChargeBoxIdentifier: 'ChargeBox Identifier',
    Owner: 'Owner',
    ChargeBoxSN: 'ChargeBox Serial number',
    Model: 'Model',
    OnlineStateDateTime: 'Last heartbeat',
    lastBootNotification: 'Last Bootnotification',
    bootNotificationTime: 'BootNotificationDateTime',
    addnewcharger: 'Add charger',
    id: 'Id',
    filters: 'Filters',
    viewLocation: 'View location',
    moreInfo: 'More info',
    currentError: 'Current Error',
    AVAILABLE: 'AVAILABLE',
    adminstate: 'Admin state',
    EndpointUrl: 'Endpoint',
    EndpointIp: 'Ip address',
    IccId: 'Icc',
    Imsi: 'Imsi',
    FirmwareVersion: 'Firmware Version',
    MeterType: 'Meter Type',
    MeterSN: 'Meter Serialnumber',
    Vendor: 'Vendor',
    Longitude: 'Longitude',
    Latitude: 'Latitude',
    chargepoints: 'chargepoints',
    locationLabel: 'Location Label',
    address: 'Address',
    landOwner: 'Land Owner',
    projectOwner: 'Project Owner',
    details: 'details',
    locationContactOptions: 'Contact',
    language: 'Language',
    routeToLocation: 'Route to location',
    routeToChargepoint: 'Route from location to chargepoint',
    connectorInfo: 'Connector info',
    state: 'State',
    chargepointserialnumber: 'Serial number',
    validate: 'Validate',
    step3: {
      selectExisting: 'select existing location',
      noLocation: 'No Location',
      newLocation: 'New Location',
      existingLocation: 'Existing Location',
      copyContactData: 'Copy data from contact (optional)',
      infoGmap: 'You can drag the chargepoint to pinpoint the correct location'
    },
    step4: {
      visibility: 'Visibility',
      reimbursement: 'Reimbursement',
      tarriff: 'Tarriff',
      private: 'Private',
      privateHint:
        'Chargepoint will only work with chargepasses that are configured in a whitelist',
      publicPub: 'Public Published',
      publicPubHint:
        'Chargepoint will be made publicly available for anyone to use and be will be shown in the Blue Corner app and partners of Blue Corner',
      publicNotPub: 'Public Not Published',
      publicNotPubHint:
        "Chargepoint will be made publicly available for anyone to use but won't show up in the Blue Corner app or provided to our partners",
      noReimbursement: 'No reimbursement',
      noReimbursementHint: '',
      rimbursBC: 'Reimbursement using Blue Corner charge card',
      rimbursBCHint: '',
      rimbursPartner: 'Reimbursement via partner',
      rimbursPartnerHint: '',
      rimbursPartnerExtra:
        "(Please select 'Public Published' or 'Public not published' to use this feature) ",
      rimbursAllSes: 'Full reïmbursement (all sessions)',
      rimbursAllSesHint: '',
      rimbursSpecificSes: 'Full reïmbursement (specified chargepasses)',
      rimbursSpecificSesHint: ''
    },
    table: {
      chargepoint: 'Charger reference',
      description: 'Description',
      location: 'Location',
      publicCharging: 'Public Charging',
      published: 'Published',
      reimbursement: 'Reimbursement',
      state: 'State',
      driver: 'Driver',
      driverReference: 'Driver reference',
      chargepointserialnumber: 'Serial number',
      connectors: 'Connectors',
      errorConnectors: 'Connectors in error',
      lastheartbeat: 'Last communication ago (hours)'
    },
    date: 'date',
    tokenEVCO: 'Token EVCO',
    tokenCode: 'Token Code',
    serviceProvider: 'Service Provider',
    type: 'type',
    actifsSince: 'actifs since',
    invoiceType: 'invoice type',
    subType: 'subscription type',
    publicChargingCp: 'public charging',
    tariff: 'tariff',
    stopSession: 'Stop Session',
    chargepass: 'Chargepass',
    kwh: 'kWh',
    confirmCharging: 'Are you sure you want to stop this charging session?',
    sessionEnd: 'Session ended successfully',
    errorRemote: 'Error occured while preforming Remote Stop',
    stopChargingSession: 'Stop Charging Session',
    sessionStart: 'Session Start',
    tokenGroup: 'TokenGroup',
    overruleTariff: 'OverruleTariffCode',
    evseType: 'EvseType',
    evseId: 'EvseId',
    configureWhite: 'Configure whitelist',
    actions: 'Actions',
    remoteStart: 'Remote start',
    unlock: 'Unlock'
  },

  navbar: {
    home: 'Home',
    locations: 'Locations',
    chargeLocationsConfig: 'Chargelocations Config',
    cdrs: 'Charge Detail Records',
    chargepoints: 'Chargepoints',
    CPOchargepoints: 'CPO Chargepoints',
    findToken: 'Find Token',
    tokenRequests: 'Token requests',
    bulkUpdateSubs: 'Bulk Update Subscriptions',
    offlineCPs: 'Offline chargepoints',
    whiteList: 'Whitelist',
    customers: 'Relations',
    Reports: 'Reports',
    CPOContract: 'CPO Contract',
    management: {
      title: 'Management',
      type: {
        vcpoCpo: 'VCPO - CPO (*)',
        bcCpoMsp: 'CPO (*) - MSP',
        mspVmsp: 'MSP (*) - VMSP'
      }
    },
    alarms: 'alarms',
    scheduler: 'Scheduler',
    transTool: 'Translation Tool',
    cpMonitor: 'Chargepoint Monitor',
    myLocations: 'My locations',
    subscriptionType: 'Subscription Type',
    superAdminTools: 'Super Admin tools',
    logsTools: 'Logs tools',
    chargepointMonitor: 'Chargepoint Monitor',
    genclassexp: 'Generic Class explorer',
    genericTables: 'Generic Tables',
    testTom: 'Toms Test Page',
    managementTitle: 'Management',
    subType: 'Subscription Type',
    emails: 'Emails',
    logTools: 'Log Tools',
    Alarms: 'Alarms',
    Backlogs: 'Backlogs',
    Errors: 'Errors',
    Scheduler: 'Scheduler',
    TranslationTool: 'TranslationTool',
    GenericClassesexplorer: 'GenericClassesexplorer',
    GenericTables: 'GenericTables',
    ProtectedUserInfo: 'ProtectedUserInfo',
    TestPageTom: 'TestPageTom',
    relSubs: 'Relations/Subscriptions',
    Connections: 'Connections',
    SessionInError: 'Sessions In Error',
    BulkSendCommand: 'Bulk Send Command'
  },
  dataIterator: {
    pageText: '{0}-{1} of {2}',
    noResultsText: 'No matching records found',
    loadingText: 'Loading items...'
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    rowsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.'
    }
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
    pageText: ''
  },
  datePicker: {
    itemsSelected: '{0} selected'
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous visual',
    next: 'Next visual'
  },
  calendar: {
    moreEvents: '{0} more'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  },
  tariffs: {
    tariffType: 'Tariff Type',
    AC: 'AC',
    DC: 'DC',
    Roaming: 'Roaming'
  },
  ChargeLocationDetail: {
    Offline: 'Offline',
    Online: 'Online',
    Available: 'Available',
    Charging: 'Charging',
    Parking: 'Parking',
    Error: 'Error',
    SaveChanges: 'Save changes',
    Edit: 'Edit',
    Published: 'Published',
    NotPublished: 'Not Published',
    ViewChargepoint: 'View Chargepoint',
    EvseId: 'Evse Id',
    SyncUpdateDateTime: 'Last Sync',
    LocationInfo: 'Location Information',
    Description: 'Description',
    DescriptionLocationToChargePoints: 'Route to charge point',
    DescriptionRouteToLocation: 'Route to location',
    ContactInfo: 'Contact Information',
    CreateLocation: 'Create Location',
    CoordinateQuickFill: 'Enter address to quick fill the location information',
    NoGPS: 'No gps coordinates found',
    Search: 'Search',
    DragIconOnMap: 'Drag the icon on the map',
    Save: 'Save',
    EditCoordinates: 'Edit Coordinates',
    Label: 'Label',
    Floor: 'Floor',
    Street: 'Street',
    HouseNumber: 'House Number',
    ZipCode: 'ZipCode',
    City: 'City',
    Country: 'Country',
    Language: 'Language',
    LocationMobile: 'Location Mobile',
    LocationPhone: 'Location Phone',
    Phone: 'Phone',
    LocationEmail: 'Location Email',
    NoLocationAssigned: 'No location is assigned to Charger',
    NotPermission: "You don't have access to this page",
    RemoteStart: 'Remote Start',
    Reset: 'Restart'
  },
  newnav: {
    chargepoints: 'Chargers',
    subscriptions: 'My subscriptions',
    tokens: 'Cards',
    myOrganisation: 'Organisation',
    myUsers: 'Users',
    chargesession: 'Charging history',
    servicecenter: 'Service center',
    myrequests: 'Requests',
    myReservation: 'Plan installation',
    mySurvey: 'Survey'
  },
  relationcontact: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    telephone: 'Telephone',
    vatNr: 'VAT Number',
    street: 'Street',
    houseNumber: 'House number',
    postalCode: 'Postal code',
    city: 'City',
    country: 'Country',
    remarkInternal: 'Internal Remark',
    remarkPublic: 'Public Remark'
  },
  relation: {
    name: 'Name',
    email: 'Email',
    description: 'Description',
    remarkInternal: 'Internal Remark',
    remarkPublic: 'Public Remark',
    vatnr: 'VAT Number',
    bankaccountiban: 'Bank Account Iban',
    bankaccountbic: 'Bic',
    address: 'Address',
    reference: 'Your reference',
    phone: 'Telephone'
  },
  // New
  newCharger: {
    addContent:
      "In order to add your charger to your account we'll need to test the connection.",
    addContentSN:
      "Please provide the serial number of your charger and we'll test the connection for you !",
    serialNumber: 'Serial number',
    location: 'Location',
    linked: 'Linked entity/user',
    address: 'Address',
    settings: 'Settings',
    serialNumberContent: 'The serial number can be found on the charger.',
    invalidSerialNumber: 'Invalid serial number',
    installLocation: 'Install location',
    addCharger: 'Add a new charger',
    whereInstallCharger: 'where is the charger installed ?',
    home: 'Home',
    company: 'Company',
    mainUser: 'Main user',
    whoMainUser: 'Who is the main user of the charger ?',
    newUser: 'New User',
    emailAccount:
      ' An e-mail request wil be sent to this address to setup the account.',
    ibanNumberInfo: 'The iban number for reimbursements.',
    existingUser: 'Existing User',
    bankInfo: 'Bank details',
    emailInfo: 'A mail will be sent to this address to setup the account.',
    settingsContent:
      "You can already add some settings to the new charger for when it's installed. You can save these settings for future installations.",
    usePreset: 'Use pre defined settings (preset)',
    useWizard: 'Choose your settings',
    whatChargerInstalled: 'Select the type of authorisation',
    withRFIDReaders: 'RFID',
    plugCharge: 'Plug & Play',
    enableGuestCharging: 'Do you want to enable guest charging ?',
    howMuchUsageCharger: 'Select the charging fee for others',
    free: 'Free',
    noAdditionalFees: 'No additional fees',
    customFee: 'Custom fees',
    customFeeContent:
      'You can choose your own fee to charge other users when they use your charger. Adds an additional cost of 4 euros/month.',
    searchCustomFee: 'Search a custom fee',
    searchChargeCard: 'Search a Charge Card',
    notFounSub: "Didn't find what you're looking for?",
    addOtherCard: 'Add another card',
    createSub: 'Create a new one',
    bluecornerFee: 'Blue Corner fee',
    bluecornerFeeContent: 'Allow charging at Blue Corner public rates.',
    bluecornerFeeSelect:
      'Selecting this option will publish your charger on the charger maps as a bluecorner charger.',
    whichChargeCardsFree: 'Which charge cards can have free acces?',
    addCardViaCharger: 'Add Charge Card via Charger',
    greatAddcardViaCharger:
      'Great! We have received your card info. How do you wish to call this card ?',
    tapCardTerminal:
      "Tap your card on the terminal of the charger to retrieve it's info.",
    addNonBluecornerCard:
      'You can also add other, non Blue Corner Charge Cards',
    scannedMyCard: 'I have scanned my card',
    greatInfo:
      'Great! We have received your card info. How do you wish to call this card ?',
    cardSwipe: 'Card swiped at',
    description: 'description',
    swipeAgain: 'swipe again',
    addCard: 'add card',
    otherPeopleFindCharger: 'Allow my charger to be visible on public maps.',
    otherPeopleFindChargerContent:
      "Don't Allow my charger to be visible on public maps ",
    otherDriverPay: 'Should some drivers pay for charging ?',
    otherDriverPayContent:
      'You can select certain drivers to be invoiced for charging at your charger. For example if a neighbour wishes to use your homecharger, the card will then be charged at your chosen rate.',
    thatWasAll: 'That was all!',
    greatInfoSetup:
      'Great! We have all information to correctly setup the charger.',
    reimburseLinkedUser: 'Does the main user need reimbursement?',
    reimburseLinkedUserContent:
      'Reimbursement allows you to automatically reimburse the selected user for their charging sessions. They will be reimbursed by bank transfer on a monthly basis while their employer receives an invoice simultaneously. You can specify this feature in the next step.',
    receiveInvoiceReimbursement:
      'Who is paying for your home charging sessions ?',
    receiveInvoiceReimbursementContent:
      'Who is paying for your home charging sessions ?',
    usageReimburseOrganization: 'Which charging sessions can be reimbursed ?',
    usageReimburseContent:
      'Select the Drivers (via their charge cards) that should receive reimbursement',
    standardFee: 'Standard fee (based on CREG)',
    fee: 'Fee',
    eurKwh: 'EUR/KWh'
  },
  // New
  preset: {
    title: 'Presets',
    explain: 'Change the settings of existing',
    explainBis: 'presets and add new presets.',
    changeSetting:
      'You can change the settings of the charger. These changes only apply to this charger.',
    typeCharger: 'Type of charger',
    rfid: 'RFID',
    rfidContent:
      'The charger has a RFID reader and should be activated with a charge card.',
    plugCharge: 'Plug & Charge',
    plugChargeContent:
      "The charger doesn't need to be activated with a charge card. Just plug in the cable and start charging. ",
    guestCharging: 'Guest charging',
    enableGuestCharging: 'Enable guest charging',
    canEverybodyCharge: 'Is everybody allowed to charge here? ',
    rate: 'Rate',
    published: 'Published',
    publishedContent: 'Allow the charger to be published on maps',
    reimbursement: 'Reimbursement',
    enableReimbursement: 'Enable reimbursement',
    enableReimbursementContent:
      'Reimbursement allows you to automatically reimburse the selected user for their charging sessions. They will be reimbursed by bank transfer on a monthly basis while their employer receives an invoice simultaneously. You can specify this feature in the next step.',
    disabled: 'Disabled',
    enabled: 'Enabled',
    selectChargeCard: 'Select a Chargecard',
    priceEuroKwh: 'price in €/kWh',
    fee: 'Fee',
    company: 'Company',
    toReimbursementCompany: 'Who is paying for your home charging sessions ?',
    addPreset: 'Add preset',
    location: 'Location',
    home: 'Home',
    addCustomPreset: 'Add custom',
    addHomePreset: 'Add default Home',
    addCompanyPreset: 'Add default Company',
    addPlugChargePreset: 'Add default Plug & Charge'
  },
  generic: {
    exclVAT: 'Excl. VAT',
    save: 'Save',
    cancel: 'Cancel',
    back: 'Back',
    close: 'Close',
    next: 'Next',
    update: 'Update',
    yes: 'Yes',
    no: 'No',
    other: "I don't know",
    create: 'Create',
    ok: 'ok',
    or: 'Or',
    add: 'Add',
    previous: 'Previous', // NEW
    new: 'New',
    finish: 'Finish',
    nl: 'NL',
    fr: 'FR',
    en: 'EN'
  },
  jira: {
    delays:
      'We are currently experiencing delays in our charge card production, new charge cards',
    delaysSUb: 'are expected to be send after 08/03/2022.',
    status: 'Status',
    created: 'Created',
    chargePass: 'Charge card',
    support: 'Support',
    description: 'Description',
    orderQuantity: 'Order Quantity',
    address: 'Address',
    summary: 'Summary',
    name: 'Name',
    companyDetails: 'Company Details',
    chargepoint: 'Chargepoint',
    titleQuestion: 'Question title ?',
    howCanHelp: 'How can we help ?',
    companyName: 'Company name',
    phoneNumber: 'Phone number',
    streetAddress: 'Street Address (invoicing)',
    postalCode: 'Postal Code (invoicing)',
    city: 'City (invoicing)',
    email: 'Email (invoicing)'
  },
  settings: {
    cardAccess: 'Only the defined cards are allowed to charge',
    guestCharging:
      'Guest Charging: everyone is allowed to charge on your charger',
    plugCharge: 'Plug&Charge: your charger is working without RFID scanner',
    reimburser:
      "Reimbursement: the charging sessions will be reimbursed with a credit nota to the electricity owner. (This is not applicable for Network partnership or VCPO's)",
    published: 'Published: everyone can see the location of your charger'
  }
}
