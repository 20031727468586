import Vue from 'vue'
import Component from 'vue-class-component'
import { VIcon, VTabs, VTab } from 'vuetify/lib'
import { VNodeData } from 'vue/types/umd'

@Component
export default class AppBarTabs extends Vue {
  tmp: Array<any> = []

  get items(): Array<any> {
    const tmp: Array<any> = [
      {
        title: this.t('$vuetify.tabsNav.dashboard'),
        icon: 'uil uil-tachometer-fast',
        type: 'router',
        router: { name: 'dashboard', exact: true }
      },
      {
        title: this.t('$vuetify.tabsNav.locations'),
        icon: 'uil uil-map-pin-alt',
        type: 'router',
        router: { name: 'ChargeLocationsMap', exact: true }
      },
      {
        title: this.t('$vuetify.tabsNav.administration'),
        icon: 'uil uil-cog',
        type: 'router',
        router: { name: 'administration' }
      }
    ]
    return tmp
  }

  _genTabs() {
    const tmp: Array<any> = []
    this.items.forEach((e) => {
      const opts: VNodeData = {
        class: 'lowerCase',
        props: {},
        style: {
          color: 'white',
        }
      }
      if (e.type === 'router') {
        // @ts-ignore
        opts.props.to = e.router
        if (e.router.exact) {
          // @ts-ignore
          opts.props.exact = true
        }
      }
      tmp.push(
        this.$createElement(VTab, opts, [
          this.$createElement(VIcon, {
            props: {
              color: 'white',
            },
          }, e.icon),
          ` ${e.title}`,
        ])
      )
    })
    return tmp
  }

  _genTabsContainer(h: any) {
    return h(
      VTabs,
      {
        props: { 'align-with-title': true, color: 'white', value: this.tmp },
        on: {
          change: (d: any) => {
            this.tmp = d
          }
        }
      },
      this._genTabs()
    )
  }

  render(h: any) {
    return this._genTabsContainer(h)
  }
}

