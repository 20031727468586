export default [
  {
    title: 'newnav.myOrganisation',
    type: 'item',
    icon: 'uil uil-users-alt',
    router: { name: 'navmyOrganisation' }
  },
  {
    title: 'newnav.myUsers',
    type: 'item',
    icon: 'uil uil-users-alt',
    router: { name: 'navmyOrganisation' }
  },
  {
    title: 'newnav.chargepoints',
    type: 'item',
    icon: 'mdi-ev-station',
    router: { name: 'navchargepoints' }
  },
  {
    title: 'newnav.tokens',
    type: 'item',
    icon: 'uil uil-credit-card',
    router: { name: 'navtokens' }
  },
  {
    title: 'newnav.chargesession',
    type: 'item',
    icon: 'uil uil-history',
    router: { name: 'navchargesession' }
  },
  {
    title: 'newnav.myrequests',
    type: 'item',
    icon: 'uil uil-file-landscape-alt',
    router: { name: 'myRequests' }
  }
]
