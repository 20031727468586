














import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
/**
 * @group CoreComponents
 * Base button component with styles added specifically for the BlueCorner design
 * this component extends the default Vuetify Button so every VBtn property passed to this component will be applied to this component
 * https://vuetifyjs.com/en/components/buttons/
 */
export default class BcBtn extends Vue {
  @Prop({ type: Boolean, default: false }) readonly outlined!: boolean
  @Prop({ type: String, default: 'primary' }) readonly color!: string

  get classes () {
    let tmp = 'bc-btn '
    if (this.outlined) {
      tmp += 'bc-btn__outlined '
    }
    return tmp.trim()
  }
}
