import Vue from 'vue'
import BcBtn from '../components/_base/BcBtn'
import BcBtnUx from '../components/_base/BcBtnUx'
import BcSimpleTable from '../components/_base/BcSimpleTable'
import BcSimpleTableUx from '../components/_base/BcSimpleTableUx'
import BcDataTable from '../components/_base/BcDataTable'
import BcCountrySelect from '../components/_base/BcCountrySelect'
import BcCardStep from '../components/_base/BcCardStep'
import BcCardSettings from '../components/_base/BcCardSettings'
import BcStepBtnChoice from '../components/_base/BcStepBtnChoice'

// import GenericTablePagination from '../components/_base/BcDataTable/GenericTablePagination.vue'
import VueApexCharts from 'vue-apexcharts'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.component('BcBtn', BcBtn)
Vue.component('BcBtnUx', BcBtnUx)
Vue.component('BcCardStep', BcCardStep)
Vue.component('BcCardSettings', BcCardSettings)
Vue.component('BcSimpleTable', BcSimpleTable)
Vue.component('BcSimpleTableUx', BcSimpleTableUx)
Vue.component('BcDataTable', BcDataTable)
Vue.component('BcCountrySelect', BcCountrySelect)
Vue.component('BcStepBtnChoice', BcStepBtnChoice)
Vue.use(VueApexCharts)
Vue.use(Treeselect)
Vue.component('apexchart', VueApexCharts)
Vue.component('treeselect', Treeselect)
// Vue.component('GenericTablePagination', GenericTablePagination)
