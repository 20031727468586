export default (data: any, filename = 'export.json') => {
  const tmp = JSON.stringify(data, null, 2)
  const blob = new Blob([tmp], { type: 'data:Application/octet-stream;' })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // console.log('doing it thisway')
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const element = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    element.setAttribute('href', url)
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}
