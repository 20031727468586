import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class SideMenu extends VuexModule {
  _userMenu = false
  _visibleMenu = true

  _mobileMenu = false

  get userMenu () {
    return this._userMenu
  }

  get visible () {
    return this._visibleMenu
  }

  @Mutation
  toggleVisible () {
    this._visibleMenu = !this._visibleMenu
  }

  @Mutation
  setVisible (val: boolean) {
    this._visibleMenu = val
  }

  // MOBILE MENU
  @Mutation
  toggleVisibleMobile () {
    this._mobileMenu = !this._mobileMenu
  }

  @Mutation
  setVisibleMobile (val: boolean) {
    this._mobileMenu = val
  }

  get mobileMenu () {
    return this._mobileMenu
  }

  @Mutation
  setNavBar (value: string) {
    if (value === 'user') {
      this._userMenu = true
    } else {
      this._userMenu = false
    }
  }
}
