









































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import AccountMenu from '../../_core/AccountMenu'
import usernav from './navigation'
import { Watch } from 'vue-property-decorator'

@Component({
  components:{
    AccountMenu
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' })
  }
})
export default class FixedNav extends Vue {
  nav = usernav
  model = 0

  get _items () {
    if (this.$store.getters['sideMenu/userMenu']) {
      return usernav
    }
  }

  get _headItems () {
    const headNav: Array<any> = [
      { title: this.t('$vuetify.tabsNav.dashboard'), icon: 'uil uil-tachometer-fast', type: 'router', router: { name: 'dashboard', exact: true } },
      { title: this.t('$vuetify.tabsNav.locations'), icon: 'uil uil-map-pin-alt', type: 'router', router: { name: 'ChargeLocationsMap', exact: true } },
      { title: this.t('$vuetify.tabsNav.administration'), icon: 'uil uil-cog', type: 'router', router: { name: 'administration' } }
    ]
    return headNav;
}

  get visible () {
    return this.$store.getters['sideMenu/mobileMenu']
  }

  set visible (newValue) {
    // this.$store.commit('sideMenu/toggleVisibleMobile')
    // this.$store.commit('sideMenu/setVisible', newValue)
  }

  getPage (item: any) {
    // this.$router.push(item.router)
    // console.log('TOGGLE FALSE')
    // this.$store.commit('sideMenu/setVisibleMobile', false)
    return item
  }

  checkAllowedProd () {
    return true
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: any) {
    this.$store.commit('sideMenu/setVisibleMobile', false)
  }
}
