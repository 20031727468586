import BaseApi from './BaseApi'
import { RelationContactInterface } from './RelationContactApi'
import RelationTreeConfig from '@/typings/ApiTypings/RelationTreeConfig'
import { AxiosRequestConfig } from 'axios'
export interface RelationInterface {
  AccountancyId?: number | string | RelationContactInterface
  CompanyType?: string
  CusId?: number | string
  DeliveryContactId?: number | string | RelationContactInterface
  Description?: string
  Email?: string
  Id?: number | string
  InvoiceContactId?: number | string | RelationContactInterface
  IsCompany?: number | string
  MainContactId?: number | string | RelationContactInterface
  Name?: string
  ParentId?: number | string
  PartnerType?: number | string
  Phone?: number | string
  PlanonId?: number | string
  RelationDescriptionList?: string
  Relation?: RelationInterface
  RelationIdList?: string
  RelationContact?: RelationContactInterface
  RemarkInternal?: string
  RemarkPublic?: string
  SearchString?: string
  SupId?: number | string
  Uci?: string
  VatNr?: string
  Reference?: string
  Street?: string
  Housenumber?: string
  HouseNumber?: string
  City?: string
  role?: string | string[]
  Postalcode?: string
  PostalCode?: string
  Country?: string
}

interface BreadCrumbInterface {
  Description: string
  Last: boolean
}
export interface BreadCrumbRelationInterface extends RelationInterface {
  Breadcrumb: BreadCrumbInterface[]
}

class RelationApi extends BaseApi {
  constructor(type = 0) {
    super('Relation', type)
  }

  async getRelationById(id: string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${id}`
    }
    return await this.getApi(options)
  }

  async relationTree(
    config: RelationTreeConfig = {},
    isAsc = true,
    filter = '{ "description": "" }',
    directonly = 1
  ) {
    let parent = -1
    if (config) {
      if (config.parentId) {
        parent = config.parentId
      }
    }
    let options = {}
    if (filter !== '{ "description": "" }') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&filter=${filter}&sort=description desc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&filter=${filter}&sort=description asc`
        }
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&sort=description desc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeview/${parent}/Relation?directonly=${directonly}&sort=description asc`
        }
      }
    }
    return await this.getApi(options)
  }

  async relationAllTree(isAsc = true, filter = '{ "description": "" }') {
    let options = {}
    if (filter !== '{ "description": "" }') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
        }
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
      }
      if (isAsc) {
        options = {
          url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/treeviewer?sort=description asc`
        }
      }
    }
    return await this.getApi(options)
  }

  async ChildrenOfElement(parentId: number | string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/childelements/${parentId}`
    }
    return await this.getApi(options)
  }

  async createUpdate(relation: RelationInterface) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/createupdate`,
      data: relation
    }
    return await this.postApi(opts)
  }

  async convertRelation(relationContactId: string) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/contact/intorelation/${relationContactId}`
    }
    return await this.postApi(opts, true, true)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async createGroup(relationId: number, payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/createupdate`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async duplicateGroup(TGCode: string, inDTA: any) {
    const code = TGCode.replace('#', '%23')
    const payload = {
      Code: '',
      Description: inDTA.groupName
    }
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${code}/clone`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because adding chargers doesn't get handled in EVA
  async addprintedNumber(TGCode: any, WLCode: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCode.replace('#', '%23')
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/${codeWL}/addprintednumber`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because adding chargers doesn't get handled in EVA
  async addPrintedAuthorizeNumber(TGCode: any, WLCode: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCode.replace('#', '%23')
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/${codeWL}/addauthorize`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because adding chargers doesn't get handled in EVA
  async createWhiteList(TGCode: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/whitelist/createupdate`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async deleteWhitelist(TGCode: any, WLCode: any, payload: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCode.replace('#', '%23')
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/whitelist/${codeWL}`,
      data: payload
    }
    return await this.deleteApi(opts)
  }

  // Todo remove this because adding chargers doesn't get handled in EVA
  async addSubscriptionType(payload: any, id: string) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${id}/createsubscriptiontype`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because adding chargers doesn't get handled in EVA
  async getTariffs(relationId: number, filter = '{ "description": "" }') {
    let options = {}
    if (filter !== '{ "description": "" }') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/subscriptiontype/list/filtered??page[size]=100&page[number]=1&caching=1&filter=${filter}`
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/subscriptiontype/list/filtered?page[size]=100&page[number]=1&caching=1&includeCreg=1`
      }
    }
    return await this.getApi(options)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async moveTokenFromGroup(
    TGCode: string,
    WLCodeFrom: string,
    WLCodeTo: string,
    payload: any
  ) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCodeFrom.replace('#', '%23')
    const codeT = WLCodeTo.replace('#', '%23')

    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/movetokens/${codeWL}/to/${codeT}`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async getWhiteListGroup(relationId: any, filter = '{ "description": "" }') {
    let options = {}
    if (filter !== '{ "description": "" }') {
      filter = JSON.stringify(filter)
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/list/filtered?page[size]=100&page[number]=1&caching=0&filter=${filter}`
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/list/filtered?page[size]=100&page[number]=1&caching=0`
      }
    }
    return await this.getApi(options)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async removeWLGroup(relationId: any, TGCode: any) {
    const codeTG = TGCode.replace('#', '%23')
    let options = {}
    options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/${relationId}/whitelistgroup/${codeTG}`
    }
    return await this.deleteApi(options)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async removeTokenWLGroup(TGCode: any, WLCode: any, TCode: any) {
    const codeTG = TGCode.replace('#', '%23')
    const codeWL = WLCode.replace('#', '%23')
    const codeT = TCode.replace('#', '%23')
    let options = {}
    options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/whitelistgroup/BCO/${codeTG}/${codeWL}/${codeT}`
    }
    return await this.deleteApi(options)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async setGroup(placementId: any, payload: any) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargeplacement/BCO/${placementId}/whitelistgroup`,
      data: payload
    }
    return await this.postApi(opts)
  }

  // Todo remove this because managing whitelist doesn't get handled in EVA
  async getGroup(placementId: any) {
    let options = {}
    options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargeplacement/BCO/${placementId}/whitelistgroup`
    }
    return await this.getApi(options)
  }

  async getRelationsForCurrentUser(isRelationsOnly=false) {
    const opts: AxiosRequestConfig = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/relation/BCO/impersonate/list?isRelationsOnly=${isRelationsOnly}`
    }
    return await this.getApi(opts)
  }

  async checkIBAN(iban: string) {
    const options: AxiosRequestConfig = {
      url: `https://openiban.com/validate/${iban}?getBIC=true`
    }
    return await this.getExterneApi(options)
  }

  async checkVAT(iban: string) {
    const options: AxiosRequestConfig = {
      url: `https://controleerbtwnummer.eu/api/validate/${iban}.json`
    }
    return await this.getExterneApi(options, false)
  }
}

export { RelationApi }
export default RelationApi
