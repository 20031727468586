/* eslint-disable */

import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

/*
  include cable mis
*/

@Module({ namespaced: true })
export default class RequestsState extends VuexModule {
  current = 'AccountType'
  previous: Array<any> = []
  // totPrice: Array<any> = []
  totPrice: Array<any> = []
  // totPrice: any = {}
  prevPrice = 0
  elm = {
    // accounType: false, // false enterprise site - true - home site
    summary: '', // Summary - string [MANDATORY]
    description: '', // Description - string
    attachment: [], // Attachment - string
    customfield_15729: { "id": "" }, // Charger Type - string [REQUIRED]
    customfield_15730: { "id": "13840" }, // Mounting Type - string [REQUIRED] [MANDATORY]
    // customfield_15730: '', // Mounting Type - string
    customfield_15736: { "id": "" }, // Power connection - string [REQUIRED]
    customfield_15731: { "id": "" }, // Installation Type - string REQUIRED
    // customfield_15731: '13842', // Installation Type - string REQUIRED
    // customfield_15732: '', // Charger Subscription - string [REQUIRED]
    customfield_15732: { "id": "13846" }, // Charger Subscription - string [REQUIRED] [MANDATORY]
    customfield_15733: { "id": "13848" }, // Dynamic Load Balancing - string [MANDATORY]
    customfield_13200: '', // Customer ID - string
    customfield_15407: '', // Company name - string
    customfield_15401: '', // Customer Address - string
    customfield_12509: '', // E-mail address - string
    customfield_12508: '', // Phone number - string
    customfield_15742: '', // Street Address (delivery) - string
    customfield_15743: '', // Postal Code (delivery) - string
    customfield_15744: '', // City (delivery) - string
    customfield_15746: '', // Street Address (invoicing) - string
    customfield_15747: '', // Postal Code (invoicing) - string
    customfield_15748: '', // City (invoicing) - string
    customfield_15749: '' // Email (invoicing) - string
  }

  @Mutation
  setUserInfo (usr: any) {
    this.elm.customfield_12509 = usr.data.RelationContact.Email // E-mail address - string
    this.elm.customfield_12508 = usr.data.RelationContact.Telephone // Phone number - string
    this.elm.customfield_15742 = usr.data.RelationContact.Street // Street Address (delivery) - string
    this.elm.customfield_15743 = usr.data.RelationContact.PostalCode // Postal Code (delivery) - string
    this.elm.customfield_15744 = usr.data.RelationContact.City // City (delivery) - string
  }

  @Mutation
  setStep (name: any) {
    this.previous.push(this.current)
    this.current = name
  }

  @Mutation
  setPreviousStepName () {
    this.current = this.previous.pop()
    // this.previous.push(name)
  }

  @Mutation
  setMountingType (val: any) {
    this.elm.customfield_15730 = { "id": val }
  }

  @Mutation
  setDynamicType (val: any) {
    this.elm.customfield_15733 = { "id": val }
  }

  @Mutation
  setDescription (val: any) {
    this.elm.description = val
  }

  @Mutation
  setChargerType (val: any) {
    this.elm.customfield_15729 = { "id": val }
  }

  @Mutation
  setInstallationType (val: any) {
    this.elm.customfield_15731 = { "id": val }
  }

  @Mutation
  setSummary (val: any) {
    this.elm.summary = val
  }


  @Mutation
  setMountedType (val: any) {
    this.elm.customfield_15730 = { "id": val }
  }

  @Mutation
  setPowerConnection (val: any) {
    this.elm.customfield_15736 = { "id": val }
  }

  @Mutation
  setSubscriptionType (val: any) {
    this.elm.customfield_15732 = { "id": val }
  }

  @Mutation
  setCustomerId (val: any) {
    this.elm.customfield_13200 = val
  }

  @Mutation
  setCompanyName (val: any) {
    this.elm.customfield_15407 = val
  }
  
  @Mutation
  setCompanyDetails (val: any) {
    // [TEMP] invoicing fields
    this.elm.customfield_15407 = val.companyName + ' - ' + val.vat
    this.elm.customfield_13200 = val.sexe + ' ' + val.name
    this.elm.customfield_15746 = val.address
    this.elm.customfield_15747 = val.zipCode
    this.elm.customfield_15748 = val.city
    this.elm.customfield_15749 = val.email
    this.elm.customfield_12508 = val.phone
  }

  @Mutation
  setInvoiceAddress (val: any) {
    // [TEMP] invoicing fields
    this.elm.customfield_15407 = val.companyName + ' - ' + val.vat
    this.elm.customfield_13200 = val.sexe + ' ' + val.name
    this.elm.customfield_15746 = val.address
    this.elm.customfield_15747 = val.zipCode
    this.elm.customfield_15748 = val.city
  }

  @Mutation
  setDeliveryAddress (val: any) {
    /* address: "Kerkstraat 7"
    addressBis: ""
    city: "Antwerpen"
    companyName: "Jan Pietje"
    country: ""
    isCompany: 0
    name: "Jan Pietje"
    sexe: "Mr."
    vat: ""
    zipCode: "2000"
    console.log('Delivery Address')
    console.log(val) */
    // [TEMP] delivery fields
    this.elm.customfield_15407 = val.companyName + ' - ' + val.vat
    this.elm.customfield_13200 = val.sexe + ' ' + val.name
    this.elm.customfield_15742 = val.address
    this.elm.customfield_15743 = val.zipCode
    this.elm.customfield_15744 = val.city
  }

  @Mutation
  setSameDeliveryAddress () {
    this.elm.customfield_15407 = this.elm.customfield_15407
    this.elm.customfield_13200 = this.elm.customfield_13200
    this.elm.customfield_15742 = this.elm.customfield_15746
    this.elm.customfield_15743 = this.elm.customfield_15747
    this.elm.customfield_15744 = this.elm.customfield_15748
  }

  @Mutation
  setEmail (val: any) {
    this.elm.customfield_15749 = val.email
  }

  @Mutation
  setPhone (val: any) {
    this.elm.customfield_12508 = val.phone
  }

  @Mutation
  setPrice (elm: any) {
    /* console.log('elm')
    console.log(elm)
    this.totPrice[elm.type] = elm.price
    console.log('SET PRICE') */
    // this.totPrice.push(elm)
    // this.totPrice[elm.type] = elm.price
    // console.log(this.totPrice)
    // search if price exist
    let pos = -1
    for (let index = 0; index < this.totPrice.length && pos === -1; index++) {
      const priceElm = this.totPrice[index]
      if (priceElm.type === elm.type) {
        pos = index
      }
    }
    if (pos >= 0) {
      this.totPrice[pos].price = elm.price
    } else {
      this.totPrice.push(elm)
    }
    // console.log(this.totPrice)
  }

  @Mutation
  removePrice (elm: any) {
    // console.log('Remove Price')
    // console.log(elm)
    // delete this.totPrice[elm]
    let pos = -1
    for (let index = 0; index < this.totPrice.length && pos === -1; index++) {
      const priceElm = this.totPrice[index]
      console.log(priceElm)
      if (priceElm.type === elm) {
        pos = index
      }
    }
    // console.log('RSLT : ')
    // console.log(pos)
    if (pos >= 0) {
      this.totPrice.splice(pos,1)
      // this.totPrice[pos].price = 0
    }
    // this.totPrice.pop()
  }

  get price () {
    return this.totPrice
  }

  get totalPrice () {
    // console.log('Calc')
    // console.log(this.price.length)
    let sum = 0
    for (let index = 0; index < this.price.length; index++) {
      sum += this.price[index].price
    }
    return sum
  }


  @Mutation
  setPreviousPrice (val: any) {
    this.prevPrice = val
  }

  get previousPrice () {
    return this.prevPrice
  }

  

  get stepName () {
    return this.current
  }

  get elmState () {
    return this.elm
  }

  get previousStepName () {
    return this.previous[(this.previous.length - 1)]
  }
}
