import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { method } from 'lodash'
import store from '../store'

interface ChangePasswordObject {
  oldPassword: string
  newPassword: string
}

export interface ISetupUser {
  vatnr: string,
  name: string,
  iscompany: string,
  companytype: string,
  phone: string,
  description: string,
  city: string,
  country: string,
  housenumber: string,
  postalcode: string,
  street: string,
  telephone: string,
  companyname?: string
}

export default class UserApi {
  token: string | undefined = undefined
  tokenType = 'Bearer'

  constructor () {
    this.token = this.getToken()
  }

  private getToken () {
    const storage = localStorage
    let obj: any = null
    Object.entries(storage).forEach(([key, value]) => {
      if (key.search(`oidc.user:${process.env.VUE_APP_OAUTH_AUTHORITY}`) > -1) {
        obj = JSON.parse(value)
      }
    })
    return obj.access_token
  }

  public async changePassword (passwordObject: ChangePasswordObject) {
    return new Promise(async (resolve, reject) => {
      let obj: AxiosRequestConfig = {
        url: `${process.env.VUE_APP_OAUTH_AUTHORITY}/api/User/ChangePassword`,
        method: 'PUT',
        data: passwordObject
      }
      obj = this.checkHeaders(obj, true)
      try {
        // [bitbucket]
        const res = await this.rawHttp(obj) as any
        resolve(res)
      } catch (error) {
        reject(new Error(error))
      }
    })
  }

  public async cleanup () {
    let opts: any = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/Dev/CleanUp?debug=1`
    }
    opts = this.checkHeaders(opts, true)
    return await this.rawHttp(opts)
  }

  public async setupUser (payload: ISetupUser, debug = false) {
    return new Promise(async (resolve, reject) => {
      let opts: AxiosRequestConfig = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/Setup`,
        method: 'post',
        data: payload
      }
      if (debug) {
        opts.url = `${opts.url}?debug=1`
        console.log('debug is enabled, url is now:', opts.url)
      }
      opts = this.checkHeaders(opts, true)
      const result = await this.rawHttp(opts) as AxiosResponse
      try {
        resolve(result)
      } catch (err) {
        reject(err)
        console.error(err)
      }
    })
  }

  public async userInfo () {
    return new Promise(async (resolve) => {
      let opts: AxiosRequestConfig = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/UserInfo`
      }
      opts = this.checkHeaders(opts, true)
      const res = await this.rawHttp(opts) as any
      if (res && (res.data.result.errorcode === "NOTLOGGEDIN" || res.data.result.errorcode === "FAILEDTOLOAD" || res.data.result.errorcode === "InvalidToken")) {
        store.dispatch('oidcStore/signOutOidc')
      }
      store.dispatch('oidcStore/authenticateOidcSilent')
      resolve(res)
    })
  }

  public async myInfo () {
    return new Promise(async (resolve) => {
      let opts: AxiosRequestConfig = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/UserInfo`
      }
      opts = this.checkHeaders(opts, true)
      const res = await this.rawHttp(opts) as any
      resolve(res)
    })
  }

  async getGenericInfo (id: any, type = '', isAllChargepoint = true) {
    let options: any = {}
    if (type !== 'ChargePoint') {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/My/Info/${id}/${type}`
      }
    } else {
      options = {
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/user/BCO/My/Info/${id}/${type}?directonly=${isAllChargepoint}`
      }
    }

    return await this.get(options)
  }

  public async get (options: any, useAuth = true) {
    return new Promise((resolve, reject) => {
      options = this.checkHeaders(options, useAuth)
      this.rawHttp(options).then((res: any) => {
        try {
          const dt = this.checkResult(res)
          resolve(dt)
        } catch (err) {
          console.error(err)
          reject(err)
        }
      })
    })
  }

  private checkResult (result: AxiosResponse) {
    if (result.data) {
      if (result.data.result) {
        if (result.data.result.status === 'success') {
          return result.data.data
        } else if (result.data.result.status === 'error') {
          console.log('did i get here?')
          if (result.data.result.errorcode === 'InvalidToken') {
            throw new Error('InvalidToken')
          } else if (result.data.result.errorcode) {
            throw new Error(result.data.result.message)
          }
        }
      }
    }
  }

  // checkHeaders
  // @args {Object} options - options for the get Api
  // @args {Number} mode - mode where in the request is sendn(0 = admin, 1 = cpo, 3 = msp, 5 = landowner)
  // @args {Boolean} useAuth - allows you to disable the authentication (Default=true)
  // @return Object
  checkHeaders (options: any, useAuth: any) {
    let opt: any = {}
    if (typeof options === 'string') {
      opt.url = this.checkUrl(options)
    } else {
      options.url = this.checkUrl(options.url)
      opt = options
    }
    if (useAuth) {
      if (this.token !== '') {
        if (opt.headers) {
          opt.headers.Authorization = `${this.tokenType} ${this.token}`
        } else {
          opt.headers = {
            Authorization: `${this.tokenType} ${this.token}`
          }
        }
      } else {
        return new Error('No token was set')
      }
    }
    return opt
  }

  private checkUrl (url: any) {
    if (url.substring(0, 4) === 'http') {
      return url
    } else {
      return `${process.env.VUE_APP_API_ENDPOINT}/${url}`
    }
  }

  // rawHttp
  // @args {Object} options - options for the get Api
  // @return Promise
  private async rawHttp (options: AxiosRequestConfig): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      axios(options).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
