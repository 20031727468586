interface FeatureFlag {
  name: string
  // eslint-disable-next-line camelcase
  default_enabled: boolean
}

interface FlagSmithFeatureFlag {
  feature: FeatureFlag
  enabled: boolean
}

export const GENERIC_FILTER_CHARGEPASS_REQUEST =
  'generic_relation_filter_chargepassrequest'

export const MAINTENANCE = 'maintenance'

export const GENERIC_FILTER_CHARGING_HISTORY =
  'generic_relation_filter_charginghistory'

export const GENERIC_FILTER_CARDS = 'generic_relation_filter_cards'

export const GENERIC_FILTER_CHARGERS = 'generic_relation_filter_chargers'

export const GENERIC_SUPPORT_ATTACHMENTS = 'generic_support_attachments'

export const GENERIC_SUPPORT_DRIVER_TEMPLATE = 'generic_support_driver_template'

export const localFeatureFlags = (): FlagSmithFeatureFlag[] => {
  return [
    {
      feature: {
        name: GENERIC_FILTER_CHARGEPASS_REQUEST,
        default_enabled: false
      },
      enabled: true
    },
    {
      feature: {
        name: 'maintenance',
        default_enabled: false
      },
      enabled: false
    },
    {
      feature: {
        name: GENERIC_FILTER_CHARGING_HISTORY,
        default_enabled: false
      },
      enabled: true
    },
    {
      feature: {
        name: GENERIC_FILTER_CARDS,
        default_enabled: false
      },
      enabled: true
    },
    {
      feature: {
        name: GENERIC_FILTER_CHARGERS,
        default_enabled: false
      },
      enabled: true
    },
    {
      feature: {
        name: GENERIC_SUPPORT_ATTACHMENTS,
        default_enabled: false
      },
      enabled: true
    },
    {
      feature: {
        name: GENERIC_SUPPORT_DRIVER_TEMPLATE,
        default_enabled: false
      },
      enabled: true
    }
  ]
}
