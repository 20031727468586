export default (objArray: Array<any>, filename = 'export.csv', delimiter = ',') => {
  // console.log('objArray', objArray)
  const keys = Object.keys(objArray[0])
  const replace = (key: any, value: any) => { return value === null ? '' : value }
  const csv = objArray.map((row) => {
    return keys.map((fieldName) => {
      return JSON.stringify(row[fieldName], replace)
    }).join(delimiter)
  })
  csv.unshift(keys.join(delimiter))
  const tmp = csv.join('\r\n')
  const blob = new Blob([tmp], { type: 'data:Application/octet-stream;' })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // console.log('doing it thisway')
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const element = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    element.setAttribute('href', url)
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}
