<template>
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 512 512" width="512" height="512"><path d="M346.343,445.657A8,8,0,0,0,352,448q.394,0,.789-.039a8,8,0,0,0,5.868-3.523l64-96a8,8,0,0,0-13.312-8.876l-58.587,87.881-25.1-25.1a8,8,0,0,0-11.314,11.314Z"/><path d="M36.169,415.023C39.836,417.023,126.983,464,216,464a332.263,332.263,0,0,0,67.025-7.122A111.978,111.978,0,1,0,368,272c-2.142,0-4.268.066-6.38.186-25.414-38.012-61.228-63.9-102.114-74.624a96,96,0,1,0-86.978.017c-33.16,8.7-63.155,27.408-87.009,54.772C51.007,291.941,32,347.218,32,408A8,8,0,0,0,36.169,415.023ZM368,288a96,96,0,1,1-96,96A96.108,96.108,0,0,1,368,288ZM136,112a80,80,0,1,1,80,80A80.091,80.091,0,0,1,136,112ZM97.579,262.865C128.422,227.485,170.478,208,216,208c50.71,0,96.7,24.14,127.817,66.637A111.947,111.947,0,0,0,272.6,442.613,312.1,312.1,0,0,1,216,448c-74.188,0-149.593-35.583-167.957-44.844C49.014,348.164,66.517,298.5,97.579,262.865Z"/></svg>
</template>

<script>
export default {
  name: 'UserAllowedAccount',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
