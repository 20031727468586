<template>
<svg id="light" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m8.5 21h-6c-1.378 0-2.5-1.122-2.5-2.5v-16c0-1.378 1.122-2.5 2.5-2.5h11c1.378 0 2.5 1.122 2.5 2.5v6c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-6c0-.827-.673-1.5-1.5-1.5h-11c-.827 0-1.5.673-1.5 1.5v16c0 .827.673 1.5 1.5 1.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z"/><path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z"/><path d="m17.5 21c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6c0 .276-.224.5-.5.5z"/><path d="m20.5 18h-6c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z"/><path d="m9.5 13.89c-.276 0-.5-.224-.5-.5v-7.89c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v4.52c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-4.02h-2v7.39c0 .277-.224.5-.5.5z"/><path d="m6.5 15h-3c-.276 0-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5h3c.276 0 .5.224.5.5v5c0 .276-.224.5-.5.5zm-2.5-1h2v-4h-2z"/><path d="m9.02 15h-6.52c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6.52c.276 0 .5.224.5.5s-.224.5-.5.5z"/></svg>
</template>

<script>
export default {
  name: 'AddCompany',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
