import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Ref } from 'vue-property-decorator'
import { VDataTable, VSpacer, VToolbar } from 'vuetify/lib'
import { throttle } from 'lodash'
import PaginationInterface from '../../../typings/PaginationInterface'
import OptionsDataTable from '../../../typings/OptionsDataTable'
import BaseApi from '../../../services/BaseApi'
import ExportWindow from './ExportWindow'
import FilterContainer from './FilterContainer.vue'
import GenericTablePagination from './GenericTablePagination.vue'
import DownloadJSON from '../../../functions/DownloadJSON'
import JsonToCSV from '../../../functions/JsonToCSV'

@Component
export default class BcDataTable extends Vue {
  @Ref() readonly paginationRef!: any

  @Prop({ type: Object, required: true }) readonly options!: OptionsDataTable
  @Prop({ type: Boolean, default: false }) readonly loadOnInit!: boolean
  @Prop({ type: Boolean, default: false }) readonly 'showFilters'!: boolean
  @Prop({ type: Object }) readonly 'defaultFilter'!: any | false
  @Prop({ type: Boolean, default: false }) readonly 'custom-call':
    | string
    | undefined

  items: Array<any> = []
  addedClasses: Array<string> = []
  filter = '{}'
  loading = false
  exportLoading = false
  disablePrevious = false
  disableNext = false
  config: OptionsDataTable = { dataTable: '' }
  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1
  }

  mounted () {
    this.onOptionsChange(this.options)
    if (this.loadOnInit) {
      this.$nextTick(() => {
        this.getData()
      })
    }
    if (this.$listeners['click:row']) {
      this.addedClasses.push('generic-table-add-pointer')
    }
    if (!this.config.disableArrowKeys) {
      document.addEventListener('keyup', this.eventLisArrows)
    }
  }

  destroyed () {
    document.removeEventListener('keyup', this.eventLisArrows)
  }

  eventLisArrows (event: any) {
    if (event.code === 'ArrowLeft') {
      if (!this.disablePrevious) {
        this.paginationRef.previousPage()
      }
    }
    if (event.code === 'ArrowRight') {
      if (!this.disableNext) {
        this.paginationRef.nextPage()
      }
    }
  }

  get slots () {
    const tmp: any = {}
    const keys = Object.keys(this.$scopedSlots)
    keys.forEach((key: string) => {
      tmp[key] = this.$scopedSlots[key]
    })
    return tmp
  }

  get throttleGetData () {
    return throttle(this.getData, 500, { leading: true })
  }

  get headers () {
    if (this.config.tableFields) {
      return this.config.tableFields
    } else {
      if (this.items.length > 0) {
        const keys = Object.keys(this.items[0])
        const tmp: Array<any> = []

        for (let i = 0; i < keys.length; i++) {
          const e = keys[i]
          tmp.push({ text: e, value: e })
        }
        return tmp
      } else {
        return []
      }
    }
  }

  async fetchData (pagination: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const opts: any = {}
        opts.DataCategories = this.config.DataCategories
        if (this.config.fields) {
          opts.Fields = this.config.fields
        }
        if (this.config.links) {
          opts.Links = this.config.links
        }
        if (this.config.orderBy) {
          opts.orderby = this.config.orderBy
        }
        let dt: any = {}
        const filter = this.fixFilter()
        if (this.options.dataTable) {
          if (this.config.customCall) {
            dt = (await new BaseApi(this.options.dataTable, 0).customFilter(
              this.config.customCall,
              filter,
              pagination,
              opts
            )) as any
          } else {
            dt = (await new BaseApi(this.options.dataTable, 0).filter(
              filter,
              pagination,
              opts
            )) as any
          }
        }
        resolve(dt)
      } catch (err) {
        reject(err)
      }
    })
  }

  fixFilter () {
    let cFilter = this.config.filter
    let fFilter: any = this.filter
    if (typeof fFilter === 'string') {
      fFilter = JSON.parse(fFilter)
    }
    const cKeys = Object.keys(cFilter)
    const fKeys = Object.keys(fFilter)
    console.log('cKeys', cKeys.length, cFilter)
    console.log('fKeys', fKeys.length, fFilter)
    if (cKeys.length === 0) {
      cFilter = null
    }
    if (fKeys.length === 0) {
      fFilter = null
    }
    if (cFilter && fFilter) {
      return { and: [cFilter, fFilter] }
    } else if (cFilter) {
      return cFilter
    } else if (fFilter) {
      return fFilter
    } else {
      return {}
    }
  }

  async getData () {
    try {
      this.loading = true
      const dt = (await this.fetchData({
        pagesize: this.pagination.pagesize,
        pagenumber: this.pagination.pagenumber
      })) as any
      this.loading = false
      this.pagination = dt.Page
      this.checkPagination(dt.Page)
      this.items = dt.Data
      this.$emit('update:data', dt.Data)
    } catch (err) {
      this.loading = false
      console.error(err)
    }
  }

  checkPagination (page: any) {
    if (page.PageCount === 0 && page.PageNumber === 1 && page.Results === 0) {
      // To lazy to write if not
    } else {
      if (page.PageCount < page.PageNumber) {
        this.pagination = {
          ...page,
          pagenumber: page.PageCount
        }
        this.getData()
      }
    }
  }

  async doExport (amount: string, delimiter: string) {
    this.exportLoading = true
    let { Data: data } = await this.fetchData({ pagesize: parseInt(amount), pagenumber: 1 }) as any
    data = this.formatedExportData(data)
    if (delimiter === 'json') {
      DownloadJSON(data, 'export' + '.json')
    } else {
      JsonToCSV(data, 'export' + '.csv', delimiter)
    }
    this.exportLoading = false
  }

  formatedExportData (data: Array<any>) {
    if (this.config) {
      let array1: Array<any> = []
      if (this.config.exportTransformFields) {
        const tmp2: Array<any> = []
        for (let i = 0; i < data.length; i++) {
          const el = data[i]
          const newObj: any = Object.assign({}, el)
          const keys = Object.keys(this.config.exportTransformFields)
          for (let k = 0; k < keys.length; k++) {
            const key = keys[k]
            newObj[key] = this.config.exportTransformFields[key](el)
          }
          tmp2.push(newObj)
        }
        array1 = tmp2
      } else {
        array1 = data
      }
      if (this.config.exportFields !== undefined) {
        const arr2: Array<any> = []
        for (let i = 0; i < array1.length; i++) {
          const el = array1[i]
          const obj1: any = {}
          for (let k = 0; k < this.config.exportFields.length; k++) {
            obj1[this.config.exportFields[k]] = el[this.config.exportFields[k]]
          }
          arr2.push(obj1)
        }
        array1 = arr2
      }
      return array1
    } else {
      return data
    }
  }

  genFilterSlot () {
    if (this.showFilters || this.config.showExport) {
      const elements: any = []
      if (this.showFilters) {
        elements.push(
          this.$createElement(FilterContainer, {
            props: {
              filterOptions: this.config.filterOptions
            },
            on: {
              search: (v: string) => {
                this.throttleGetData()
              },
              'updated:qs': (val: any) => {
                this.filter = JSON.stringify(val)
                console.log(this.filter)
              }
            }
          })
        )
      }
      elements.push(this.$createElement(VSpacer))
      if (this.config.showExport) {
        elements.push(
          this.$createElement(ExportWindow, {
            props: {
              title: '',
              loading: this.exportLoading
            },
            on: {
              'do:export': (config: any) => {
                this.doExport(config.amount, config.delimiter)
              }
            }
          })
        )
      }

      return this.$createElement(
        VToolbar,
        {
          props: {
            flat: true
          }
        },
        elements
      )
    }
  }

  genPaginationSlot () {
    return this.$createElement(GenericTablePagination, {
      ref: 'paginationRef',
      props: {
        pagination: this.pagination
      },
      on: {
        'update:pagination': (val: any) => {
          this.pagination = val
          this.throttleGetData()
        },
        'updated:disableNext': (val: any) => {
          this.disableNext = val
        },
        'updated:disablePrevious': (val: any) => {
          this.disablePrevious = val
        }
      }
    })
  }

  genDataTable () {
    return this.$createElement(VDataTable, {
      props: {
        items: this.items,
        headers: this.headers,
        disablePagination: true,
        hideDefaultFooter: true,
        loading: this.loading,
        ...this.$attrs
      },
      scopedSlots: {
        footer: () => this.genPaginationSlot(),
        top: () => this.genFilterSlot(),
        ...this.slots
      },
      on: this.$listeners
    })
  }

  @Watch('options', { deep: true })
  onOptionsChange (val: OptionsDataTable) {
    const tmp: OptionsDataTable = val
    if (!tmp.DataCategories) {
      tmp.DataCategories = ['BASIC']
    }
    if (!tmp.filter) {
      tmp.filter = {}
    }
    this.config = tmp
  }

  render () {
    return this.$createElement('div', [this.genDataTable()])
  }
}
