export default [
  {
    path: '/my/chargepoints',
    name: 'navchargepoints',
    component: () => import('@/views/_enduser/Chargepoints.vue')
  },
  {
    path: '/my/chargepoints/:id',
    name: 'navchargepointsdetails',
    props: true,
    component: () => import('@/views/_enduser/ChargepointsDetails.vue')
  },
  {
    path: '/my/subscriptions',
    name: 'navsubscriptions',
    component: () => import('@/views/_enduser/Subscriptions.vue')
  },
  {
    path: '/my/subscriptions/details',
    name: 'navsubscriptionsdetails',
    props: true,
    component: () => import('@/views/_enduser/SubscriptionDetail.vue')
  },
  {
    path: '/my/token/details/:id',
    name: 'navtokendetails',
    props: true,
    component: () => import('@/views/_enduser/TokensDetails.vue')
  },
  {
    path: '/my/tokens',
    name: 'navtokens',
    component: () => import('@/views/_enduser/Tokens.vue')
  },
  {
    path: '/my/organisation',
    name: 'navmyOrganisation',
    component: () => import('@/views/_enduser/MyOrganisation.vue')
  },
  {
    path: '/my/chargesession',
    name: 'navchargesession',
    component: () => import('@/views/_enduser/ChargeSessions.vue')
  },
  {
    path: '/my/chargesession/:id',
    name: 'sessionDetail',
    component: () => import('@/views/_enduser/ChargeSessionDetail.vue')
  },
  {
    path: '/my/reservation/:id',
    name: 'reservation',
    props: true,
    component: () => import('@/views/_enduser/Reservation.vue')
  },
  {
    path: '/my/survey/:id',
    name: 'survey',
    props: true,
    component: () => import('@/views/_enduser/Survey.vue')
  },
  {
    path: '/my/service-center',
    name: 'serviceCenter'
  },
  {
    path: '/my/profile',
    name: 'myprofile',
    component: () => import('@/views/_user/MyProfile.vue')
  },
  {
    path: '/my/changepassword',
    name: 'ChangePassword',
    component: () => import('@/views/_user/ChargePassword.vue')
  },
  {
    path: '/my/requests',
    name: 'myRequests',
    component: () => import('@/views/_enduser/MyRequests.vue')
  },
  {
    path: '/my/request/:id',
    name: 'requestDetails',
    props: true,
    component: () => import('@/views/_enduser/RequestDetails.vue')
  },
  {
    path: '/my/cprequest/:id',
    name: 'requestCPDetails',
    props: true,
    component: () => import('@/views/_enduser/RequestCPDetails.vue')
  },
  {
    path: '/my/request-charger',
    name: 'invoiceAddress',
    props: true,
    component: () => import('@/views/_enduser/requests/InvoiceAddress.vue')
  },
  {
    path: '/my/request-delivery',
    name: 'deliveryAddress',
    props: true,
    component: () => import('@/views/_enduser/requests/DeliveryAddress.vue')
  },
  {
    path: '/my/review-order',
    name: 'reviewOrder',
    props: true,
    component: () => import('@/views/_enduser/requests/ReviewOrder.vue')
  },
  {
    path: '/my/account-type',
    name: 'accountType',
    props: true,
    component: () => import('@/views/_enduser/requests/AccountType.vue')
  },
  {
    path: '/my/contact-back',
    name: 'contactBack',
    component: () => import('@/views/_enduser/requests/ContactBack.vue')
  },
  {
    path: '/my/request-contact-details',
    name: 'contactDetails',
    props: true,
    component: () => import('@/views/_enduser/requests/ContactDetails.vue')
  },
  {
    path: '/my/contact-enterprise-details',
    name: 'contactEnterpriseDetails',
    props: true,
    component: () =>
      import('@/views/_enduser/requests/ContactEnterpriseDetails.vue')
  },
  {
    path: '/my/chargerType',
    name: 'chargerType',
    props: true,
    component: () => import('@/views/_enduser/requests/ChargerType.vue')
  },
  {
    path: '/my/charging-speed-type',
    name: 'chargingSpeedType',
    props: true,
    component: () => import('@/views/_enduser/requests/ChargingSpeedType.vue')
  },
  {
    path: '/my/installation-type',
    name: 'installationType',
    props: true,
    component: () => import('@/views/_enduser/requests/InstallationType.vue')
  },
  {
    path: '/my/subscription-type',
    name: 'subscriptionType',
    props: true,
    component: () => import('@/views/_enduser/requests/SubscriptionType.vue')
  },
  {
    path: '/my/dynamic-type',
    name: 'dynamicType',
    props: true,
    component: () => import('@/views/_enduser/requests/DynamicType.vue')
  },
  {
    path: '/my/survey-type',
    name: 'surveyType',
    props: true,
    component: () => import('@/views/_enduser/requests/SurveyType.vue')
  },
  {
    path: '/my/survey-type-details',
    name: 'surveyTypeDetails',
    props: true,
    component: () => import('@/views/_enduser/requests/SurveyTypeDetails.vue')
  },
  {
    path: '/my/survey-type-pictures',
    name: 'surveyTypePictures',
    props: true,
    component: () => import('@/views/_enduser/requests/SurveyTypePictures.vue')
  },
  {
    path: '/my/mounting-type',
    name: 'mountingType',
    props: true,
    component: () => import('@/views/_enduser/requests/MountingType.vue')
  },
  {
    path: '/locations',
    name: 'ChargeLocationsMap',
    component: () => import('@/views/_enduser/ChargelocationsMap.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/_enduser/Dashboard.vue')
  },
  {
    path: '/my',
    name: 'administration',
    redirect: '/my/organisation'
  },
  {
    path: '/',
    redirect: '/dashboard'
  }
]
