<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path id="pump" d="M16.832,5.432,15.6,4.2,14.472,3.072a.8.8,0,1,0-1.136,1.136l.8.8L13.472,7a2.4,2.4,0,0,0,.584,2.456l2.36,2.4V15.6a.8.8,0,0,1-1.6,0V14a2.4,2.4,0,0,0-2.4-2.4H11.6V4.4A2.4,2.4,0,0,0,9.2,2H4.4A2.4,2.4,0,0,0,2,4.4V15.6A2.4,2.4,0,0,0,4.4,18H9.2a2.4,2.4,0,0,0,2.4-2.4V13.2h.8a.8.8,0,0,1,.8.8v1.6a2.4,2.4,0,1,0,4.8,0V8.264a4,4,0,0,0-1.168-2.832ZM10,15.6a.8.8,0,0,1-.8.8H4.4a.8.8,0,0,1-.8-.8V10H10Zm0-7.2H3.6v-4a.8.8,0,0,1,.8-.8H9.2a.8.8,0,0,1,.8.8Zm6.4,1.136L15.168,8.3a.8.8,0,0,1-.192-.8l.408-1.232.312.32a2.4,2.4,0,0,1,.7,1.672Z" transform="translate(-2 -2)" fill="#279dd9"/>
    </svg>
</template>

<script>
export default {
  name: 'ChargePoint',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337;
}
</style>
