function createFilterObject (filter: any) {
  const o: any = {}
  if (filter.operator === 'EX') {
    o[filter.field] = filter.value
  } else if (filter.operator === 'SW') {
    o[filter.field] = { op: 'LI', value: `${filter.value}?` }
  } else if (filter.operator === 'EW') {
    o[filter.field] = { op: 'LI', value: `?${filter.value}` }
  } else if (filter.operator === 'CO') {
    o[filter.field] = { op: 'LI', value: `?${filter.value}?` }
  }
  return o
}

function createFiltersObject (filters: Array<any>) {
  if (filters.length === 1) {
    return createFilterObject(filters[0])
  } else if (filters.length > 1) {
    const tmp: Array<any> = []
    filters.forEach(e => {
      tmp.push(createFilterObject(e))
    })
    return { and: tmp }
  } else {
    return {}
  }
}

function genUseFullObj (obj: any) {
  const keys = Object.keys(obj)
  if (keys.length === 1) {
    const field = keys[0]
    if (typeof obj[field] === 'object') {
      const reg1 = obj[field].value.match(/^(\?)(.*)(\?)$/)
      const reg2 = obj[field].value.match(/^(\?)(.*)/)
      const reg3 = obj[field].value.match(/(.*)(\?)$/)
      if (reg1) {
        return { field, operator: 'CO', value: reg1['2'] }
      } else if (reg2) {
        return { field, operator: 'EW', value: reg2['2'] }
      } else if (reg3) {
        return { field, operator: 'EW', value: reg3['1'] }
      }
    } else {
      return { field, operator: 'EX', value: obj[field] }
    }
  }
}

function deStringifyFilter (val: string) {
  try {
    const obj = JSON.parse(val)
    const rtn: Array<any> = []
    const keys: Array<any> = Object.keys(obj)
    if (keys.length === 1 && obj.and) {
      obj.and.forEach((e: any) => {
        rtn.push(genUseFullObj(e))
      })
    } else {
      rtn.push(genUseFullObj(obj))
    }
    // console.log(rtn)
  } catch (error) {
    console.error('Probably not a valid JSON string', error)
  }
}

export default { createFiltersObject, deStringifyFilter }
export { createFiltersObject, deStringifyFilter }
