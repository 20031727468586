export default {
  profile: {
    fleetManager: 'Fleet Manager',
    changePassword: 'change password',
    myProfile: 'My profile',
    logout: 'Log Out',
    currentPswd: 'current password',
    newPswd: 'new password',
    repeatPswd: 'repeat password',
    successUpdate: 'Account succesfully updated',
    local: 'fr-FR',
    locale: 'fr'
  },
  requests: {
    title: 'Demandes'
  },
  common: {
    close: 'Close',
    noDataText: 'No data available'
  },
  cdr: {
    sessionId: 'ID de session',
    startStopSession: 'Démarrer / arrêter la session',
    startSession: 'Démarrer la session',
    sessionDuration: 'Durée de la session',
    token: 'Carte de charge',
    consumption: 'Consommation',
    status: 'Statut',
    price: 'Prix',
    actions: 'Actions',
    connectorNumber: 'Connector Nr',
    cpMonitor: 'Chargepoint Monitor',
    myLocations: 'Mes emplacements'
  },
  chargelocation: {
    street: 'rue',
    city: 'ville',
    county: 'pays',
    landOwner: 'propriétaire foncier',
    description: 'description',
    houseNr: 'numéro de maison',
    postalCode: 'code postal',
    owner: 'propriétaire',
    mergeMode: 'Mode fusion',
    newLocation: 'nouvel emplacement',
    mergeSelected: 'Fusionner sélectionné',
    searchLocation: 'Trouver un emplacement',
    Id: 'Id',
    label: 'Label',
    Description: 'Description',
    Street: 'Rue',
    City: 'Ville',
    LandOwner: 'Propriétaire foncier',
    Owner: 'Propriétaire',
    Status: 'Statut',
    Enable: 'Activer',
    Disable: 'Désactiver',
    validate3char: 'Veuillez saisir au moins 3 caractères'
  },
  chargepoint: {
    activeSessions: 'Sessions actives',
    pastSessions: 'Dernières sessions',
    tools: 'Outils',
    Authorizes: 'Autorise',
    ChargeBoxIdentifier: 'Identifiant ChargeBox',
    Owner: 'Propriétaire',
    ChargeBoxSN: 'Numéro de série de ChargeBox',
    Model: 'Modèle',
    OnlineStateDateTime: 'Dernier battement de coeur',
    lastBootNotification: 'Dernière notification de démarrage',
    description: 'Description',
    id: 'Id',
    filters: 'Filtres',
    viewLocation: "Afficher l'emplacement",
    moreInfo: "Plus d'informations",
    currentError: 'Erreur actuelle',
    AVAILABLE: 'DISPONIBLE',
    adminstate: 'État administrateur',
    EndpointUrl: 'Endpoint',
    EndpointIp: 'Ip address',
    IccId: 'Icc',
    Imsi: 'Imsi',
    FirmwareVersion: 'Version du firmware',
    MeterType: 'Type de compteur',
    MeterSN: 'Numéro de série du compteur',
    Vendor: 'Constructeur',
    Longitude: 'Longitude',
    Latitude: 'Latitude',
    chargepoints: 'chargepoints'
  },
  navbar: {
    home: 'Home',
    locations: 'Emplacements',
    chargeLocationsConfig: 'Configuration des Emplacements',
    cdrs: 'Dossiers détaillés des frais',
    chargepoints: 'Borne de recharge',
    findToken: 'Trouver une carte de recharge',
    tokenRequests: 'Demandes de carte de recharge',
    bulkUpdateSubs: 'Bulk Update Abonnements',
    offlineCPs: 'Borne de recharge offline ',
    whiteList: 'Whitelist',
    customers: 'Relations',
    CPOchargepoints: 'CPO Borne de recharge',
    Reports: 'Rapports',
    CPOContract: 'CPO Contrat',
    alarms: 'alarms',
    scheduler: 'Scheduler',
    transTool: 'Translation Tool',
    cpMonitor: 'Chargepoint Monitor',
    myLocations: 'Mes emplacements',
    subscriptionType: 'Subscription Type',
    superAdminTools: 'Super Admin tools',
    logsTools: 'Logs tools',
    chargepointMonitor: 'Chargepoint Monitor',
    genclassexp: 'Generic Class explorer',
    genericTables: 'Generic Tables',
    testTom: 'Toms Test Page',
    managementTitle: 'Management',
    subType: 'Subscription Type',
    emails: 'Emails',
    logTools: 'Log Tools',
    Alarms: 'Alarms',
    Backlogs: 'Backlogs',
    Errors: 'Errors',
    Scheduler: 'Scheduler',
    TranslationTool: 'TranslationTool',
    GenericClassesexplorer: 'GenericClassesexplorer',
    GenericTables: 'GenericTables',
    ProtectedUserInfo: 'ProtectedUserInfo',
    TestPageTom: 'TestPageTom',
    relSubs: 'Relations/Subscriptions',
    Connections: 'Connections',
    SessionInError: 'Sessions In Error',
    BulkSendCommand: 'Bulk Send Command'
  },
  dataIterator: {
    pageText: '{0}-{1} of {2}',
    noResultsText: 'Aucun enregistrements correspondants trouvés',
    loadingText: 'Chargement des éléments ...'
  },
  dataTable: {
    itemsPerPageText: 'Lignes par page:',
    rowsPerPageText: 'Lignes par page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.'
    }
  },
  dataFooter: {
    itemsPerPageText: 'Objets par page:',
    itemsPerPageAll: 'Tout',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    firstPage: 'Première page',
    lastPage: 'Dernière page',
    pageText: ''
  },
  datePicker: {
    itemsSelected: '{0} choisi'
  },
  noDataText: 'Pas de données disponibles',
  carousel: {
    prev: 'Visuel précédent',
    next: 'Visuel suivant'
  },
  calendar: {
    moreEvents: '{0} plus'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  },
  tariffs: {
    tariffType: 'tariffType',
    AC: 'AC',
    DC: 'DC',
    Roaming: 'Roaming'
  },
  ChargeLocationDetail: {
    Offline: 'Offline',
    Online: 'Online',
    Available: 'Disponible',
    Charging: 'Chargée',
    Parking: 'Garé',
    Error: 'Error',
    SaveChanges: 'Enregistrer les modifications',
    Edit: 'Éditer',
    Published: 'Publié',
    NotPublished: 'Non publié',
    ViewChargepoint: 'Voir le borne de charge',
    EvseId: 'Evse Id',
    SyncUpdateDateTime: 'Last Sync',
    LocationInfo: 'informations de localisation',
    Description: 'Description',
    DescriptionLocationToChargePoints: 'Directions vers le point de charge',
    DescriptionRouteToLocation: "Itinéraire vers l'emplacement",
    ContactInfo: 'Coordonnées',
    CreateLocation: 'Créer un emplacement',
    CoordinateQuickFill:
      "Entrez l'adresse pour saisir rapidement les informations de localisation",
    NoGPS: 'Aucune coordonnée GPS trouvée',
    Search: 'Chercher',
    DragIconOnMap: "Faites glisser l'icône sur la carte",
    Save: 'Enregistrer',
    EditCoordinates: 'Modifier les coordonnées',
    Label: 'Label',
    Floor: 'Étage',
    Street: 'Rue',
    HouseNumber: 'Numéro de maison',
    ZipCode: 'Code postal',
    City: 'Ville',
    Country: 'Terre',
    Language: 'Langue',
    LocationMobile: 'Location Mobile',
    LocationPhone: 'Location Numéro de téléphone',
    Phone: 'Numéro de téléphone',
    LocationEmail: 'Adresse e-mail de localisation'
  },
  newnav: {
    chargepoints: 'TODO: Chargepoints',
    subscriptions: 'TODO: subscriptions',
    tokens: 'TODO: tokens',
    myOrganisation: 'TODO: myOrganisation',
    chargesession: 'TODO: chargesession'
  }
}
