import BaseApi from '@/services/BaseApi'

export default class BannerApi extends BaseApi {
  constructor(type = 0) {
    super('Banner', type)
  }

  async getBanner() {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/banner/BCO/Banner`
    }
    return await this.getApi(options)
  }
}
