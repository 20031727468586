import PaginationInterface from "@/typings/PaginationInterface";


export const shouldDisableNextButton = (pageNumber: PaginationInterface['PageNumber'], pageCount: PaginationInterface['PageCount'], resultFull: PaginationInterface['resultfull']): boolean => {
  if (pageNumber && pageCount) {
    if (pageNumber === pageCount) {
      if (resultFull !== 1) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return true
  }
}

export const getNextPageNumber = (pageCount: PaginationInterface['pagecount'], pageNumber: PaginationInterface['pagenumber']): number => {
  let page = 1
  if (pageCount && pageNumber) {
    if (pageCount < pageNumber) {
      page = pageCount
    } else {
      if (pageNumber) {
        page = pageNumber + 1
      }
    }
  } else {
    if (pageNumber) {
      page = pageNumber + 1
    }
  }
  return page;
}

export const getMaxPageNumber = (pageCount: PaginationInterface['pagecount'], resultFull: PaginationInterface['resultfull']): number|string => {
  if (pageCount) {
    if (resultFull === 1) {
      return pageCount
    } else {
      return `${pageCount}+`
    }
  } else {
    return 0
  }
}
