import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

export interface NotificationPayload {
  content: string
  color: string
  timeout?: number
  position?: string
  multiline?: boolean
}

@Module({ namespaced: true })
export default class Notification extends VuexModule {
  content = ''
  color = ''
  timeout = 6000
  position = 'right'
  multiline = false

  @Mutation
  showMessage (payload: NotificationPayload) {
    this.content = payload.content
    this.color = payload.color
    if (payload.multiline) {
      this.multiline = true
    } else {
      this.multiline = false
    }
    if (payload.timeout) {
      this.timeout = payload.timeout
    } else {
      this.timeout = 6000
    }
    if (payload.position) {
      this.position = payload.position
    } else {
      this.position = 'right-top'
    }
  }
}
