import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import Vue from 'vue'
import ChargePointApi from '@/services/ChargePointApi'
interface AsyncDataInterface {
  data: Array<any> | any
  fetched: boolean
  isFetching: boolean
  lastFetched: number
}

const refreshTime = 5 // in seconds

function existsOnObject (object: any, key: string) {
  const keys = Object.keys(object).filter(x => object[x])
  return keys.includes(key)
}

async function fetchCpDetails (id: string, vm: any) {
  try {
    const tmp = {
      id,
      data: {
        expiredAt: new Date().getTime() + (refreshTime * 1000),
        data: null,
        loading: true
      }
    }
    vm.context.commit('setDetailForId', tmp)
    vm.context.commit('setDetailIsloading', true)
    const data = await new ChargePointApi().details(id) as any
    console.log('DATA RSLT: ')
    console.log(data)
    const tmpRtn = {
      id,
      data: {
        expiredAt: new Date().getTime() + (refreshTime * 1000),
        data: data.data,
        loading: false
      }
    }
    vm.context.commit('setDetailIsloading', false)
    vm.context.commit('setActive', id)
    vm.context.commit('setDetailForId', tmpRtn)
    return tmpRtn
  } catch (err) {
    console.log(err)
    console.log('CATCH ERROR 403')
    /* if (err.status === 403) {
      try {
        const tmp = {
          id,
          data: {
            expiredAt: new Date().getTime() + (refreshTime * 1000),
            data: null,
            loading: true
          }
        }
        vm.context.commit('setDetailForId', tmp)
        vm.context.commit('setDetailIsloading', true)
        const data = await new ChargePointApi().minimumDetails(id) as any
        console.log('DATA RSLT 403: ')
        console.log(data)
        data.data.error = 403
        const tmpRtn = {
          id,
          data: {
            expiredAt: new Date().getTime() + (refreshTime * 1000),
            data: data.data,
            error: 403,
            loading: false
          }
        }
        vm.context.commit('setDetailIsloading', false)
        vm.context.commit('setActive', id)
        vm.context.commit('setDetailForId', tmpRtn)
        return tmpRtn
      } catch (error) {
        console.error(err)
      }
    } else {
      console.log(err)
      // console.error(err)
    } */
  }
}

@Module({ namespaced: true })
export default class Chargepoints extends VuexModule {
  MyChargepoints: AsyncDataInterface = {
    data: [],
    fetched: false,
    isFetching: false,
    lastFetched: 0
  }

  _activeDetails = ''
  _detailsChargepoints: any = {}
  contract = {}
  detailChargepoint: any = null
  detailChargepointLastFetch = 0
  detailChargepointIsFetching = false

  loadedMyChargepoints = false

  get detailLastFetched () {
    return this.detailChargepointLastFetch
  }

  get contractDTA () {
    console.log(this.contract)
    return this.contract
  }

  get detailIsloading () {
    return this.detailChargepointIsFetching
  }

  get details () {
    return this.detailChargepoint
  }

  get lastFetched () {
    return this.MyChargepoints.lastFetched
  }

  get isloading () {
    return this.MyChargepoints.isFetching
  }

  get cpDetails () {
    if (this._activeDetails.length > 0) {
      return this._detailsChargepoints[this._activeDetails].data
    } else {
      return null
    }
  }

  get myChargepoints () {
    return this.MyChargepoints.data
  }

  @Mutation
  setDetailIsloading (val: boolean) {
    this.detailChargepointIsFetching = val
  }

  @Mutation
  setDetailForId (data: any) {
    if (data) {
      console.log('idDDDD', data)
      Vue.set(this._detailsChargepoints, data.id, data.data)
      // this._detailsChargepoints[data.id] = data.data
      console.log('dees ist resultaat', this._detailsChargepoints[data.id])
    }
  }

  @Mutation
  setDetail (val: any) {
    this.detailChargepoint = val
  }

  @Mutation
  setContract (val: any) {
    this.contract = val
  }

  @Mutation
  setActive (val: string) {
    this._activeDetails = val
  }

  @Mutation
  setDetailLastFetch (val: number) {
    this.detailChargepointLastFetch = val
  }

  @Mutation
  setIsloading (val: boolean) {
    this.detailChargepointIsFetching = val
  }

  @Mutation
  setIsloadingList (val: boolean) {
    this.MyChargepoints.isFetching = val
  }

  @Mutation
  setcps (data: Array<any>) {
    this.MyChargepoints.data = data
  }

  @Action({ commit: 'setcps' })
  async fetchChargepoints (forceFetch = false) {
    if (this.lastFetched + (refreshTime * 1000) < new Date().getTime() || forceFetch) {
      console.log('fetching new stuff')
      try {
        this.context.commit('setIsloadingList', true)
        this.context.commit('setcps', [])
        const data = await new ChargePointApi().myChargers() as any
        this.MyChargepoints.lastFetched = new Date().getTime()
        this.context.commit('setIsloadingList', false)
        return data.data
      } catch (error) {
        console.error(error)
      }
    } else {
      console.log('Nah, your gona see some old stuff')
      return this.myChargepoints
    }
  }

  @Action({ commit: 'setDetailForId', rawError: true })
  async getDetails (id: string) {
    // @ts-ignore
    if (!existsOnObject(this.context.state._detailsChargepoints, id)) {
      console.log('chargepoint does not exists in tree')
      const tmp = await fetchCpDetails(id, this)
      console.log('tmpBIS', tmp)
      return tmp
    } else {
      console.log('chargepoint found in print, checking if expired')
      console.log(this._detailsChargepoints[id].expiredAt)
      const tmp = this._detailsChargepoints[id].expiredAt - new Date().getTime()
      console.log(tmp)
      this.context.commit('setActive', id)
      // @ts-ignore
      if (tmp < 0) {
        console.log('Chargepoint details have expired, fetching new data')
        const tmp = await fetchCpDetails(id, this)
        return tmp
      }
    }
  }

  @Action({ commit: 'setDetail' })
  async fetchChargepointDetail (id: string) {
    if (this.detailChargepointLastFetch + (refreshTime * 1000) < new Date().getTime()) {
      try {
        console.log('getting new details for:')
        this.context.commit('setDetailIsloading', true)
        this.context.commit('setDetail', { id, data: null })
        const data = await new ChargePointApi().details(id) as any
        this.context.commit('setDetailLastFetch', new Date().getTime())
        this.context.commit('setDetailIsloading', false)
        console.log('echt what the fuck werk gewoon', { id, data: data.data })
        return { id, data: data.data }
      } catch (error) {
        console.error(error)
      }
    } else {
      return { id, data: this.details }
    }
  }

  @Action({ commit: 'setContract' })
  async getContracts (id: string) {
    try {
      return await new ChargePointApi().contracts(id) as any
    } catch (error) {
      console.error(error)
    }
  }
}
