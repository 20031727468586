



































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import UserApi, { ISetupUser } from '../../services/UserApi'
import { isRequired, isEmail, hasIbanSpecialChars, isHouseNumber, isMaxLengthCheck, isAddress, isVAT } from '../../functions/formRules'
import RelationApi from '@/services/RelationApi'

@Component({
  computed: {
    ...mapGetters({ oidcUser: 'oidcStore/oidcUser' })
  }
})
export default class Intake extends Vue {
  @Ref() readonly form!: any
  showme = false
  loaded = false
  isValid = true
  isCompany = false
  loading = false
  error = false
  hideForm = false
  ibanErrorMsg = ''
  vatErrorMsg = ''
  errorMsg = ''
  required: Array<any> = [
    isRequired('This field is required')
  ]

  rulesName: Array<any> = [
    isMaxLengthCheck('Maximum no. of characters allowed is 100', 100),
    isRequired('This field is required')
  ]

  rulesEmail: Array<any> = [
    isRequired('This field is required'),
    isEmail('Invalid Email address')
  ]

  rulesPhone: Array<any> = [
    isRequired('This field is required')
  ]

  rulesVAT: Array<any> = [
    isRequired('This field is required')
  ]

  /* rulesZipCode: Array<any> = [
    isRequired('This field is required'),
    isZipCode('Invalide vat should be lik BEXXXXXXXXXX')
  ] */

  rulesHouseNumber: Array<any> = [
    isRequired('This field is required'),
    isHouseNumber('Invalid')
  ]

  rulesAddress: Array<any> = [
    isRequired('This field is required'),
    isAddress('Invalid')
  ]

  payload: ISetupUser = {
    vatnr: '',
    name: '',
    iscompany: '',
    companytype: '',
    telephone: '',
    street: '',
    phone: '',
    description: '',
    city: '',
    country: '',
    housenumber: '',
    postalcode: '',
    companyname: ''
  }

  subscribeHandler = (mutation: any, state: any) => {
    if (mutation.type === 'oidcStore/setOidcAuthIsChecked' && !this.loaded) {
      this.checkUserInfo()
      this.loaded = true
    }
  }

  async checkIBAN (val: string) {
    if (val !== '') {
      if (hasIbanSpecialChars (val)) {
        this.ibanErrorMsg = 'Iban shouldn\'t contains special characters or spaces.'
      } else {
        try {
          const result = await new RelationApi().checkIBAN(val) as any
          if (result.valid) {
            this.ibanErrorMsg = ''
          } else {
            this.ibanErrorMsg = 'Invalid IBAN'
          }
        } catch (error: any) {
          this.ibanErrorMsg = 'Invalid IBAN'
        }
      }
    }
  }

  async checkVAT (val: string) {
    if (val !== '') {
      const result = await new RelationApi().checkVAT(val) as any
      if (result.valid) {
        this.vatErrorMsg = ''
      } else {
        // this.fieldsRelation.bankaccountbic = ''
        this.vatErrorMsg = 'Invalid VAT'
      }
    }
  }

  mounted () {
    // this.checkUserInfo()
    this.$store.subscribe(this.subscribeHandler)
  }

  get rules () {
    return {
      required: (v: string) => !!v || this.t('intakeForm.required'),
      email: (v: string) => /.+@.+\..+/.test(v) || this.t('intakeForm.email')
    }
  }

  async submit () {
    this.errorMsg = ''
    if (this.form.validate()) {
      if (this.ibanErrorMsg === '') {
        const clone = Object.assign({}, this.payload)
        if (this.isCompany) {
          clone.iscompany = '1'
          if (clone.companyname) {
            clone.description = clone.companyname
            clone.name = clone.companyname
          }
        } else {
          clone.iscompany = '0'
          // @ts-ignore
          clone.description = this.oidcUser.name
          // @ts-ignore
          clone.name = this.oidcUser.name
        }
        delete clone.companyname
        this.loading = true
        const res = await new UserApi().setupUser(clone) as any
        if (res.data.result === 'success') {
          this.loading = false
          this.hideForm = true
          setTimeout(() => window.location.reload(), 5000)
        } else {
          this.error = true
          this.hideForm = true
        }
        await this.checkUserInfo()
        this.loading = false
      }
    }
  }

  async cleanUp () {
    const result = await new UserApi().cleanup()
  }

  async checkUserInfo () {
    try {
      const result = await new UserApi().userInfo() as any
      if (result.data) {
        if (result.data.result.status === 'success') {
          this.$store.commit('user/setUserInfo', { data: result.data.data, verified: true })
        } else if (result.data.result.status === 'error') {
          if (result.data.result.errorcode === 'SETUP') {
            this.$store.commit('user/setUserInfo', { data: {}, verified: false })
            this.showme = true
          } else {
            this.$store.commit('user/setUserInfo', { error: true })
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
}
