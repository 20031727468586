<template>
<svg height="511pt" viewBox="0 -27 511.99932 511" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m213.410156 25.089844-206.746094 358.09375c-18.929687 32.789062 4.730469 73.773437 42.589844 73.773437h413.492188c37.859375 0 61.519531-40.984375 42.589844-73.773437l-206.746094-358.09375c-18.929688-32.785156-66.25-32.785156-85.179688 0zm0 0" fill="#65687f"/><path d="m49.253906 415.160156c-2.828125 0-4.980468-1.242187-6.394531-3.691406s-1.414063-4.9375 0-7.386719l206.746094-358.09375c1.414062-2.449219 3.566406-3.691406 6.394531-3.691406s4.980469 1.242187 6.394531 3.691406l206.746094 358.09375c1.417969 2.449219 1.417969 4.933594 0 7.386719-1.414063 2.449219-3.5625 3.691406-6.394531 3.691406zm0 0" fill="#ff931f"/><path d="m58.179688 402.621094 197.820312-342.632813 197.820312 342.632813zm0 0" fill="#ffe37d"/><path d="m280.855469 127.058594-91.933594 136.347656h55.996094l-13.460938 126.242188 92.246094-126.242188h-52.320313zm0 0" fill="#65687f"/></svg>
</template>

<script>
export default {
  name: 'TypeTwoCcs',
  props: ['role']
}
</script>

<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
</style>
