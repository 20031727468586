// https://github.com/perarnborg/vuex-oidc-example/blob/master/src/config/oidc.js
/* eslint-disable */
import { VuexOidcClientSettings } from 'vuex-oidc'
import vuetify from './vuetify'

function baseUrl () {
  const url = window.location.href
  const arr = url.split('/')
  return `${arr[0]}//${arr[2]}`
}

function localeLng () {
  let currLng = localStorage.getItem('language')
  if (currLng === undefined || currLng === null) {
    localStorage.setItem('language', 'en')
  }
  return localStorage.getItem('language')
}

export const oidcSettings: any = {
  authority: process.env.VUE_APP_OAUTH_AUTHORITY!,
  clientId: process.env.VUE_APP_OAUTH_CLIENTID!,
  redirectUri: `${baseUrl()}/oidc-callback`,
  responseType: process.env.VUE_APP_OAUTH_RESPONSETYPE!,
  scope: process.env.VUE_APP_OAUTH_SCOPE!,
  // ui_locales: process.env.VUE_APP_OAUTH_UI_LOCALES,
  ui_locales: `${localeLng()}`,
  automaticSilentRenew: (/true/i).test(process.env.VUE_APP_OAUTH_ENABLE_SILENT_RENEW!),
  automaticSilentSignin: (/true/i).test(process.env.VUE_APP_OAUTH_ENABLE_SILENT_RENEW!),
  silentRedirectUri: `${baseUrl()}/silent-renew-oidc.html`,
  post_logout_redirect_uri: process.env.VUE_APP_LOGOUT_URI!
}
