<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="Group_135" data-name="Group 135" transform="translate(2001 -2497)">
    <rect id="Rectangle_605" data-name="Rectangle 605" width="16" height="16" transform="translate(-2001 2497)" fill="none"/>
    <g id="history" transform="translate(-2002 2496)">
      <path id="Path_1810" data-name="Path 1810" d="M9,2A7,7,0,0,0,4.184,3.939V2.7a.7.7,0,1,0-1.4,0V5.85a.7.7,0,0,0,.7.7h3.15a.7.7,0,0,0,0-1.4H4.954A5.6,5.6,0,1,1,3.4,9,.7.7,0,1,0,2,9,7,7,0,1,0,9,2ZM9,6.2a.7.7,0,0,0-.7.7V9a.7.7,0,0,0,.7.7h1.4a.7.7,0,1,0,0-1.4H9.7V6.9A.7.7,0,0,0,9,6.2Z" fill="#333"/>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: 'ChargeCard',
  props: ['role']
}
</script>
<style scoped>
.v-input .v-label {
    color: white !important;
    font-size: 16px;
}
</style>
<style>
.svg-icon {
  fill: currentColor;
  stroke-width:1.33333337
}
.genericfilter .v-label {
  color: white !important;
}
</style>
